import { yupResolver } from "@hookform/resolvers/yup";
import { Button, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useSeller } from "../../providers/users";
import { Container } from "./styles";
import { useNavigate, useParams } from "react-router-dom";
import PageHeader from "../pageHeader";
import { IoCreateOutline } from "react-icons/io5";
import Loading from "../Loading";

const ProfileAddressForm = () => {
  const { seller, createAddress, getSeller, apiLoading } = useSeller();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { path } = useParams();
  const formSchema = yup
    .object()
    .shape({
      country: yup
        .string()
        .max(128, t("country_max"))
        .required(t("country_required")),
      address_line: yup
        .string()
        .max(128, t("address_max"))
        .required(t("address_required")),
      address_line_2: yup.string().max(128, t("address_2_max")),
      city: yup.string().max(128, t("city_max")).required(t("city_required")),
      state: yup.string().max(128, t("state_max")).required(t("state_max")),
      zip_code: yup
        .string()
        .max(128, t("zip_code_max"))
        .required(t("zip_code_required")),
    })
    .required();
  type FormValues = yup.InferType<typeof formSchema>;

  const onSubmit = async (data: FormValues) => {
    const addressToCompare = { ...seller.address };
    delete addressToCompare.id;
    if (JSON.stringify(data) === JSON.stringify(addressToCompare)) {
      return navigate(`/${path}/profile`);
    } else {
      await createAddress(data);
      await getSeller();
      return navigate(`/${path}/profile`);
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });
  return (
    <Container>
      <PageHeader
        title={t("update_address")}
        description={`${t("fill_update")} ${t("address")}`}
        icon={<IoCreateOutline />}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          error={errors.country === undefined ? false : true}
          helperText={errors.country?.message?.toString()}
          id="standard-country"
          label={t("country")}
          variant="standard"
          defaultValue={seller.address?.country}
          {...register("country")}
        />
        <TextField
          id="standard-zipcode"
          error={errors.zip_code === undefined ? false : true}
          helperText={errors.zip_code?.message?.toString()}
          label={t("zip_code")}
          variant="standard"
          defaultValue={seller.address?.zip_code}
          {...register("zip_code")}
        />
        <TextField
          id="standard-address_line"
          error={errors.address_line === undefined ? false : true}
          helperText={errors.address_line?.message?.toString()}
          label={t("address_line")}
          variant="standard"
          defaultValue={seller.address?.address_line}
          {...register("address_line")}
        />
        <TextField
          id="standard-address_line_2"
          error={errors.address_line_2 === undefined ? false : true}
          helperText={errors.address_line_2?.message?.toString()}
          label={t("address_line_2")}
          variant="standard"
          defaultValue={seller.address?.address_line_2}
          {...register("address_line_2")}
        />
        <TextField
          id="standard-city"
          error={errors.city === undefined ? false : true}
          helperText={errors.city?.message?.toString()}
          label={t("city")}
          variant="standard"
          defaultValue={seller.address?.city}
          {...register("city")}
        />
        <TextField
          id="standard-state"
          error={errors.state === undefined ? false : true}
          helperText={errors.state?.message?.toString()}
          label={t("state")}
          variant="standard"
          defaultValue={seller.address?.state}
          {...register("state")}
        />
        {apiLoading ? (
          <Loading />
        ) : (
          <div className="divButton">
            <Button
              onClick={() => navigate(`/${path}/profile`)}
              variant="outlined"
            >
              {t("back")}
            </Button>

            <Button type="submit" sx={{ ml: 1 }} variant="contained">
              {t("confirm")}
            </Button>
          </div>
        )}
      </form>
    </Container>
  );
};

export default ProfileAddressForm;
