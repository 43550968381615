import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loading from "../../components/Loading";
import { useSeller } from "../../providers/users";
import { useTenant } from "../../providers/tenant";
import { Container } from "./styles";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const ActivationPage = () => {
  const { activateUser, isLogged, seller, verifyRolePermission, roleRecruit } =
    useSeller();
  const query = useQuery();
  const { path } = useParams();
  const { tenantByPath } = useTenant();
  const navigate = useNavigate();
  const activation_token = query.get("code");
  const email = query.get("email");
  useEffect(() => {
    if (tenantByPath) {
      activateUser(activation_token, email, tenantByPath.id);
    }
  }, [tenantByPath]);

  useEffect(() => {
    if (isLogged) {
      if (seller && verifyRolePermission(roleRecruit)) {
        const recruitment_id =
          window.localStorage.getItem("SPP-Recruitment-ID") || "";
        if (recruitment_id) {
          return navigate(
            "/" +
              path +
              "/recruitments/" +
              window.localStorage.getItem("SPP-Recruitment-ID") +
              "/candidate"
          );
        }

        navigate("/" + path + "/candidate");
      } else {
        window.localStorage.removeItem("SPP-Recruitment-ID");
        navigate(`/${path}/dashboard`);
      }
    }
  }, [seller]);

  return (
    <Container>
      <Loading />
    </Container>
  );
};

export default ActivationPage;
