import styled from "styled-components";

export const Container = styled.div`
  .paymentCommission {
    font-weight: bold;
    margin-top: 10px;
  }
  .paymentMethodsList {
    margin-top: 15px;
    width: 100%;

    display: flex;
    padding: 5px;
    overflow: scroll;
    gap: 15px;
  }

  @media (min-width: 1100px) {
    .paymentMethodsList {
      overflow: hidden;
      flex-wrap: wrap;
      gap: 5px;
      justify-content: center;
    }
  }
`;
