import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import { useEffect, useState } from "react";
import "react-calendar/dist/Calendar.css";
import { useTranslation } from "react-i18next";
import { AiFillDollarCircle } from "react-icons/ai";
import { ISale } from "../../providers/sales/sales.interface";

import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useSeller } from "../../providers/users";
import { AdminSalesTable } from "../AdminSalesTable";
import Loading from "../Loading";
import { SalesTable } from "../SalesTable";
import PageHeader from "../pageHeader";
import { Container } from "./styles";

interface IProps {
  allSales: ISale[];
  name: string;
  isAdminView?: boolean;
  showUserName?: string;
}

type Value = any;
const Sales = ({
  allSales,
  name,
  isAdminView = false,
  showUserName = "",
}: IProps) => {
  const statusArray = [
    "approved_guarantee",
    "approved_for_payment",
    "finance_processing",
    "refunded_after_paid",
    "chargedbacked_after_paid",
    "cancelled_chargeback",
    "cancelled_refunded",
    "paid",
    "analysis",
    "rejected",
    "not_applicable",
  ];
  const { apiLoading } = useSeller();
  const { t } = useTranslation();
  let weekAgo = new Date(new Date().setDate(new Date().getDate() - 7));
  const [datePicked, changeDatePicked] = useState<Value>([weekAgo, new Date()]);
  const [endGuaranteeDatePicked, changeEndGuaranteeDatePicked] =
    useState<Value>(null);
  const [statusSale, setStatusSale] = useState<string[]>([
    "approved_guarantee",
    "approved_for_payment",
  ]);
  const navigate = useNavigate();
  const { path } = useParams();

  const filteredSales =
    allSales.length > 0 && datePicked
      ? allSales.filter((e) => {
          let beginFirstDate = new Date(datePicked[0]);
          beginFirstDate.setHours(0);
          beginFirstDate.setMinutes(0);
          beginFirstDate.setSeconds(0);
          let endLastDate = new Date(datePicked[1]);
          endLastDate.setHours(23);
          endLastDate.setMinutes(59);
          endLastDate.setSeconds(59);

          let inCreatedAtRange =
            new Date(e.createdAt) >= beginFirstDate &&
            new Date(e.createdAt) <= endLastDate;
          let inEndGuaranteeRange = true;

          if (endGuaranteeDatePicked) {
            let beginFirstEndGuaranteeDate = new Date(
              endGuaranteeDatePicked[0]
            );
            beginFirstEndGuaranteeDate.setHours(0);
            beginFirstEndGuaranteeDate.setMinutes(0);
            beginFirstEndGuaranteeDate.setSeconds(0);
            let endLastEndGuaranteeDate = new Date(endGuaranteeDatePicked[1]);
            endLastEndGuaranteeDate.setHours(23);
            endLastEndGuaranteeDate.setMinutes(59);
            endLastEndGuaranteeDate.setSeconds(59);

            inEndGuaranteeRange =
              new Date(e.end_guarantee) >= beginFirstEndGuaranteeDate &&
              new Date(e.end_guarantee) <= endLastEndGuaranteeDate;
          }

          return (
            inCreatedAtRange &&
            inEndGuaranteeRange &&
            statusSale.includes(e.saleCommission.status)
          );
        })
      : [];

  function handleChange(e: any) {
    const {
      target: { value },
    } = e;
    setStatusSale(typeof value === "string" ? value.split(",") : value);
  }

  return (
    <Container>
      <>
        <PageHeader
          title={t("administrative")}
          description={t("track-sales")}
          icon={<AiFillDollarCircle />}
          showButton={!isAdminView}
          buttonContent={t("new_sales_analysis")}
          buttonPath={!isAdminView ? `salescontestation/create` : undefined}
        />

        <>
          <div className="filterAndUpdateContainer">
            {apiLoading ? (
              <> </>
            ) : isAdminView ? (
              <div className="sellerName">
                {t("seller")}: {name}
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="datePickerContainer custom-select">
            <div className="dateRangeLabel">
              <label>{t("date_sale")}</label>
              <DateRangePicker
                className="datePicker"
                onChange={changeDatePicked}
                value={datePicked}
              />
            </div>
            <div className="dateRangeLabel">
              <label>{t("end_guarantee")}</label>
              <DateRangePicker
                className="datePicker"
                onChange={changeEndGuaranteeDatePicked}
                value={endGuaranteeDatePicked}
              />
            </div>
            <FormControl id="select-label">
              <InputLabel id="demo-simple-select-status">
                {t("status")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-status"
                id="demo-simple-select-status"
                multiple
                value={statusSale}
                required
                label={t("status")}
                placeholder="Choose a status"
                onChange={handleChange}
              >
                {statusArray.map((e: string) => (
                  <MenuItem key={e} value={e}>
                    {/* @ts-ignore */}
                    {t(`${e}`)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          {filteredSales.length === 0 ? (
            <h3>{t("no-sales")}</h3>
          ) : (
            <AdminSalesTable
              filteredSales={filteredSales}
              showUserName={name}
              isAdminView={isAdminView}
            />
          )}
        </>
      </>
    </Container>
  );
};

export default Sales;
