import styled, { keyframes } from "styled-components";
import { fadeIn } from "react-animations";
const fadeAnimation = keyframes`${fadeIn}`;

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  min-height: 100vh;
  padding: 1px;
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: ${fadeAnimation} 1s;

  header {
    text-align: center;
  }
  .tenantPageLogo {
    width: 220px;
    height: 120px;
  }

  @media (min-width: 1100px) {
    max-width: 80vw;
    padding: 0px 200px;
    margin: 0 auto;
    width: 100%;
  }
`;
