import styled from "styled-components";

export const Container = styled.div`
  padding: 1px;
  margin: 1px;
  th,
  td {
    border: 1px solid rgba(224, 224, 224, 1) !important;
  }
  .inputNone {
    opacity: 0;
    width: 1px;
  }
  .updateInvoiceDiv {
    display: flex;
    justify-content: center;
    gap: 3px;
    align-items: center;
  }
  .updateProductIcon {
    background-color: ${({ theme }) => theme.colors.primary};
    width: 25px;
    border-radius: 100%;
    text-align: center;
    height: 25px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .updateProductIcon:hover {
    cursor: pointer;
    background-color: #1272a2;
  }
  .updateProductIcon > span {
    display: flex;
  }

  .iconInvoice {
    width: 25px;
    height: 25px;
  }
  .pdfLink {
    color: ${({ theme }) => theme.colors.primary};
  }
  .attachedDiv {
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
  }
  .removeFile {
    font-size: 1.1rem;
    line-height: 12px;
    display: hidden;
    color: #c4c4c4;
  }
  .removeFile:hover {
    color: #777;

    cursor: pointer;
  }

  .noteInvoice {
    span {
      button {
        opacity: 0;
      }
    }
  }
  .noteInvoice:hover {
    span {
      button {
        opacity: 1;
      }
    }
  }
  .invoiceChange:hover {
    cursor: pointer;
    opacity: 0.2;
  }
`;
