import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineBarChart } from "react-icons/ai";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useSeller } from "../../providers/users";
import { Container } from "./styles";
import { ISale } from "../../providers/sales/sales.interface";

const Chart = () => {
  const { sales } = useSeller();
  const [chartSales, setChartSales] = useState<any>([]);
  const { t } = useTranslation();
  const salesByMonth = (sales: ISale[]) => {
    const monthsArray = [];
    const today = new Date();
    let month = today.getMonth();

    let year = today.getFullYear() - 1;
    for (let i = 1; i <= 13; i++) {
      if (month + i > 12) {
        month = -(12 - month);
        year = today.getFullYear();
      }
      const auxObj: any = {};
      auxObj["x"] = `${month + i}/${year}`;
      auxObj["vendas"] = 0;
      monthsArray.push(auxObj);
    }
    for (let i = 0; i < sales.length; i++) {
      const date = new Date(sales[i].createdAt);
      for (let j = 0; j < monthsArray.length; j++) {
        if (
          `${date.getMonth() + 1}/${date.getFullYear()}` === monthsArray[j].x
        ) {
          monthsArray[j].vendas = monthsArray[j].vendas + 1;
        }
      }
    }

    setChartSales(monthsArray);
  };

  useEffect(() => {
    salesByMonth(sales);
  }, [sales]);

  return (
    <>
      {" "}
      <Container>
        <h2 className="chartsTitle">
          <span>
            <AiOutlineBarChart />
          </span>{" "}
          {t("chartTitle")}
        </h2>{" "}
        <div className="chartsContainer">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              width={500}
              height={300}
              data={chartSales}
              margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
            >
              <Line
                type="monotone"
                dataKey="vendas"
                name={t("sales")}
                stroke="#8884d8"
              />
              <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
              <XAxis dataKey="x" />
              <YAxis domain={[0, 50]} />
              <Tooltip />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </Container>
    </>
  );
};
export default Chart;
