import { useEffect, useState } from "react";
import * as yup from "yup";
import { useSeller } from "../../providers/users";
import Loading from "../Loading";
import { Container } from "./styles";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  FormHelperText,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { usePlaylistTraining } from "../../providers/playlistsTrainings";
import { IPlaylist } from "../../providers/playlistsTrainings/playlistAndTrainings.interface";
import ModalConfirmDeletion from "../ModalConfirmDeletion";
import PageHeader from "../pageHeader";
import { IoCreateOutline } from "react-icons/io5";

const PlaylistsUpdateForm = () => {
  const { apiLoading } = useSeller();
  const navigate = useNavigate();
  const { editPlaylist, allPlaylists, deletePlaylist } = usePlaylistTraining();
  const [language, setLanguage] = useState("english");
  const { path, playlist_id } = useParams();
  const { t } = useTranslation();
  const [modalConfirmDeletion, setModalConfirmDeletion] = useState(false);
  const [playlist, setPlaylist] = useState<IPlaylist | null>(null);
  useEffect(() => {
    if (allPlaylists) {
      const playlistFromId = allPlaylists.find(
        (e: IPlaylist) => e.id === playlist_id
      );
      if (!playlistFromId) {
        navigate("/" + path + "/trainings");
      }
      setPlaylist(playlistFromId);
      setLanguage(playlistFromId.language);
    }
  }, [allPlaylists, navigate, path, playlist_id]);

  const handleChange = (event: any) => {
    setLanguage(event.target.value);
  };
  function removeEmptyFields(data: any) {
    Object.keys(data).forEach((key) => {
      if (data[key] === "" || data[key] == null) {
        delete data[key];
      }
    });
  }
  type FormValues = yup.InferType<typeof formSchema>;
  const onSubmit = async (data: FormValues) => {
    removeEmptyFields(data);
    await editPlaylist(playlist_id, data);
  };
  const formSchema = yup
    .object()
    .shape({
      name: yup.string().max(128, t("name_max")),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  return (
    <>
      <Container>
        <PageHeader
          title={t("update_playlist")}
          description={`${t("fill_update")} playlist`}
          icon={<IoCreateOutline />}
        />

        {playlist && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              error={errors.name === undefined ? false : true}
              helperText={errors.name?.message?.toString()}
              id="standard-name"
              label={t("name")}
              defaultValue={playlist.name}
              variant="standard"
              {...register("name")}
            />

            <FormControl>
              <InputLabel id="demo-simple-select-label">
                {t("language")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={language}
                label={t("language")}
                {...register("language")}
                onChange={handleChange}
              >
                <MenuItem value={"english"}>{t("english")}</MenuItem>
                <MenuItem value={"portuguese"}>{t("portuguese")}</MenuItem>
              </Select>
            </FormControl>
            <FormHelperText> {t("no_fill")}</FormHelperText>
            {apiLoading ? (
              <Loading />
            ) : (
              <div>
                <Button type="submit" variant="contained">
                  {t("confirm")}
                </Button>
                <Button
                  sx={{ ml: 1 }}
                  onClick={() => navigate("/" + path + "/trainings")}
                  variant="outlined"
                >
                  {t("cancel")}
                </Button>
                <Button
                  sx={{ ml: 1 }}
                  onClick={() => setModalConfirmDeletion(true)}
                  variant="outlined"
                  color="error"
                >
                  {t("delete")}
                </Button>
              </div>
            )}
          </form>
        )}
      </Container>
      {modalConfirmDeletion && (
        <ModalConfirmDeletion
          setModalConfirmDeletion={setModalConfirmDeletion}
          deleteId={playlist_id!}
          deleteFunction={deletePlaylist}
        />
      )}
    </>
  );
};

export default PlaylistsUpdateForm;
