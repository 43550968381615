import PaymentMethodCreationForm from "../../components/PaymentMethodCreationForm";

const PaymentMethodCreation = () => {
  return (
    <>
      <PaymentMethodCreationForm />
    </>
  );
};

export default PaymentMethodCreation;
