import styled from "styled-components";

interface IProps {
  status: number;
}

export const Container = styled.div<IProps>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid lightgrey;

  &:hover {
    opacity: 0.5;
    cursor: pointer;
  }
  .recruitmentContainer {
    display: flex;
    align-items: center;
  }

  .activeBall {
    min-width: 40px;
    min-height: 40px;
    background-color: ${(props) => (props.status ? "green" : "red")};
    border-radius: 100%;
  }
  .recruitmentInfo {
    padding: 15px;
    display: flex;
    font-size: 0.75rem;
    justify-content: space-evenly;
    flex-direction: column;
    /* align-items: center; */
    gap: 5px;
    max-height: 250px;
  }
  .recruitmentItemHeader {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    /* justify-content: space-between; */
  }
  .recruitmentName {
    font-weight: bold;
    color: ${({ theme }) => theme.colors.primary};
    text-transform: capitalize;
  }
  .recruitmentStatus {
    color: ${(props) => (props.status ? "green" : "red")};
  }
  .recruitmentDescription {
    /* text-align: center; */
    /* height: 45px; */
  }
  .recruitmentCreatedBy {
    color: grey;
  }
  .recruitmentLink {
    margin-right: 30px;
    position: relative;
    z-index: 1;
  }
  .recruitmentLink button:hover {
    cursor: copy;
  }
  .recruitmentLink > button {
    padding: 5px;
    border-radius: 9px;
    border: none;
    background-color: ${({ theme }) => theme.colors.primary};
  }
  .recruitmentLink > button > svg {
    width: 20px;
    height: 20px;
    color: white;
  }
  @media (min-width: 1100px) {
    /* max-width: 30%; */
    img {
      max-height: 275px;
    }
  }
`;

export const ActiveBall = styled.div<IProps>`
  min-width: 40px;
  min-height: 40px;
  background-color: ${(props) => (props.status ? "green" : "red")};
  border-radius: 100%;
`;
