import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Checkbox, FormControlLabel } from "@mui/material";
import axios from "axios";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useCandidature } from "../../providers/candidatures";
import { ICandidatureFromSeller } from "../../providers/candidatures/candidatures.interface";
import { useTenant } from "../../providers/tenant";
import { useSeller } from "../../providers/users";
import Loading from "../Loading";
import { formSchema } from "./resolvers";
import { Container } from "./styles";
interface IProps {
  candidature: ICandidatureFromSeller;
}

const AcceptTermsCreationForm = ({ candidature }: IProps) => {
  const { apiLoading, setApiLoading, createLegalTerms, seller } = useSeller();
  const { updateCandidature } = useCandidature();
  const { getTenantTerms, tenantTerms } = useTenant();
  const { t } = useTranslation();

  useEffect(() => {
    getTenantTerms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seller]);

  type FormValues = yup.InferType<typeof formSchema>;
  const onSubmit = async (data: FormValues) => {
    setApiLoading(true);
    await axios
      .get("https://api.ipify.org?format=json")
      .then((res) => (data.ip = res.data.ip))
      .catch((err) => {
        toast.error(t("error_accepting"));
        return;
      });
    setApiLoading(false);
    data.terms_version = tenantTerms.terms_conditions_version;
    data.privacy_policy_version = tenantTerms.privacy_policy_version;
    data.sales_manual_version = tenantTerms.sales_manual_version;

    await createLegalTerms(data);
    updateCandidature(candidature.id, {
      recruitment_phase: "approved_2_phase_accepted_terms",
    });
  };

  const { register, handleSubmit } = useForm({
    resolver: yupResolver(formSchema),
  });

  return (
    <Container>
      {apiLoading ? (
        <Loading />
      ) : (
        <>
          <h4>{t("legal_terms")}</h4>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="checkboxContainer">
              <h5>{t("terms_conditions")}</h5>

              <a
                href={tenantTerms && tenantTerms.terms_conditions}
                target="_blank"
                rel="noreferrer"
              >
                <Button variant="outlined">{t("read_terms_conditions")}</Button>
              </a>
              <FormControlLabel
                required
                control={<Checkbox />}
                {...register("accepted_terms")}
                label={t("read_and_accepted_terms")}
              />
            </div>
            <div className="checkboxContainer">
              <h5>{t("privacy_policy")}</h5>
              <a
                href={tenantTerms && tenantTerms.privacy_policy}
                target="_blank"
                rel="noreferrer"
              >
                <Button variant="outlined">{t("read_privacy_policy")}</Button>
              </a>
              <FormControlLabel
                required
                control={<Checkbox />}
                {...register("accepted_privacy_policy")}
                label={t("read_and_accepted_policy")}
              />
            </div>
            <div className="checkboxContainer">
              <h5>{t("sales_agent_manual")}</h5>
              <a
                href={tenantTerms && tenantTerms.sales_manual}
                target="_blank"
                rel="noreferrer"
              >
                <Button variant="outlined">
                  {t("read_sales_agent_manual")}
                </Button>
              </a>
              <FormControlLabel
                required
                control={<Checkbox />}
                {...register("accepted_sales_manual")}
                label={t("read_and_accepted_manual")}
              />
            </div>
            <div className="divButton">
              {apiLoading ? (
                <Loading />
              ) : (
                <Button type="submit" variant="contained">
                  {t("submit")}
                </Button>
              )}
            </div>
          </form>
        </>
      )}
    </Container>
  );
};

export default AcceptTermsCreationForm;
