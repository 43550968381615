import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AiOutlineLink } from "react-icons/ai";
import { IoCreateOutline } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { IPaymentMethod } from "../../providers/paymentMethods/paymentMethods.interface";
import { useProduct } from "../../providers/products";
import { IProduct } from "../../providers/products/products.interface";
import { useSeller } from "../../providers/users";
import Loading from "../Loading";
import PageHeader from "../pageHeader";
import { Container } from "./styles";
import { useSale } from "../../providers/sales";
const SalesContestationCreationForm = () => {
  const { apiLoading } = useSeller();
  const { createSaleContestation } = useSale();
  const navigate = useNavigate();
  const [file, setFile] = useState<File[] | null>(null);
  const { path } = useParams();
  const { allProducts } = useProduct();
  const { t } = useTranslation();
  const [product, setProduct] = useState<IProduct | null>(null);
  const [currency, setCurrency] = useState<string>("");
  const [paymentMethod, setPaymentMethod] = useState<IPaymentMethod | null>(
    null
  );
  type FormValues = yup.InferType<typeof formSchema>;

  const onSubmit = async (data: FormValues) => {
    setFile(null);
    if (!paymentMethod || !product) {
      return;
    }
    data.payment_method_id = paymentMethod.id;

    data.order_amount = data.order_amount.toString();
    data.product_name = product.name;
    await createSaleContestation(data);
  };
  const handleProduct = (event: any) => {
    setProduct(event.target.value);
  };
  const handlePaymentMethod = (event: any) => {
    setPaymentMethod(event.target.value);
  };
  const handleCurrency = (event: any) => {
    setCurrency(event.target.value);
  };
  const handleFileUpload = (e: any) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files;

    setFile(Object.values(file));
  };
  const formSchema = yup
    .object()
    .shape({
      customer_first_name: yup
        .string()
        .required(t("first_name_required"))
        .max(128, t("first_name_max")),
      customer_last_name: yup
        .string()
        .required(t("last_name_required"))
        .max(128, t("last_name_max")),
      customer_email: yup
        .string()
        .required(t("email_required"))
        .email(t("email_invalid")),
      currency: yup
        .string()
        .required(t("currency_required"))
        .test(
          "currency test",
          "currency must be USD or BRL",
          (currency) => currency === "BRL" || currency === "USD"
        ),
      order_amount: yup
        .number()
        .typeError(t("value_typeerror"))
        .required(t("value_required")),
      order_id: yup
        .string()
        .required(t("order_id_required"))
        .max(128, t("order_id_max")),
      subscription_id: yup
        .string()
        .required(t("subscription_id_required"))
        .max(128, t("subscription_id_max")),
      transaction_id: yup
        .string()
        .required(t("transaction_id_required"))
        .max(128, t("transaction_id_max")),
      charge_id: yup
        .string()
        .required(t("charge_id_required"))
        .max(128, t("charge_id_max")),
      payment_processor: yup
        .string()
        .required(t("payment_processor_required"))
        .max(128, t("payment_processor_max")),
      note: yup.string().max(128, t("note_length")),
      fileInput: yup
        .mixed()
        .required(t("file_required"))
        .test("fileSize", t("file_size"), (value) => {
          return value && value[0].size <= 1000 * 1024 * 1024;
        })
        .test(
          "type",
          t("file_accept", { file_types: ".jpeg, .jpg, .gif, .png" }),
          (value) => {
            return (
              value &&
              (value[0].type === "image/jpg" ||
                value[0].type === "image/jpeg" ||
                value[0].type === "image/gif" ||
                value[0].type === "image/png")
            );
          }
        ),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  return (
    <Container>
      <PageHeader
        title={t("new_sales_analysis")}
        description={`${t("fill_create")} ${t("sales_analysis")}`}
        icon={<IoCreateOutline />}
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          error={errors.customer_email === undefined ? false : true}
          helperText={errors.customer_email?.message?.toString()}
          id="standard-customer_email"
          label={t("customer_email")}
          variant="standard"
          {...register("customer_email")}
        />
        <TextField
          id="standard-customer_first_name"
          error={errors.customer_first_name === undefined ? false : true}
          helperText={errors.customer_first_name?.message?.toString()}
          label={t("customer_first_name")}
          variant="standard"
          {...register("customer_first_name")}
        />
        <TextField
          id="standard-customer_last_name"
          error={errors.customer_last_name === undefined ? false : true}
          helperText={errors.customer_last_name?.message?.toString()}
          label={t("customer_last_name")}
          variant="standard"
          {...register("customer_last_name")}
        />

        <FormControl sx={{ mt: 1 }}>
          <InputLabel id="select-currency">{t("currency")}</InputLabel>
          <Select
            labelId="select-currency"
            id="select-currency"
            value={currency}
            required
            label={t("currency")}
            {...register("currency")}
            onChange={handleCurrency}
          >
            <MenuItem value="USD">USD</MenuItem>
            <MenuItem value="BRL">BRL</MenuItem>
          </Select>
        </FormControl>
        <TextField
          id="standard-order_amount"
          error={errors.order_amount === undefined ? false : true}
          helperText={errors.order_amount?.message?.toString()}
          label={t("value")}
          variant="standard"
          {...register("order_amount")}
        />
        <TextField
          id="standard-payment_processor"
          error={errors.payment_processor === undefined ? false : true}
          helperText={errors.payment_processor?.message?.toString()}
          label={t("payment_processor")}
          variant="standard"
          placeholder={t("example_abbreviation") + " Stripe"}
          {...register("payment_processor")}
        />
        <TextField
          id="standard-note"
          error={errors.note === undefined ? false : true}
          helperText={errors.note?.message?.toString()}
          label={t("note")}
          placeholder={t("not_required")}
          variant="standard"
          {...register("note")}
        />
        <TextField
          id="standard-order_id"
          error={errors.order_id === undefined ? false : true}
          helperText={errors.order_id?.message?.toString()}
          label={t("order_id")}
          variant="standard"
          {...register("order_id")}
        />
        <TextField
          id="standard-subscription_id"
          error={errors.subscription_id === undefined ? false : true}
          helperText={errors.subscription_id?.message?.toString()}
          label={t("subscription_id")}
          variant="standard"
          {...register("subscription_id")}
        />
        <TextField
          id="standard-transaction_id"
          error={errors.transaction_id === undefined ? false : true}
          helperText={errors.transaction_id?.message?.toString()}
          label={t("transaction_id")}
          variant="standard"
          {...register("transaction_id")}
        />
        <TextField
          id="standard-charge_id"
          error={errors.charge_id === undefined ? false : true}
          helperText={errors.charge_id?.message?.toString()}
          label={t("charge_id")}
          variant="standard"
          {...register("charge_id")}
        />

        <Button
          component="label"
          variant="outlined"
          startIcon={<AiOutlineLink />}
        >
          {t("files_upload")}
          <input
            {...register("fileInput")}
            className="inputNone"
            type="file"
            accept=".png, .jpg, .jpeg"
            required
            multiple
            onChange={handleFileUpload}
          />
        </Button>
        <p className="errosForm">
          {errors.fileInput && errors.fileInput.message?.toString()}
        </p>
        <FormHelperText>{t("files_message")}</FormHelperText>
        {file && file.map((e) => <Box key={e.name}>{e?.name}</Box>)}
        <FormControl>
          <InputLabel id="select-product">{t("products")}</InputLabel>
          <Select
            labelId="select-product"
            id="select-product"
            value={product == null ? "" : product}
            required
            label={t("product")}
            onChange={handleProduct}
          >
            {allProducts &&
              allProducts.map((e: IProduct) => (
                //@ts-ignore
                <MenuItem key={e.id} value={e}>
                  {e.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        {product && (
          <FormControl>
            <InputLabel id="select-payment-method">
              {t("payment_method")}
            </InputLabel>
            <Select
              labelId="select-payment-method"
              id="select-payment-method"
              value={paymentMethod == null ? "" : paymentMethod}
              required
              label={t("payment_method")}
              onChange={handlePaymentMethod}
            >
              {product &&
                //@ts-ignore
                product.payment_methods!.map((e: IPaymentMethod) => (
                  //@ts-ignore
                  <MenuItem key={e.id} value={e}>
                    {e.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}

        {apiLoading ? (
          <Loading />
        ) : allProducts && allProducts.length === 0 ? (
          <div>
            <p className="emptyPlaylistMessage">{t("empty_product_message")}</p>
            <Button
              sx={{ ml: 1 }}
              onClick={() => navigate("/" + path + "/sales")}
              variant="outlined"
            >
              {t("back")}
            </Button>
          </div>
        ) : (
          <div>
            <Button type="submit" variant="contained">
              {t("create")}
            </Button>
            <Button
              sx={{ ml: 1 }}
              onClick={() => navigate("/" + path + "/sales")}
              variant="outlined"
            >
              {t("cancel")}
            </Button>
          </div>
        )}
      </form>
    </Container>
  );
};

export default SalesContestationCreationForm;
