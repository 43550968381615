import styled, { keyframes } from "styled-components";
import { fadeIn } from "react-animations";
const fadeAnimation = keyframes`${fadeIn}`;

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.gray};
  min-height: 100vh;
  padding: 15px;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  header {
    text-align: center;
  }
  .candidatePageLogo {
    margin: 20px 0px;
    width: 180px;
    height: 80px;
  }
  .candidatureMain {
    animation: ${fadeAnimation} 1s;
    text-align: center;
  }

  @media (min-width: 800px) {
    display: flex;
    padding: 0px;

    main {
      padding: 40px 50px;

      width: 100%;
    }
  }

  @media (min-width: 1100px) {
    .candidatePageLogo {
      margin: 20px 0px;
      width: 200px;
      height: 80px;
    }

    main {
      max-width: 80vw;
      padding: 40px 200px;
      margin: 0 auto;
      width: 100%;
    }
  }
`;
