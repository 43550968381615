import { toast } from "react-hot-toast";
import { AiOutlinePaperClip } from "react-icons/ai";
import { Container } from "./styles";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";

interface IProps {
  link: string;
}
const SellerLink = ({ link }: IProps) => {
  const { t } = useTranslation();
  return (
    <Container>
      <div className="sellerLink">
        <p>{t("generated-link")}</p>
        <div className="sellerLinkCopy">
          <p>{link}</p>
          <CopyToClipboard
            onCopy={() => toast.success(t("copied"))}
            text={link}
          >
            <button>
              {" "}
              <AiOutlinePaperClip />
            </button>
          </CopyToClipboard>
        </div>
        <span>{t("click-copy")}</span>
      </div>
    </Container>
  );
};

export default SellerLink;
