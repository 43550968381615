import { createContext, useContext, useState } from "react";
import { toast } from "react-toastify";
import api from "../../services";
import { useSeller } from "../users";
import { IProviderProps } from "../tenant/tenant.interfaces";
import { ICreateInvoice, IInvoice, IUpdateInvoice } from "./invoices.interface";
import { useTenant } from "../tenant";
import { useLocation, useNavigate } from "react-router-dom";

export const InvoicesContext = createContext<any | null>(null);
export const InvoiceProvider = ({ children }: IProviderProps) => {
  const { token, setApiLoading, setPageMounting } = useSeller();
  const { getTenantUsers } = useTenant();
  const location = useLocation();
  const path = location.pathname.split("/")[1];
  const navigate = useNavigate();
  const [allInvoices, setAllInvoices] = useState<IInvoice[] | null>(null);
  const getAllInvoices = () => {
    setPageMounting(true);
    api
      .get("/invoices", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setAllInvoices(res.data);
        setPageMounting(false);
      })
      .catch((err) => {
        setPageMounting(false);
        console.log(err.response.data.message);
      });
  };

  const addAttachmentToInvoice = async (
    invoice_id: string,
    file: File,
    attachmentType: string,
    isAdminView: boolean
  ) => {
    setApiLoading(true);
    const dataFile = new FormData();
    dataFile.append("file", file);

    const added = await api
      .post(`/invoices/${invoice_id}/${attachmentType}`, dataFile, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setApiLoading(false);
        if (isAdminView) {
          getTenantUsers();
        } else {
          getAllInvoices();
        }
        return true;
      })
      .catch((err) => {
        setApiLoading(false);
        toast.error(err.response.data.message);
        console.log(err.response.data);
        return false;
      });
    if (added) {
      return true;
    }
  };
  const updateInvoice = async (
    invoices_id: string,
    data: IUpdateInvoice,
    isAdminView: boolean
  ) => {
    setApiLoading(true);
    const updated = await api
      .patch(`/invoices/${invoices_id}`, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setApiLoading(false);
        if (isAdminView) {
          getTenantUsers();
        } else {
          getAllInvoices();
        }
        return true;
      })
      .catch((err) => {
        setApiLoading(false);
        toast.error(err.response.data.message);
        console.log(err.response.data.message);
        return false;
      });
    if (updated) {
      return true;
    }
  };

  const createInvoice = async (data: ICreateInvoice) => {
    setApiLoading(true);
    const updated = await api
      .post(`/invoices`, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setApiLoading(false);
        getAllInvoices();
        return true;
      })
      .catch((err) => {
        setApiLoading(false);
        toast.error(err.response.data.message);
        console.log(err.response.data.message);
        return false;
      });
    if (updated) {
      return true;
    }
  };

  const deleteInvoiceAttachment = (id: string, invoice_id: string) => {
    setApiLoading(true);

    api
      .delete(`/invoices/file/${id}/${invoice_id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (location.pathname.split("/").includes("users")) {
          getTenantUsers();
        } else {
          getAllInvoices();
        }
        setApiLoading(false);
      })
      .catch((err) => {
        setApiLoading(false);
        console.log(err.response.data.message);
      });
  };
  const deleteInvoice = (id: string) => {
    setApiLoading(true);

    api
      .delete(`/invoices/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        getAllInvoices();

        setApiLoading(false);
        navigate("/" + path + "/invoices");
      })
      .catch((err) => {
        setApiLoading(false);
        console.log(err.response.data.message);
      });
  };
  return (
    <InvoicesContext.Provider
      value={{
        addAttachmentToInvoice,
        updateInvoice,
        createInvoice,
        deleteInvoiceAttachment,
        getAllInvoices,
        allInvoices,
        deleteInvoice,
      }}
    >
      {children}
    </InvoicesContext.Provider>
  );
};

export const useInvoice = () => useContext(InvoicesContext);
