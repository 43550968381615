import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SendPasswordRecoveryForm from "../../components/SendPasswordRecoveryCode";
import { useSeller } from "../../providers/users";
import { Container } from "./styles";
import PasswordRecoveryForm from "../../components/PasswordRecoveryForm";
import logo from "../../assets/logo-removebg-preview.svg";

const SendPasswordRecoveryPage = () => {
  const { token } = useSeller();
  const navigate = useNavigate();
  const { path } = useParams();
  const [page, setPage] = useState(0);
  const [email, setEmail] = useState<string | null>(null);
  useEffect(() => {
    if (token) {
      navigate("/" + path + "/dashboard");
    }
  }, []);

  return (
    <Container>
      <header>
        <img src={logo} alt="SPP Logo" className="loginLogo" />
      </header>
      <main>
        {email && page === 1 ? (
          <PasswordRecoveryForm email={email} />
        ) : (
          <SendPasswordRecoveryForm setPage={setPage} setEmail={setEmail} />
        )}
      </main>
    </Container>
  );
};

export default SendPasswordRecoveryPage;
