import { yupResolver } from "@hookform/resolvers/yup";
import { Button, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useTenant } from "../../providers/tenant";
import { useSeller } from "../../providers/users";
import Loading from "../Loading";
import { Container } from "./styles";

const FindTenantForm = () => {
  const { apiLoading } = useSeller();
  const { getTenantByPath } = useTenant();

  const { t } = useTranslation();
  const formSchema = yup
    .object()
    .shape({
      path: yup.string().required(t("tenant_path_required")),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });
  type FormValues = yup.InferType<typeof formSchema>;
  const onSubmit = async (data: FormValues) => {
    await getTenantByPath(data);
  };

  return (
    <Container onSubmit={handleSubmit(onSubmit)}>
      <p className="tenantFindMessage">{t("tenant_find_message")}</p>
      <TextField
        error={errors.path === undefined ? false : true}
        helperText={errors.path?.message?.toString()}
        id="standard-path"
        label={t("tenant_path")}
        variant="standard"
        {...register("path")}
      />

      {apiLoading ? (
        <Loading />
      ) : (
        <Button variant="contained" type="submit">
          {t("confirm")}
        </Button>
      )}
    </Container>
  );
};

export default FindTenantForm;
