import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BsCart4 } from "react-icons/bs";
import { useProduct } from "../../providers/products";
import { IProduct } from "../../providers/products/products.interface";
import { useSeller } from "../../providers/users";
import ProductsItem from "../ProductItem";
import PageHeader from "../pageHeader";
import { Container } from "./styles";

const ProductsList = () => {
  const { roleAdmin } = useSeller();
  const [language, setLanguage] = useState("english");
  const { allProducts } = useProduct();
  const { t } = useTranslation();

  const handleChange = (event: any, newLanguage: any) => {
    if (!newLanguage) {
      return;
    }
    setLanguage(newLanguage);
  };
  const filteredProducts: IProduct[] = allProducts
    ? allProducts.filter((e: IProduct) => e.language === language)
    : [];

  return (
    <Container>
      <PageHeader
        title={t("products")}
        description={t("products_list")}
        buttonContent={t("create")}
        buttonPath="products/create"
        verifyRole={true}
        roleToVerify={roleAdmin}
        icon={<BsCart4 />}
      />
      <ToggleButtonGroup
        size="small"
        color="primary"
        value={language}
        exclusive
        onChange={handleChange}
        aria-label="Platform"
      >
        <ToggleButton size="small" value="english">
          {t("english")}
        </ToggleButton>
        <ToggleButton size="small" value="portuguese">
          {t("portuguese")}
        </ToggleButton>
      </ToggleButtonGroup>
      {allProducts && filteredProducts.length === 0 ? (
        <div className="productList">
          <h4> {t("no-product-found")}</h4>
        </div>
      ) : (
        <div className="productList">
          {filteredProducts &&
            filteredProducts.map((e: IProduct) => (
              <ProductsItem product={e} key={e.id} />
            ))}
        </div>
      )}
    </Container>
  );
};

export default ProductsList;
