import { yupResolver } from "@hookform/resolvers/yup";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { useSeller } from "../../providers/users";
import Loading from "../Loading";
import { Container } from "./styles";

const LoginForm = () => {
  const { Login, apiLoading } = useSeller();
  const { path } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const formSchema = yup
    .object()
    .shape({
      email: yup
        .string()
        .required(t("email_required"))
        .email(t("email_invalid")),
      password: yup.string().required(t("password_required")),
    })
    .required();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });
  type FormValues = yup.InferType<typeof formSchema>;
  const onSubmit = async (data: FormValues) => {
    await Login(data);
  };

  return (
    <Container onSubmit={handleSubmit(onSubmit)}>
      <TextField
        error={errors.email === undefined ? false : true}
        helperText={errors.email?.message?.toString()}
        id="standard-email"
        label={t("email")}
        autoComplete="email"
        variant="standard"
        {...register("email")}
      />
      <TextField
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        error={errors.password === undefined ? false : true}
        helperText={errors.password?.message?.toString()}
        id="standard-password"
        label={t("password")}
        type={showPassword ? "text" : "password"}
        variant="standard"
        autoComplete="current-password"
        {...register("password")}
      />
      <p
        className="forgotPasswordLink"
        onClick={() => navigate(`/${path}/recovery/password`)}
      >
        {t("forgot_password")}
      </p>
      {apiLoading ? (
        <Loading />
      ) : (
        <Button variant="contained" type="submit">
          {t("singin")}
        </Button>
      )}
    </Container>
  );
};

export default LoginForm;
