import styled from "styled-components";

export const Container = styled.div`
  border-bottom: 1px solid lightgrey;

  .datePickerContainer {
    margin-top: 5px;
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }
  .react-daterange-picker__wrapper {
    border-radius: 5px;
    padding: 5px;
  }

  .filterAndUpdateContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .custom-select select {
    background-color: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.black};
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 1em;
    padding: 8px;
    min-width: 267px;
    color: ${({ theme }) => theme.colors.black};
    border-radius: 5px;
  }

  .custom-select select:hover {
    cursor: pointer;
  }

  @media (min-width: 800px) {
    .datePickerContainer {
      align-items: flex-end;
    }
    margin: 0px auto;
    max-width: 65vw;
  }
  @media (min-width: 1100px) {
    max-width: 80vw;
  }
`;
