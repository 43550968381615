import PaymentMethodUpdateForm from "../../components/PaymentMethodUpdateForm";

const PaymentMethodUpdate = () => {
  return (
    <>
      <PaymentMethodUpdateForm />
    </>
  );
};

export default PaymentMethodUpdate;
