import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { useInvoice } from "../../providers/invoices";
import { IInvoice } from "../../providers/invoices/invoices.interface";
import { useSeller } from "../../providers/users";
import Loading from "../Loading";
import { Container } from "./styles";
import PageHeader from "../pageHeader";
import { IoCreateOutline } from "react-icons/io5";
import ModalConfirmDeletion from "../ModalConfirmDeletion";

const InvoicesUpdateForm = () => {
  const { apiLoading } = useSeller();
  const navigate = useNavigate();
  const { updateInvoice, allInvoices, deleteInvoice } = useInvoice();
  const [month, setMonth] = useState<Number>(1);
  const [invoice, setInvoice] = useState<any>(null);
  const { invoice_id, path } = useParams();
  const { t } = useTranslation();
  const [modalConfirmDeletion, setModalConfirmDeletion] = useState(false);
  useEffect(() => {
    const invoiceFromId =
      allInvoices && allInvoices.find((e: IInvoice) => e.id === invoice_id);
    if (invoiceFromId) {
      setInvoice(invoiceFromId);
      setMonth(Number(moment().month(invoiceFromId.month).format("M")));
    } else {
      navigate("/" + path + "/invoices");
    }
  }, [invoice_id, navigate, path, allInvoices]);

  const handleChange = (event: any) => {
    setMonth(event.target.value);
  };

  function removeEmptyFields(data: any) {
    Object.keys(data).forEach((key) => {
      if (data[key] === "" || data[key] == null || data[key] === invoice[key]) {
        delete data[key];
      }
      if (data[key] === Number(moment().month(invoice.month).format("M"))) {
        delete data[key];
      }
    });
  }
  type FormValues = yup.InferType<typeof formSchema>;
  const onSubmit = async (data: FormValues) => {
    removeEmptyFields(data);

    if (Object.keys(data).length === 0) {
      navigate(`/${path}/invoices`);
      return;
    }
    data.month = month;
    const updated = await updateInvoice(invoice_id, data);
    if (updated) {
      navigate(`/${path}/invoices`);
    }
  };
  const formSchema = yup
    .object()
    .shape({
      month: yup
        .number()
        .min(1, t("month_min_typeerror"))
        .max(12, t("month_max_typeerror")),
      year: yup
        .number()
        .transform((value) =>
          isNaN(value) || value === null || value === undefined ? null : value
        )
        .nullable(),
      note: yup.string().max(256, t("note_length")),
      status: yup
        .string()
        .max(128, "status length must have less than 128 characters. ")
        .test(
          "status Test",
          "status must be not_applicable, in_progress or concluded",
          function (value: any) {
            return (
              value === "not_applicable" ||
              value === "in_progress" ||
              value === "concluded" ||
              value === undefined ||
              value === null
            );
          }
        ),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  return (
    <>
      <Container>
        <PageHeader
          title={t("update_invoice")}
          description={`${t("fill_update")} ${t("invoice")}`}
          icon={<IoCreateOutline />}
        />

        {invoice && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              error={errors.year === undefined ? false : true}
              helperText={errors.year?.message?.toString()}
              id="standard-year"
              label={t("year")}
              defaultValue={invoice.year}
              variant="standard"
              {...register("year")}
            />

            <FormControl>
              <InputLabel id="demo-simple-select-label">
                {t("month")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={month}
                label={t("month")}
                {...register("month")}
                onChange={handleChange}
              >
                <MenuItem value={1}>{t("january")}</MenuItem>
                <MenuItem value={2}>{t("february")}</MenuItem>
                <MenuItem value={3}>{t("march")}</MenuItem>
                <MenuItem value={4}>{t("april")}</MenuItem>
                <MenuItem value={5}>{t("may")}</MenuItem>
                <MenuItem value={6}>{t("june")}</MenuItem>
                <MenuItem value={7}>{t("july")}</MenuItem>
                <MenuItem value={8}>{t("august")}</MenuItem>
                <MenuItem value={9}>{t("september")}</MenuItem>
                <MenuItem value={10}>{t("october")}</MenuItem>
                <MenuItem value={11}>{t("november")}</MenuItem>
                <MenuItem value={12}>{t("december")}</MenuItem>
              </Select>
              <FormHelperText>
                {errors.month?.message?.toString()}
              </FormHelperText>
            </FormControl>
            <FormHelperText> {t("no_fill")}</FormHelperText>
            {apiLoading ? (
              <Loading />
            ) : (
              <div>
                <Button type="submit" variant="contained">
                  {t("update")}
                </Button>
                <Button
                  sx={{ ml: 1 }}
                  onClick={() => navigate("/" + path + "/invoices")}
                  variant="outlined"
                >
                  {t("cancel")}
                </Button>
                <Button
                  sx={{ ml: 1 }}
                  onClick={() => setModalConfirmDeletion(true)}
                  variant="outlined"
                  color="error"
                >
                  {t("delete")}
                </Button>
              </div>
            )}
          </form>
        )}
      </Container>
      {modalConfirmDeletion && (
        <ModalConfirmDeletion
          setModalConfirmDeletion={setModalConfirmDeletion}
          deleteId={invoice_id!}
          deleteFunction={deleteInvoice}
        />
      )}
    </>
  );
};

export default InvoicesUpdateForm;
