import styled from "styled-components";

export const Container = styled.div`
  h2 {
    text-transform: capitalize;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  form > .checkboxContainer {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
    margin-top: 10px;
  }
  .divButton {
    align-self: flex-end;
  }
`;
