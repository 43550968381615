import styled from "styled-components";

interface IProps {
  src: string;
}

export const Container = styled.div<IProps>`
  text-align: center;
  font-weight: bold;
  max-width: 360px;
  margin-bottom: 5px;
  .imageDiv {
    height: 200px;
    max-width: 360px;
    width: 360px;
    border-radius: 5px;
    background-image: url(${(props) => props.src});
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  }
  .imageDiv:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  .updateTrainingIcon {
    position: relative;
    background-color: ${({ theme }) => theme.colors.primary};
    width: 30px;
    border-radius: 100%;
    text-align: center;
    height: 30px;
    z-index: 1;
    top: 15px;
    right: 0px;
    color: white;
  }
  .updateTrainingIcon:hover {
    cursor: pointer;
    background-color: #1272a2;
  }
  @media (min-width: 1100px) {
    /* max-width: 30%; */
    img {
      max-height: 275px;
      max-width: 160px;
    }
  }
`;
