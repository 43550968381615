import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  FormHelperText,
} from "@mui/material";
import { MuiTelInput } from "mui-tel-input";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AiOutlineLink } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { useSeller } from "../../providers/users";
import Loading from "../Loading";
import { Container } from "./styles";
import PageHeader from "../pageHeader";
import { IoCreateOutline } from "react-icons/io5";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import { ptBR } from "@mui/x-date-pickers/locales";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
const UserUpdateForm = () => {
  const { seller, apiLoading, updateUser } = useSeller();
  const { path } = useParams();
  const navigate = useNavigate();
  const [language, setLanguage] = useState<string>(seller.default_language);
  const [file, setFile] = useState<File | null>(null);
  const { t } = useTranslation();
  const handleChange = (event: any) => {
    setLanguage(event.target.value);
  };

  function removeEmptyFields(data: any) {
    Object.keys(data).forEach((key) => {
      if (data[key] === "" || data[key] == null || data[key] === seller[key]) {
        delete data[key];
      }
    });
  }

  type FormValues = yup.InferType<typeof formSchema>;
  const onSubmit = async (data: FormValues) => {
    setFile(null);
    removeEmptyFields(data);
    data.default_language = language;
    await updateUser(data);
  };
  const handleDateChange = (date: any) => {

    setValue("date_birth", date.$d.toISOString().split("T")[0]); // Atualiza o valor do campo date_birth
  };

  const handleFileUpload = (e: any) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    setFile(file);
  };
  const formSchema = yup
    .object()
    .shape({
      first_name: yup.string().max(128, t("first_name_max")),
      last_name: yup.string().max(128, t("last_name_max")),
      email: yup.string().email(t("email_invalid")),

      phone: yup.string(),
      gender: yup.string(),
      fileInput: yup
        .mixed()
        .notRequired()
        .nullable()
        .test("fileSize", t("file_size"), (value) => {
          return (
            value?.length === 0 ||
            value === undefined ||
            (value && value[0].size <= 1000 * 1024 * 1024)
          );
        })
        .required()

        .test(
          "type",
          t("file_accept", { file_types: ".jpeg, .jpg, .gif, .png" }),
          (value) => {
            return (
              value?.length === 0 ||
              value === undefined ||
              value[0].type === "image/jpg" ||
              value[0].type === "image/jpeg" ||
              value[0].type === "image/gif" ||
              value[0].type === "image/png"
            );
          }
        )
        .notRequired()
        .nullable(),
    })
    .required();

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {
      first_name: seller.first_name,
      last_name: seller.last_name,
      date_birth: seller.date_birth,
      email: seller.email,
      phone: seller.phone,
      gender: seller.gender,
      default_language: seller.default_language,
      fileInput: "",
    },
  });
  useEffect(()=>{
    console.log(errors)
  },[errors])
  return (
    <Container>
      <PageHeader
        title={t("update_user")}
        description={`${t("fill_update")} ${t("profile")}`}
        icon={<IoCreateOutline />}
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          error={errors.first_name === undefined ? false : true}
          helperText={errors.first_name?.message?.toString()}
          id="standard-name"
          label={t("name")}
          variant="standard"
          defaultValue={seller.address?.country}
          {...register("first_name")}
        />

        <TextField
          defaultValue={seller.last_name}
          error={errors.last_name === undefined ? false : true}
          helperText={errors.last_name?.message?.toString()}
          id="standard-last_name"
          label={t("last_name")}
          variant="standard"
          {...register("last_name")}
        />

        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={seller.default_language === "english" ? "" : "pt-br"}
          localeText={
            ptBR.components.MuiLocalizationProvider.defaultProps.localeText
          }
        >
          <DatePicker
            label={t("date_birth")}
            value={dayjs(seller.date_birth)}
            onChange={handleDateChange}
            sx={{ mt: 1 }}
            disableFuture
          />
        </LocalizationProvider>

        <FormControl>
          <FormLabel id="demo-radio-buttons-group-label">
            {t("gender")}
          </FormLabel>
          <Controller
            control={control}
            name={"gender"}
            render={({ field }) => (
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={t("female")}
                {...field}
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value="female"
                  control={<Radio />}
                  label={t("female")}
                />
                <FormControlLabel
                  value="male"
                  control={<Radio />}
                  label={t("male")}
                />
                {/* <FormControlLabel value="other" control={<Radio />} label="Other" /> */}
              </RadioGroup>
            )}
          />
        </FormControl>

        <Controller
          control={control}
          // rules={{
          //   validate: isValidPhoneNumber,
          // }}
          render={({ field, fieldState }) => (
            <MuiTelInput
              {...field}
              defaultCountry="BR"
              variant="standard"
              label={t("phone_number")}
              // defaultValue={seller.phone}
              error={errors.phone === undefined ? false : true}
              helperText={errors.phone?.message?.toString()}
            />
          )}
          name="phone"
        />
        {/* <TextField
          error={errors.email === undefined ? false : true}
          helperText={errors.email?.message?.toString()}
          id="standard-email"
          label={t("email")}
          defaultValue={seller.email}
          variant="standard"
          {...register("email")}
        /> */}

        <Button
          component="label"
          variant="outlined"
          startIcon={<AiOutlineLink />}
        >
          {t("update_profile_image")}
          <input
            {...register("fileInput")}
            className="inputNone"
            type="file"
            accept=".png, .jpg, .jpeg"
            onChange={handleFileUpload}
          />
        </Button>
        <p className="errosForm">
          {errors.fileInput && errors.fileInput.message?.toString()}
        </p>
        <Box>{file?.name}</Box>

        <FormControl>
          <InputLabel id="demo-simple-select-label">Idioma</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={language}
            label={t("language")}
            {...register("default_language")}
            onChange={handleChange}
          >
            <MenuItem value={"english"}>{t("english")}</MenuItem>
            <MenuItem value={"portuguese"}>{t("portuguese")}</MenuItem>
          </Select>
        </FormControl>
        <FormHelperText> {t("no_fill")}</FormHelperText>
        {apiLoading ? (
          <Loading />
        ) : (
          <div>
            <Button type="submit" variant="contained">
              {t("confirm")}
            </Button>
            <Button
              sx={{ ml: 1 }}
              onClick={() => navigate("/" + path + "/profile")}
              variant="outlined"
            >
              {t("cancel")}
            </Button>
          </div>
        )}
      </form>
    </Container>
  );
};

export default UserUpdateForm;
