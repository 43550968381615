import styled, { keyframes } from "styled-components";
import { fadeIn } from "react-animations";
const fadeAnimation = keyframes`${fadeIn}`;
export const Container = styled.form`
  animation: ${fadeAnimation} 1s;

  padding-top: 1px;
  padding-bottom: 15px;
  display: flex;
  flex-direction: column;
  gap: 5px;

  .registerRedirect {
    text-align: center;
    color: ${({ theme }) => theme.colors.primary};
  }
  .registerRedirect:hover {
    opacity: 0.8;
    cursor: pointer;
  }
`;
