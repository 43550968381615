import CandidaturesList from "../../components/CandidaturesList";

const RecruitmentsCandidaturesPage = () => {
  return (
    <>
      <CandidaturesList />
    </>
  );
};

export default RecruitmentsCandidaturesPage;
