import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid lightgrey;

  &:hover {
    opacity: 0.5;
    cursor: pointer;
  }
  .salesTeamContainer {
    display: flex;
    align-items: center;
  }

  .salesTeamInfo {
    padding: 15px;
    display: flex;
    font-size: 0.75rem;
    justify-content: space-evenly;
    flex-direction: column;
    /* align-items: center; */
    gap: 5px;
    max-height: 250px;
  }
  .salesTeamItemHeader {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 5px;
    /* justify-content: space-between; */
  }
  .salesTeamName {
    font-weight: bold;
    color: ${({ theme }) => theme.colors.primary};
    text-transform: capitalize;
  }
  .salesTeamDescription {
    /* color: ${({ theme }) => theme.colors.secondary}; */
  }

  .salesTeamCreatedBy {
    color: grey;
  }
  .salesTeamLink {
    margin-right: 30px;
    position: relative;
    z-index: 1;
  }
  .salesTeamLink button:hover {
    cursor: copy;
  }
  .salesTeamLink > button {
    padding: 5px;
    border-radius: 9px;
    border: none;
    background-color: ${({ theme }) => theme.colors.primary};
  }
  .salesTeamLink > button > svg {
    width: 20px;
    height: 20px;
    color: white;
  }
  @media (min-width: 1100px) {
    /* max-width: 30%; */
    img {
      max-height: 275px;
    }
  }
`;
