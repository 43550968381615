import TrainingUpdateForm from "../../components/TrainingUpdateForm";

const TraningUpdate = () => {
  return (
    <>
      <TrainingUpdateForm />
    </>
  );
};

export default TraningUpdate;
