import RecruitmentsList from "../../components/RecruitmentsList";

const RecruitmentsPage = () => {
  return (
    <>
      <RecruitmentsList />
    </>
  );
};

export default RecruitmentsPage;
