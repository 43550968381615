import InvoicesUpdateForm from "../../components/InvoicesUpdateForm";
import Loading from "../../components/Loading";
import { useSeller } from "../../providers/users";

const InvoicesUpdatePage = () => {
  const { seller, apiLoading } = useSeller();
  return (
    <>{apiLoading === true ? <Loading /> : seller && <InvoicesUpdateForm />}</>
  );
};

export default InvoicesUpdatePage;
