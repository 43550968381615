import SettingsUpdateForm from "../../components/SettingsUpdateForm";

const SettingsUpdate = () => {
  return (
    <>
      <SettingsUpdateForm />
    </>
  );
};

export default SettingsUpdate;
