import { AdminUserList } from "../../components/AdminUsersList";
import { useTenant } from "../../providers/tenant";

const AdminUsersPage = () => {
  const { allUsers } = useTenant();

  return (
    <>
      {" "}
      <AdminUserList currentItems={allUsers} />
    </>
  );
};

export default AdminUsersPage;
