import { Button } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import ModalChangePhase from "../ModalChangePhase";
import { Container } from "./styles";
import { ICandidature } from "../../providers/candidatures/candidatures.interface";

interface IProps {
  candidature: ICandidature;
}

const CandidatureItem = ({ candidature }: IProps) => {
  const [modalChangePhase, setModalChangePhase] = useState(false);
  const { path } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <>
      <Container>
        <div className="candidatureContainer">
          <p>
            <span className="candidatureName">{t("candidate")}: </span>{" "}
            {candidature.user.first_name + " " + candidature.user.last_name}
          </p>
          <p>
            <span className="candidatureName">{t("email")}: </span>{" "}
            {candidature.user.email}
          </p>
          <p>
            <span className="candidatureName">{t("phase")}:</span>{" "}
            {t(candidature.recruitment_phase)}
          </p>
          <p>
            <span className="candidatureName">
              {t("profissional_summary")}:
            </span>{" "}
            {candidature.profissional_summary}
          </p>
          <p>
            <span className="candidatureName">{t("application_date")}:</span>{" "}
            {candidature.createdAt.split("T")[0]}
          </p>
          <p>
            <span className="candidatureName">{t("temperament_test")}:</span>{" "}
            {candidature.temperament_test
              ? candidature.temperament_test
              : t("none")}
          </p>
          <p>
            <span className="candidatureName">{t("video_apresentation")}</span>{" "}
            {candidature.attachments.filter((e) => e.type_name === "video")
              .length > 0 ? (
              <a
                href={
                  candidature.attachments.filter(
                    (e) => e.type_name === "video"
                  )[0].url
                }
                target="_blank"
                rel="noreferrer"
              >
                {t("click_here")}
              </a>
            ) : (
              t("none")
            )}
          </p>
          <p>
            <span className="candidatureName">{t("audio_apresentation")}:</span>{" "}
            {candidature.attachments.filter((e) => e.type_name === "audio")
              .length > 0 ? (
              <a
                href={
                  candidature.attachments.filter(
                    (e) => e.type_name === "audio"
                  )[0].url
                }
                target="_blank"
                rel="noreferrer"
              >
                {t("click_here")}
              </a>
            ) : (
              t("none")
            )}
          </p>
        </div>
        {(candidature && candidature.recruitment_phase === "approved") ||
        (candidature && candidature.recruitment_phase === "rejected") ? (
          <></>
        ) : (
          <div className="phasesButtons">
            <Button
              onClick={() => setModalChangePhase(true)}
              size={"small"}
              variant="contained"
            >
              {t("change_phase")}
            </Button>
            {candidature &&
            candidature.recruitment_phase ===
              "approved_2_phase_accepted_terms" ? (
              <Button
                onClick={() =>
                  navigate(`/${path}/candidature/${candidature.id}`)
                }
                size={"small"}
                variant="contained"
              >
                {t("approve")}
              </Button>
            ) : (
              <></>
            )}
          </div>
        )}
      </Container>
      {modalChangePhase && (
        <ModalChangePhase
          setModalChangePhase={setModalChangePhase}
          candidature_id={candidature.id}
          defaultPhase={candidature.recruitment_phase}
        />
      )}
    </>
  );
};

export default CandidatureItem;
