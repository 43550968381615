import { createContext, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../services";
import { IProviderProps } from "../tenant/tenant.interfaces";
import { useSeller } from "../users";
import {
  IAssistants,
  ICreateAssistant,
  ICreateThread,
  IThread,
  IUpdateAssistant,
} from "./salesCopilot.interface";
import { useTenant } from "../tenant";

export const SalesCopilotContext = createContext<any | null>(null);

export const SalesCopilotProvider = ({ children }: IProviderProps) => {
  const [allAssistants, setAllAssistants] = useState<IAssistants[] | null>(
    null
  );
  const { getTenant } = useTenant();
  const [allThreads, setAllThreads] = useState<IThread[] | null>([]);
  const { token, setApiLoading, setPageMounting } = useSeller();
  const [loadingAI, setLoadingAI] = useState(false);
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const path = location.pathname.split("/")[1];
  const getAllAssistants = async () => {
    setPageMounting(true);
    getTenant();
    await api
      .get("/assistants", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setAllAssistants(res.data);
        setPageMounting(false);
      })
      .catch((err) => {
        console.log(err.response.data.message);
        setPageMounting(false);
      });
  };

  const createAssistant = async (data: ICreateAssistant) => {
    setApiLoading(true);
    let dataFile: File[];
    if (data.fileInput && data.fileInput.length !== 0) {
      dataFile = Object.values(data.fileInput);
    }
    delete data.fileInput;
    const created = await api
      .post("/assistants", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async (res) => {
        for (let i = 0; i < dataFile.length; i++) {
          await uploadFileAssistants(res.data.id, dataFile[i]);
        }
        setApiLoading(false);
        return true;
      })
      .catch((err) => {
        setApiLoading(false);
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
        return false;
      });
    if (created) {
      getAllAssistants();
      navigate(`/${path}/assistants`);
    }
  };

  const uploadFileAssistants = async (
    assistantId: string,
    data: File,
    reload: boolean = false
  ) => {
    const dataFile = new FormData();
    dataFile.append("file", data);

    if (reload) setApiLoading(true);
    await api
      .post(`/assistants/${assistantId}/file`, dataFile, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(async (res) => {
        if (reload) {
          getAllAssistants();
          setApiLoading(false);
          toast.success(t("file_uploaded"));
        }
      })
      .catch((err) => {
        setApiLoading(false);
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
      });
  };

  const updateAssistant = async (id: string, data: IUpdateAssistant) => {
    setApiLoading(true);

    await api
      .put(`/assistants/${id}`, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setApiLoading(false);
        getAllAssistants();
        navigate(`/${path}/assistants`);
      })
      .catch((err) => {
        setApiLoading(false);
        toast.error(err.response.data.message);
        console.log(err.response.data.message);
      });
  };

  const deleteAssistant = async (id: string) => {
    setApiLoading(true);
    await api
      .delete(`/assistants/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setApiLoading(false);
        getAllAssistants();
        navigate(`/${path}/assistants`);
      })
      .catch((err) => {
        setApiLoading(false);
        toast.error(err.response.data.message);
        console.log(err.response.data.message);
      });
  };
  const deleteAssistantFile = async (assistant_id: string, file_id: string) => {
    setApiLoading(true);
    await api
      .delete(`/assistants/${assistant_id}/${file_id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setApiLoading(false);
        getAllAssistants();
        navigate(`/${path}/assistants`);
      })
      .catch((err) => {
        setApiLoading(false);
        toast.error(err.response.data.message);
        console.log(err.response.data.message);
      });
  };

  const getAllThreads = async (mountPage: boolean = true) => {
    if (mountPage) setPageMounting(true);

    await api
      .get("/threads", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setAllThreads(
          res.data.sort(
            (a: IThread, b: IThread) =>
              b.messages[0].created_at - a.messages[0].created_at
          )
        );
        if (mountPage) setPageMounting(false);
      })
      .catch((err) => {
        console.log(err.response.data.message);
        if (mountPage) setPageMounting(false);
      });
  };

  const createThread = async (data: ICreateThread) => {
    setLoadingAI(true);

    const thread = await api
      .post("/threads", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async (res) => {
        setLoadingAI(false);
        allThreads!.unshift(res.data[0]);
        return res.data;
      })
      .catch((err) => {
        setLoadingAI(false);
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
        return false;
      });
    if (thread) {
      return thread;
    }
  };
  const addMessagesToThread = async (data: ICreateThread) => {
    setLoadingAI(true);

    const thread = await api
      .post("/messages", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async (res) => {
        setLoadingAI(false);
        return res.data;
      })
      .catch((err) => {
        setLoadingAI(false);
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
        return false;
      });
    if (thread) {
      return thread;
    }
  };

  const deleteThread = async (id: string) => {
    setApiLoading(true);
    await api
      .delete(`/threads/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setApiLoading(false);
        getAllThreads();
      })
      .catch((err) => {
        setApiLoading(false);
        toast.error(err.response.data.message);
        console.log(err.response.data.message);
      });
  };
  return (
    <SalesCopilotContext.Provider
      value={{
        allAssistants,
        setAllAssistants,
        getAllAssistants,
        deleteAssistantFile,
        deleteAssistant,
        updateAssistant,
        createAssistant,
        uploadFileAssistants,
        createThread,
        getAllThreads,
        deleteThread,
        allThreads,
        addMessagesToThread,
        loadingAI,
      }}
    >
      {children}
    </SalesCopilotContext.Provider>
  );
};

export const useSalesCopilot = () => useContext(SalesCopilotContext);
