import { Button, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ICandidature } from "../../providers/candidatures/candidatures.interface";
import { useProductCommissions } from "../../providers/productCommissions";
import { IProductComission } from "../../providers/productCommissions/productCommissions.interface";
import { useRecruitment } from "../../providers/recruitments";
import { IRecruitment } from "../../providers/recruitments/recruitments.interface";
import { useSeller } from "../../providers/users";
import Loading from "../Loading";
import { Container } from "./styles";
import PageHeader from "../pageHeader";
import { IoCreateOutline } from "react-icons/io5";
import { useProduct } from "../../providers/products";
import { type } from "os";

const CandidatureApproveForm = () => {
  const { apiLoading } = useSeller();
  const { adminUpdateCandidature, allRecruitments } = useRecruitment();
  const navigate = useNavigate();
  // eslint-disable-next-line
  const [nothing, setNothing] = useState(false);
  const [candidature, setCandidature] = useState<ICandidature | null>(null);
  const { t } = useTranslation();
  const { candidature_id, path } = useParams();
  const { allProducts, getAllProducts } = useProduct();
  const {
    allProductCommissions,
    getAllProductCommissions,
    editProductCommissions,
  } = useProductCommissions();
  useEffect(() => {
    if (allRecruitments && allRecruitments.length !== 0) {
      const recruitmentFromId = allRecruitments.find((e: IRecruitment) =>
        e.candidatures.find((j: ICandidature) => j.id === candidature_id)
      );

      if (recruitmentFromId) {
        setCandidature(
          recruitmentFromId.candidatures.find(
            (e: ICandidature) => e.id === candidature_id
          )
        );
      } else {
        navigate("/" + path + "/recruitments");
      }
    }
  }, [allRecruitments, candidature_id, navigate, path]);
  useEffect(() => {
    if (!allProducts) {
      getAllProducts();
    }
    if (allProducts) {
      for (let i = 0; i < allProductCommissions.length; i++) {
        allProductCommissions[i].product = allProducts.find(
          (e) => e.id === allProductCommissions[i].productId
        );
      }
    }
  }, [allProducts]);
  useEffect(() => {
    if (candidature) {
      getAllProductCommissions(candidature.user.id);
    }
    // eslint-disable-next-line
  }, [candidature]);

  const onSubmit = async (data: any) => {
    data.productCommissions = [];
    allProductCommissions.forEach((e: IProductComission) =>
      data.productCommissions.push(e.id)
    );
    editProductCommissions(data, "recruitments");
    adminUpdateCandidature(candidature_id, setNothing, {
      recruitment_phase: "approved",
    });
  };

  const { register, handleSubmit, control } = useForm({
    // resolver: yupResolver(formSchema),
  });
  useFieldArray({
    control,
    name: "percentages",
  });

  return (
    <Container>
      <PageHeader
        title={t("approve_user")}
        description={t("define_commissions")}
        icon={<IoCreateOutline />}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        {allProductCommissions &&
          allProducts &&
          allProductCommissions.map((e: IProductComission, index: number) => {
            return (
              <TextField
                id={"standard-description/" + e.id}
                key={e.id}
                type="number"
                InputProps={{ inputProps: { min: 1, max: 100 } }}
                label={`${e.product?.name} (${e.percentage}%)`}
                variant="standard"
                required
                {...register(`percentages.${index}`, {
                  valueAsNumber: true,
                })}
              />
            );
          })}

        {apiLoading ? (
          <Loading />
        ) : (
          <div>
            <Button type="submit" variant="contained">
              {t("approve")}
            </Button>
            <Button
              sx={{ ml: 1 }}
              onClick={() => navigate(`/${path}/recruitments`)}
              variant="outlined"
            >
              {t("cancel")}
            </Button>
          </div>
        )}
      </form>
    </Container>
  );
};

export default CandidatureApproveForm;
