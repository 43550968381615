import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AiOutlineLink } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { usePlaylistTraining } from "../../providers/playlistsTrainings";
import { useSeller } from "../../providers/users";
import Loading from "../Loading";
import { Container } from "./styles";
import { IPlaylist } from "../../providers/playlistsTrainings/playlistAndTrainings.interface";
import PageHeader from "../pageHeader";
import { IoCreateOutline } from "react-icons/io5";
const TrainingCreationForm = () => {
  const { apiLoading } = useSeller();
  const navigate = useNavigate();
  const [language, setLanguage] = useState("english");
  const [file, setFile] = useState<File | null>(null);
  const { path } = useParams();
  const { allPlaylists, createTraining } = usePlaylistTraining();
  const { t } = useTranslation();

  const handleChange = (event: any) => {
    setLanguage(event.target.value);
    setPlaylists([]);
  };
  const [playlists, setPlaylists] = useState([]);

  type FormValues = yup.InferType<typeof formSchema>;

  const onSubmit = async (data: FormValues) => {
    setFile(null);
    await createTraining(data);
  };
  const handleChangePlaylists = (event: any) => {
    const {
      target: { value },
    } = event;

    setPlaylists(typeof value === "string" ? value.split(",") : value);
  };
  const handleFileUpload = (e: any) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    setFile(file);
  };
  const formSchema = yup
    .object()
    .shape({
      name: yup.string().required(t("name_required")).max(128, t("name_max")),
      description: yup
        .string()
        .required(t("description_required"))
        .max(128, t("description_max")),
      video_url: yup
        .string()
        .required(t("video_url_required"))
        .url(t("video_url_type")),
      fileInput: yup
        .mixed()
        .required(t("file_required"))
        .test("fileSize", t("file_size"), (value) => {
          return value && value[0].size <= 1000 * 1024 * 1024;
        })
        .test(
          "type",
          t("file_accept", { file_types: ".jpeg, .jpg, .gif, .png" }),
          (value) => {
            return (
              value &&
              (value[0].type === "image/jpg" ||
                value[0].type === "image/jpeg" ||
                value[0].type === "image/gif" ||
                value[0].type === "image/png")
            );
          }
        ),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  return (
    <Container>
      <PageHeader
        title={t("new_training")}
        description={`${t("fill_create")} ${t("training")}`}
        icon={<IoCreateOutline />}
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          error={errors.name === undefined ? false : true}
          helperText={errors.name?.message?.toString()}
          id="standard-name"
          label={t("name")}
          variant="standard"
          {...register("name")}
        />
        <TextField
          id="standard-description"
          error={errors.description === undefined ? false : true}
          helperText={errors.description?.message?.toString()}
          label={t("description")}
          variant="standard"
          {...register("description")}
        />
        <TextField
          id="standard-video_url"
          error={errors.video_url === undefined ? false : true}
          helperText={errors.video_url?.message?.toString()}
          label={t("video_url")}
          variant="standard"
          {...register("video_url")}
        />
        <Button
          component="label"
          variant="outlined"
          startIcon={<AiOutlineLink />}
        >
          Upload Thumbnail
          <input
            {...register("fileInput")}
            className="inputNone"
            type="file"
            accept=".png, .jpg, .jpeg"
            required
            onChange={handleFileUpload}
          />
        </Button>
        <p className="errosForm">
          {errors.fileInput && errors.fileInput.message?.toString()}
        </p>
        <Box>{file?.name}</Box>

        <FormControl>
          <InputLabel id="demo-simple-select-label">{t("language")}</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={language}
            label={t("language")}
            {...register("language")}
            onChange={handleChange}
          >
            <MenuItem value={"english"}>{t("english")}</MenuItem>
            <MenuItem value={"portuguese"}>{t("portuguese")}</MenuItem>
          </Select>
        </FormControl>

        <FormControl>
          <InputLabel id="demo-simple-select-playlists">
            {t("playlists")}
          </InputLabel>
          <Select
            labelId="demo-simple-select-playlists"
            id="demo-simple-select-playlists"
            multiple
            value={playlists}
            required
            label={t("playlists")}
            {...register("playlists")}
            onChange={handleChangePlaylists}
          >
            {allPlaylists &&
              allPlaylists
                .filter((e: IPlaylist) => e.language === language)
                .map((e: IPlaylist) => (
                  <MenuItem key={e.id} value={e.id}>
                    {e.name}
                  </MenuItem>
                ))}
          </Select>
        </FormControl>
        {apiLoading ? (
          <Loading />
        ) : allPlaylists &&
          allPlaylists.filter((e: IPlaylist) => e.language === language)
            .length === 0 ? (
          <div>
            <p className="emptyPlaylistMessage">
              {t("empty_playlist_message")}
            </p>
            <Button
              sx={{ ml: 1 }}
              onClick={() => navigate("/" + path + "/trainings")}
              variant="outlined"
            >
              {t("back")}
            </Button>
          </div>
        ) : (
          <div>
            <Button type="submit" variant="contained">
              {t("create")}
            </Button>
            <Button
              sx={{ ml: 1 }}
              onClick={() => navigate("/" + path + "/trainings")}
              variant="outlined"
            >
              {t("cancel")}
            </Button>
          </div>
        )}
      </form>
    </Container>
  );
};

export default TrainingCreationForm;
