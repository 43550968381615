import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { IInvoice } from "../../providers/invoices/invoices.interface";
import { InvoiceList } from "../InvoicesList";
import { Container } from "./styles";
import { MdOutlineReceiptLong } from "react-icons/md";
import PageHeader from "../pageHeader";
import { useTranslation } from "react-i18next";

interface IProps {
  invoices: IInvoice[];
  isAdminView: boolean;
}

export const InvoiceContainer = ({ invoices, isAdminView }: IProps) => {
  const filteredInvoices = merge(invoices);
  const { t } = useTranslation();
  return (
    <Container>
      <PageHeader
        title={t("invoices")}
        description={t("invoices")}
        icon={<MdOutlineReceiptLong />}
        buttonContent={t("create")}
        buttonPath="invoices/create"
        showButton={!isAdminView}
      />
      {Object.keys(filteredInvoices).length === 0 ? (
        <h4>{t("no_invoices_found")}</h4>
      ) : (
        Object.keys(filteredInvoices).map((keyName: any, i) => (
          <Accordion
            sx={{ mt: 3 }}
            key={i}
            defaultExpanded={
              keyName.toString() === new Date().getFullYear().toString()
            }
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>{keyName}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {" "}
              <InvoiceList
                invoices={filteredInvoices[keyName]}
                isAdminView={isAdminView}
              />
            </AccordionDetails>
          </Accordion>
        ))
      )}
    </Container>
  );
};

const merge = (arr: { year: number; [key: string]: any }[]) => {
  const obj: { [year: number]: any[] } = {};
  arr.forEach(({ year, ...rest }) => (obj[year] ??= []).push(rest));
  return obj;
};
