import styled from "styled-components";

export const Container = styled.div`
  border-bottom: 1px solid lightgrey;

  .membersList {
    margin-top: 15px;
    width: 100%;

    display: flex;
    flex-direction: column;
    padding: 5px;
    gap: 15px;
  }
  .membersNone {
    text-align: center;
  }
  .addUser {
    display: flex;
    justify-content: flex-end;
    padding: 5px 0;
  }
`;
