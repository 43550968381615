import React, { useState, useMemo } from "react";
import {
  Button,
  Menu,
  MenuItem,
  Chip,
  Box,
  TextField,
  TableCell,
  TableRow,
} from "@mui/material";
import {
  DataGrid,
  GridCellParams,
  GridColumnMenuContainer,
  GridColumnMenuProps,
  GridPagination,
  GridRowId,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { MdFilterList } from "react-icons/md";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";
import { useTranslation } from "react-i18next";
import { ISale } from "../../providers/sales/sales.interface";
import { ExportButton } from "../ExcelExport/ExportButton";
import ModalListSalesFiles from "../ModalListSalesFiles/index";
import ModalUpdateSaleStatus from "../ModalUpdateSaleStatus";
import { Container } from "./styles";
import ModalUpdateNote from "../ModalAddNote";
import { useSale } from "../../providers/sales";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../Loading";
import { useSeller } from "../../providers/users";

interface IProps {
  filteredSales: ISale[];
  showUserName: string;
  isAdminView: boolean;
}

export const AdminSalesTable = ({
  filteredSales,
  showUserName = "",
  isAdminView = true,
}: IProps) => {
  const { t } = useTranslation();
  const { apiLoading } = useSeller();
  const [modalUpdateSaleStatus, setModalUpdateSaleStatus] = useState(false);
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowId[]>([]);
  const [modalListSalesFiles, setModalListSalesFiles] = useState(false);
  const [defaultNote, setDefaultNote] = useState("");
  const [modalAddNote, setModalAddNote] = useState(false);
  const [sale, setSale] = useState<ISale>(filteredSales[0]);
  const [saleId, setSaleId] = useState<string>("");
  const { updateSaleNote } = useSale();
  const navigate = useNavigate();
  const { path } = useParams();

  // Custom filter states
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedField, setSelectedField] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [activeFilters, setActiveFilters] = useState<
    { field: string; value: string }[]
  >([]);

  const handleFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const handleFilterFieldSelect = (field: string) => {
    setSelectedField(field);
    handleFilterClose();
  };

  const handleAddFilter = () => {
    if (selectedField && filterValue) {
      setActiveFilters([
        ...activeFilters,
        { field: selectedField, value: filterValue },
      ]);
      setSelectedField("");
      setFilterValue("");
    }
  };

  const handleRemoveFilter = (index: number) => {
    const newFilters = activeFilters.filter((_, i) => i !== index);
    setActiveFilters(newFilters);
  };

  const onClickList = (params: GridCellParams) => {
    setSale(params.row);
    setModalListSalesFiles(true);
  };

  const rows = useMemo(() => {
    return filteredSales
      ? filteredSales.map((row: ISale, index: number) => {
          const saleDate = row.createdAt.split("T")[0];
          const engGuaranteeDate = row.end_guarantee.split("T")[0];
          return {
            id: row.id,
            sale_id: row.id,
            email: `${row.customer_email} `,
            name: `${row.customer_first_name} ${row.customer_last_name} `,
            seller: row.user
              ? `${row.user.first_name} ${row.user.last_name}`
              : showUserName,
            product: row.product_name,
            currency: row.currency,
            value: new Intl.NumberFormat(
              row.currency === "BRL" ? "pt-BR" : "en-US",
              {
                style: "currency",
                currency: row.currency,
              }
            ).format(Number(row.order_amount)),
            commission: new Intl.NumberFormat(
              row.currency === "BRL" ? "pt-BR" : "en-US",
              {
                style: "currency",
                currency: row.currency,
              }
            ).format(Number(row.saleCommission.value_to_receive)),
            percentage: row.saleCommission.percentage,
            status: t(`${row.saleCommission.status}`),
            date_sale: t("date_format", {
              month: saleDate.split("-")[1],
              day: saleDate.split("-")[2],
              year: saleDate.split("-")[0],
            }),
            end_guarantee: t("date_format", {
              month: engGuaranteeDate.split("-")[1],
              day: engGuaranteeDate.split("-")[2],
              year: engGuaranteeDate.split("-")[0],
            }),
            transaction_id: row.transaction_id,
            subscription_id: row.subscription_id,
            order_id: row.order_id,
            charge_id: row.charge_id,
            attachments: row.attachments,
            note: row.note,
          };
        })
      : [];
  }, [filteredSales, showUserName, t]);

  const columns = [
    { field: "name", headerName: t("name"), width: 130 },
    { field: "email", headerName: t("email"), width: 230 },
    { field: "seller", headerName: t("seller"), width: 130 },
    { field: "product", headerName: t("product"), width: 200 },
    { field: "currency", headerName: t("currency"), width: 70 },
    { field: "value", headerName: t("value"), width: 130 },
    { field: "commission", headerName: t("commission"), width: 90 },
    { field: "percentage", headerName: t("percentage"), width: 90 },
    { field: "status", headerName: t("status"), width: 200 },
    { field: "date_sale", headerName: t("date_sale"), width: 130 },
    { field: "end_guarantee", headerName: t("end_guarantee"), width: 130 },
    {
      field: "attachments",
      headerName: t("files"),
      width: 130,
      renderCell: (params: GridCellParams) =>
        params.row.attachments.length > 0 ? (
          <p className="listFiles" onClick={() => onClickList(params)}>
            Listar arquivos
          </p>
        ) : (
          t("none")
        ),
    },
    { field: "transaction_id", headerName: t("transaction_id"), width: 230 },
    { field: "subscription_id", headerName: t("subscription_id"), width: 130 },
    { field: "order_id", headerName: t("order_id"), width: 130 },
    { field: "charge_id", headerName: t("charge_id"), width: 130 },
    {
      field: "update",
      headerName: t("update"),
      hideable: false,
      width: 130,
      renderCell: (params: GridCellParams) => (
        <Button
          variant="contained"
          onClick={() =>
            navigate(`/${path}/allsales/${params.row.sale_id}/update`)
          }
        >
          {t("update")}
        </Button>
      ),
    },
    {
      field: "note",
      headerName: t("note"),
      width: 400,
      renderCell: (params: GridCellParams) => (
        <span
          title={params.row.note}
          onClick={() => {
            setSaleId(params.row.sale_id);
            setModalAddNote(true);
            setDefaultNote(params.row.note || "");
          }}
        >
          {params.row.note ? (
            <p className="saleChange">{params.row.note}</p>
          ) : (
            <Button variant="outlined">{t("add")}</Button>
          )}
        </span>
      ),
    },
  ];

  const filterableFields = [
    "name",
    "email",
    "seller",
    "product",
    "currency",
    "status",
    "transaction_id",
    "subscription_id",
    "order_id",
    "charge_id",
  ];

  const filteredRows = useMemo(() => {
    return rows.filter((row) =>
      activeFilters.every((filter) =>
        String(row[filter.field])
          .toLowerCase()
          .includes(filter.value.toLowerCase())
      )
    );
  }, [rows, activeFilters]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer sx={{ p: 1 }}>
        <ExportButton />
        <GridToolbarQuickFilter sx={{ ml: "auto" }} />
      </GridToolbarContainer>
    );
  }

  const CustomFooter = (props: GridColumnMenuProps) => {
    return (
      <GridColumnMenuContainer {...props}>
        <TableRow>
          <TableCell>{t("commissions")}</TableCell>
          <TableCell align="left">USD</TableCell>
          <TableCell align="left">BRL</TableCell>
        </TableRow>
        <TableRow>
          <TableCell />
          <TableCell align="left">
            {new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(
              filteredSales
                .filter((e) => e.currency === "USD")
                .reduce(
                  (acc, sale) =>
                    acc + Number(sale.saleCommission.value_to_receive),
                  0
                )
            )}
          </TableCell>
          <TableCell align="left">
            {new Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(
              filteredSales
                .filter((e) => e.currency === "BRL")
                .reduce(
                  (acc, sale) =>
                    acc + Number(sale.saleCommission.value_to_receive),
                  0
                )
            )}
          </TableCell>
        </TableRow>
        <GridPagination />
      </GridColumnMenuContainer>
    );
  };

  return (
    <>
      <Container>
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <Button
            variant="outlined"
            startIcon={<MdFilterList />}
            onClick={handleFilterClick}
          >
            Add Filter
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleFilterClose}
          >
            {filterableFields.map((field) => (
              <MenuItem
                key={field}
                onClick={() => handleFilterFieldSelect(field)}
              >
                {field}
              </MenuItem>
            ))}
          </Menu>
        </Box>
        {selectedField && (
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <TextField
              label={`Filter by ${selectedField}`}
              value={filterValue}
              onChange={(e) => setFilterValue(e.target.value)}
              sx={{ mr: 2 }}
            />
            <Button variant="contained" onClick={handleAddFilter}>
              Add
            </Button>
          </Box>
        )}
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mb: 2 }}>
          {activeFilters.map((filter, index) => (
            <Chip
              key={index}
              label={`${filter.field} contains: ${filter.value}`}
              onDelete={() => handleRemoveFilter(index)}
            />
          ))}
        </Box>

        <DataGrid
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          rowSelectionModel={rowSelectionModel}
          rows={filteredRows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 50 },
            },
          }}
          pageSizeOptions={[5, 15, 30, 50, 100]}
          slots={{ toolbar: CustomToolbar, footer: CustomFooter }}
          columnVisibilityModel={{
            update: isAdminView,
          }}
          checkboxSelection
          disableRowSelectionOnClick
          sx={{
            height: 900,
            maxHeight: "80vh",
            "& .MuiDataGrid-row": {
              maxHeight: "unset !important",
            },
            "& .MuiDataGrid-cell": {
              maxHeight: "unset !important",
              overflow: "auto",

              whiteSpace: "normal",
              lineHeight: "1.5em",
              paddingTop: "8px",
              paddingBottom: "8px",
            },
          }}
        />

        {rowSelectionModel.length > 0 && isAdminView ? (
          <Button
            sx={{ mt: 2 }}
            variant="contained"
            onClick={() => setModalUpdateSaleStatus(true)}
          >
            {t("update")} status
          </Button>
        ) : (
          <></>
        )}
      </Container>
      {modalUpdateSaleStatus && (
        <ModalUpdateSaleStatus
          setModalUpdateSaleStatus={setModalUpdateSaleStatus}
          arraySalesId={rowSelectionModel}
        />
      )}
      {modalListSalesFiles && (
        <ModalListSalesFiles
          sale={sale}
          setModalListSalesFiles={setModalListSalesFiles}
        />
      )}
      {modalAddNote && saleId && (
        <ModalUpdateNote
          setModalChangeNote={setModalAddNote}
          item_id={saleId}
          defaultNote={defaultNote}
          isAdminView={true}
          functionToUpdate={updateSaleNote}
        />
      )}
    </>
  );
};
