import { styled } from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 15px 0px;

  .headerTitle {
    display: flex;
    align-items: center;
  }
  .headerTitle > svg {
    margin-right: 5px;
  }
  .headerDescription {
    margin-left: 30px;
    color: ${({ theme }) => theme.colors.black};
  }
  .pageHeaderButton {
    align-self: start;
  }

  @media (min-width: 800px) {
    .headerDescription {
      margin-left: 39px;
    }
  }
`;
