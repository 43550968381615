import { Box, Modal } from "@mui/material";
import { useState } from "react";
import { AiOutlineEdit, AiOutlineLink } from "react-icons/ai";
import { FaHourglassHalf } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { usePaymentMethod } from "../../providers/paymentMethods";
import { IPaymentMethod } from "../../providers/paymentMethods/paymentMethods.interface";
import { useSeller } from "../../providers/users";
import SellerLink from "../SellerLink";
import { Container } from "./styles";
interface IProps {
  productId: string;
  paymentMethod: IPaymentMethod;
}

const PaymentMethodItem = ({ productId, paymentMethod }: IProps) => {
  const { verifyRolePermission, roleAdmin, apiLoading } = useSeller();
  const navigate = useNavigate();
  const { getLinkPaymentMethod, loadingLink } = usePaymentMethod();
  const [open, setOpen] = useState(false);
  const [linkModal, setLinkModal] = useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { path } = useParams();

  const getSellerLink = async () => {
    const link = await getLinkPaymentMethod(paymentMethod.id);
    setLinkModal(link);
    if (link) handleOpen();
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "95vw",
    height: "150px",
    maxWidth: "400px",
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
    "z-index": "999999999",
    padding: "15px",
  };
  return (
    <>
      <Container apiLoading={loadingLink ? 1 : 0}>
        {verifyRolePermission(roleAdmin) ? (
          <div
            onClick={() =>
              navigate(
                `/${path}/products/${productId}/paymentmethods/${paymentMethod.id}`
              )
            }
            className="updatePaymentMethodIcon"
          >
            <span>
              <AiOutlineEdit />
            </span>
          </div>
        ) : (
          <></>
        )}
        <div className="paymentMethodContainer">
          <p className="paymentMethodName">
            {" "}
            {paymentMethod.name.length > 32
              ? paymentMethod.name.slice(0, 32 - 1) + "..."
              : paymentMethod.name}
          </p>
          <p className="paymentMethodDescription">
            {paymentMethod.description.length > 50
              ? paymentMethod.description.slice(0, 60 - 1) + "..."
              : paymentMethod.description}
          </p>
          <p className="paymentMethodPrice">{paymentMethod.display_price}</p>
        </div>
        <button
          onClick={getSellerLink}
          disabled={loadingLink}
          className="getLinkPaymentMethodIcon"
        >
          <span>{loadingLink ? <FaHourglassHalf /> : <AiOutlineLink />}</span>
        </button>
      </Container>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <SellerLink link={linkModal} />
        </Box>
      </Modal>
    </>
  );
};

export default PaymentMethodItem;
