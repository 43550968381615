import CandidatureApproveForm from "../../components/CandidatureApproveForm";

const ApproveCandidatePage = () => {
  return (
    <>
      <CandidatureApproveForm />
    </>
  );
};

export default ApproveCandidatePage;
