import { useEffect, useState } from "react";
import { BsGraphUp, BsPeople } from "react-icons/bs";
import { IoSchoolOutline, IoSettingsOutline } from "react-icons/io5";
import { MdOutlineAttachMoney } from "react-icons/md";
import { RiLogoutBoxLine, RiTeamLine } from "react-icons/ri";
import { TbReportMoney } from "react-icons/tb";
import { RxAvatar, RxHome } from "react-icons/rx";
import { MdOutlineReceiptLong, MdOutlinePersonSearch } from "react-icons/md";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import logo from "../../assets/color-spp.png";
import { useSeller } from "../../providers/users";
import Stars from "../Stars";
import { Container } from "./styles";
import { LANGUAGES } from "../../constants/index";
import { useTranslation } from "react-i18next";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { BiDownArrow, BiRightArrow } from "react-icons/bi";
import { FaRobot } from "react-icons/fa";

const NavigationBar = () => {
  const { seller, Logout, verifyRolePermission, roleAdmin, roleFinance } =
    useSeller();
  const navigate = useNavigate();
  const location = useLocation();
  const [url, setUrl] = useState<string | null>(null);
  const { path } = useParams();
  const { i18n, t } = useTranslation();
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDropdownFinance, setShowDropdownFinance] = useState(false);
  const onChangeLang = (e: any) => {
    const lang_code = e.target.value;
    i18n.changeLanguage(lang_code);
  };

  useEffect(() => {
    setUrl(location.pathname);
  }, [location]);
  return (
    <Container>
      <div className="sideBarFix">
        <img src={logo} alt="SPP Logo" className="navBarLogo" />
        <section className="navBarUser">
          <p>
            {seller.first_name} {seller.last_name}
          </p>
          <Stars numberOfSales={seller.sales_count} />
        </section>
        <section className="navBarLinksContainer">
          <section className="navBarLinks">
            <p
              className={url && url.includes("/dashboard") ? "linkActive" : ""}
              onClick={() => {
                navigate("/" + path + "/dashboard");
              }}
            >
              <RxHome /> {t("dashboard")}
            </p>
            <p
              className={url && url.includes("/products") ? "linkActive" : ""}
              onClick={() => navigate("/" + path + "/products")}
            >
              <MdOutlineAttachMoney /> {t("products")}
            </p>
            <p
              className={url && url.includes("/assistants") ? "linkActive" : ""}
              onClick={() => navigate("/" + path + "/assistants")}
            >
              <FaRobot /> {t("sales_copilot")}
            </p>
            <p
              className={url && url.endsWith("/sales") ? "linkActive" : ""}
              onClick={() => navigate("/" + path + "/sales")}
            >
              <BsGraphUp /> {t("sales")}
            </p>
            <p
              className={url && url.includes("/trainings") ? "linkActive" : ""}
              onClick={() => navigate("/" + path + "/trainings")}
            >
              <IoSchoolOutline /> {t("trainings")}
            </p>
            <p
              className={url && url.includes("/profile") ? "linkActive" : ""}
              onClick={() => navigate("/" + path + "/profile")}
            >
              <RxAvatar /> {t("profile")}
            </p>
            <p
              className={url && url.endsWith("/invoices") ? "linkActive" : ""}
              onClick={() => navigate("/" + path + "/invoices")}
            >
              <MdOutlineReceiptLong /> {t("invoices")}
            </p>
            {seller && verifyRolePermission(roleAdmin) && (
              <div
                className={"dropdownLink"}
                onClick={() =>
                  showDropdown === true
                    ? setShowDropdown(false)
                    : setShowDropdown(true)
                }
              >
                <p>
                  {showDropdown === true ? <BiDownArrow /> : <BiRightArrow />}{" "}
                  {t("admin")}
                </p>
                {showDropdown && (
                  <div
                    id={
                      showDropdown === true ? "animationOpen" : "animationClose"
                    }
                  >
                    <p
                      className={
                        url && url.includes("/recruitments") ? "linkActive" : ""
                      }
                      onClick={() => navigate("/" + path + "/recruitments")}
                    >
                      <MdOutlinePersonSearch /> {t("recruitments")}
                    </p>
                    <p
                      className={
                        url && url.includes("/salesteams") ? "linkActive" : ""
                      }
                      onClick={() => navigate("/" + path + "/salesteams")}
                    >
                      <RiTeamLine /> {t("sales_teams")}
                    </p>
                    <p
                      className={
                        url && url.includes("/settings") ? "linkActive" : ""
                      }
                      onClick={() =>
                        navigate("/" + path + "/settings", { replace: true })
                      }
                    >
                      <IoSettingsOutline /> {t("settings")}
                    </p>
                  </div>
                )}
              </div>
            )}
            {seller && verifyRolePermission(roleFinance) && (
              <div className={"dropdownLink"}>
                <p
                  onClick={() =>
                    showDropdownFinance === true
                      ? setShowDropdownFinance(false)
                      : setShowDropdownFinance(true)
                  }
                >
                  {showDropdownFinance === true ? (
                    <BiDownArrow />
                  ) : (
                    <BiRightArrow />
                  )}{" "}
                  {t("financial")}
                </p>
                {showDropdownFinance && (
                  <div
                    id={
                      showDropdownFinance === true
                        ? "animationOpen"
                        : "animationClose"
                    }
                  >
                    <p
                      className={
                        url && url.includes("/users") ? "linkActive" : ""
                      }
                      onClick={() => navigate("/" + path + "/users")}
                    >
                      <BsPeople /> {t("users")}
                    </p>
                    <p
                      className={
                        url && url.includes("/allsales") ? "linkActive" : ""
                      }
                      onClick={() => navigate("/" + path + "/allsales")}
                    >
                      <TbReportMoney /> {t("all_sales")}
                    </p>
                  </div>
                )}
              </div>
            )}

            <p onClick={() => Logout()}>
              <RiLogoutBoxLine /> {t("logout")}
            </p>
            <FormControl className="lastItem">
              <InputLabel
                sx={{ ml: 2, mt: 2, color: "#fff", border: "#fff" }}
                id="demo-simple-select-label"
              >
                {t("language")}
              </InputLabel>
              <Select
                sx={{ ml: 2, mt: 2, color: "#fff", border: "#fff" }}
                labelId="demo-simple-select-label-status"
                id="demo-simple-select"
                value={i18n?.language}
                onChange={onChangeLang}
                label={t("language")}
                size="small"
              >
                {LANGUAGES.map(({ code, label }) => (
                  <MenuItem key={code} value={code}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </section>
        </section>
      </div>
    </Container>
  );
};

export default NavigationBar;
