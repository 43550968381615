import { useEffect, useState } from "react";
import { useSeller } from "../../providers/users";
import { Container } from "./styles";
import Loading from "../Loading";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  FormHelperText,
} from "@mui/material";
import { useProduct } from "../../providers/products";
import { useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { usePaymentMethod } from "../../providers/paymentMethods";
import { useTranslation } from "react-i18next";
import ModalConfirmDeletion from "../ModalConfirmDeletion/index";
import { IPaymentMethod } from "../../providers/paymentMethods/paymentMethods.interface";
import { IProduct } from "../../providers/products/products.interface";
import PageHeader from "../pageHeader";
import { IoCreateOutline } from "react-icons/io5";
import api from "../../services";

const PaymentMethodUpdateForm = () => {
  const { apiLoading, token } = useSeller();
  const { allProducts } = useProduct();
  const navigate = useNavigate();
  const { editPaymentMethod, deletePaymentMethod } = usePaymentMethod();
  const [paymentMethod, setPaymentMethod] = useState<IPaymentMethod | null>(
    null
  );
  const { product_id, payment_method_id, path } = useParams();
  const [cloaking, setCloaking] = useState(false);
  const { t } = useTranslation();
  const [modalConfirmDeletion, setModalConfirmDeletion] = useState(false);

  useEffect(() => {
    if (allProducts && allProducts.length !== 0) {
      const productFromId = allProducts.find(
        (e: IProduct) => e.id === product_id
      );
      if (productFromId) {
        const paymentMethod = productFromId.payment_methods.find(
          (e: IPaymentMethod) => e.id === payment_method_id
        );
        if (paymentMethod) {
          setPaymentMethod(paymentMethod);
        } else {
          navigate("/" + path + "/products");
        }
      } else {
        navigate("/" + path + "/products");
      }
    }
  }, [allProducts, navigate, path, payment_method_id, product_id]);

  function removeEmptyFields(data: any) {
    Object.keys(data).forEach((key) => {
      if (data[key] === "" || data[key] == null) {
        delete data[key];
      }
    });
  }
  type FormValues = yup.InferType<typeof formSchema>;
  const onSubmit = async (data: FormValues) => {
    removeEmptyFields(data);
    await editPaymentMethod(product_id, payment_method_id, data);
  };

  const handleChangeCloaking = (event: any) => {
    setCloaking(event.target.value);
  };

  const formSchema = yup
    .object()
    .shape({
      name: yup.string().max(128, t("name_max")),
      description: yup.string().max(128, t("description_max")),
      checkout_platform: yup.string().max(128, t("checkout_max")),
      checkout_url: yup.string().url(t("checkout_url_valid")),
      checkout_product_id: yup.string().max(128, t("checkout_product_id_max")),
      checkout_link_cloaking: yup.boolean(),
      seller_code_parameteer: yup
        .string()
        .max(128, t("seller_code_parameteer_max")),
      money_back_guarantee: yup
        .number()
        .transform((value, originalValue) => {
          return originalValue === "" ? undefined : value;
        })
        .typeError(t("money_back_guarantee_typeerror"))
        .min(1, t("money_back_guarantee_min"))
        .max(367, t("money_back_guarantee_max")),
      display_price: yup
        .string()

        .max(128, t("display_price_max")),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  return (
    <>
      <Container>
        <PageHeader
          title={t("update_payment_method")}
          description={`${t("fill_update")} ${t("payment_method")}`}
          icon={<IoCreateOutline />}
        />
        {paymentMethod && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              error={errors.name === undefined ? false : true}
              helperText={errors.name?.message?.toString()}
              id="standard-name"
              label={t("name")}
              defaultValue={paymentMethod.name}
              variant="standard"
              {...register("name")}
            />
            <TextField
              id="standard-description"
              error={errors.description === undefined ? false : true}
              helperText={errors.description?.message?.toString()}
              label={t("description")}
              variant="standard"
              defaultValue={paymentMethod?.description}
              {...register("description")}
            />
            <TextField
              id="standard-checkout_platform"
              error={errors.checkout_platform === undefined ? false : true}
              helperText={errors.checkout_platform?.message?.toString()}
              label={t("checkout")}
              defaultValue={paymentMethod.checkout_platform}
              variant="standard"
              {...register("checkout_platform")}
            />
            <TextField
              id="standard-checkout_url"
              error={errors.checkout_url === undefined ? false : true}
              helperText={errors.checkout_url?.message?.toString()}
              label={t("checkout_url")}
              defaultValue={paymentMethod.checkout_url}
              variant="standard"
              {...register("checkout_url")}
            />
            <TextField
              id="standard-seller_code_parameteer"
              error={errors.seller_code_parameteer === undefined ? false : true}
              helperText={errors.seller_code_parameteer?.message?.toString()}
              label={t("seller_code_parameteer")}
              defaultValue={paymentMethod.seller_code_parameteer}
              variant="standard"
              {...register("seller_code_parameteer")}
            />
            <TextField
              id="standard-checkout_product_id"
              error={errors.checkout_product_id === undefined ? false : true}
              helperText={errors.checkout_product_id?.message?.toString()}
              label={t("checkout_product_id")}
              defaultValue={paymentMethod.checkout_product_id}
              variant="standard"
              {...register("checkout_product_id")}
            />
            <FormControl>
              <InputLabel sx={{ mt: 1 }} id="select-label">
                {t("link_cloaking")}
              </InputLabel>
              <Select
                sx={{ mt: 1 }}
                labelId="select-label"
                id="select"
                value={cloaking}
                label={t("link_cloaking")}
                {...register("checkout_link_cloaking")}
                onChange={handleChangeCloaking}
              >
                <MenuItem value={false as any}>False</MenuItem>
                <MenuItem value={true as any}>True</MenuItem>
              </Select>
            </FormControl>
            <TextField
              id="standard-money_back_guarantee"
              error={errors.money_back_guarantee === undefined ? false : true}
              helperText={errors.money_back_guarantee?.message?.toString()}
              label={t("money_back_guarantee")}
              defaultValue={paymentMethod.money_back_guarantee}
              variant="standard"
              {...register("money_back_guarantee")}
            />
            <TextField
              id="standard-display_price"
              error={errors.display_price === undefined ? false : true}
              helperText={errors.display_price?.message?.toString()}
              defaultValue={paymentMethod.display_price}
              label={t("display_price")}
              variant="standard"
              placeholder={t("display_price_placeholder")}
              {...register("display_price")}
            />
            <FormHelperText> {t("no_fill")}</FormHelperText>
            {apiLoading ? (
              <Loading />
            ) : (
              <div>
                <Button type="submit" variant="contained">
                  {t("confirm")}
                </Button>
                <Button
                  sx={{ ml: 1 }}
                  onClick={() =>
                    navigate(`/${path}/products/${product_id}/paymentmethods`)
                  }
                  variant="outlined"
                >
                  {t("cancel")}
                </Button>
                <Button
                  sx={{ ml: 1 }}
                  onClick={() => setModalConfirmDeletion(true)}
                  variant="outlined"
                  color="error"
                >
                  {t("delete")}
                </Button>
              </div>
            )}
          </form>
        )}
      </Container>
      {modalConfirmDeletion && (
        <ModalConfirmDeletion
          setModalConfirmDeletion={setModalConfirmDeletion}
          deleteId={payment_method_id!}
          deleteFunction={deletePaymentMethod}
          parentId={product_id}
        />
      )}
    </>
  );
};

export default PaymentMethodUpdateForm;
