import { ISellersFromTenant } from "../../providers/users/user.interface";
import Stars from "../Stars";
import { Container } from "./styles";

interface IProps {
  currentItems: ISellersFromTenant[];
}

export const RankingUserList = ({ currentItems }: IProps) => {
  return (
    <Container>
      {currentItems &&
        currentItems.map((item, index: number) => (
          <div key={item.id} className="rankingSellerContainer">
            <div className="rankingSellerInfo">
              <span>{index + 1}.</span>
              <img
                src={
                  item.userProfileImage
                    ? item.userProfileImage.url
                    : "https://as2.ftcdn.net/v2/jpg/04/10/43/77/1000_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg"
                }
                alt="Imagem de Usuário"
              />
              <div className="rankingSellerNameCity">
                <p className="rankingSellerName">
                  {item.first_name + " " + item.last_name}
                </p>
                <Stars numberOfSales={item.sales_count} />
                <p className="rankingSellerCity">
                  {item.address
                    ? `${item.address.city}, ${item.address.country}`
                    : ""}
                </p>
              </div>
            </div>

            <p>{item.sales_count}</p>
          </div>
        ))}
    </Container>
  );
};
