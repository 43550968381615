import * as yup from "yup";

export const formSchema = yup
  .object()
  .shape({
    accepted_terms: yup
      .boolean()
      .required("accepted_terms is required to create a legal terms")
      .oneOf([true], "accepted_terms must be true"),

    accepted_privacy_policy: yup
      .boolean()
      .required("accepted_privacy_policy is required to create a legal terms")
      .oneOf([true], "accepted_privacy_policy must be true"),

    accepted_sales_manual: yup
      .boolean()
      .required("accepted_sales_manual is required to create a legal terms")
      .oneOf([true], "accepted_sales_manual must be true"),
  })
  .required();
