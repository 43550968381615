import SalesTeamCreationForm from "../../components/SalesTeamCreationForm";

const SalesTeamCreationPage = () => {
  return (
    <>
      <SalesTeamCreationForm />
    </>
  );
};

export default SalesTeamCreationPage;
