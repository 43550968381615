import { createContext, useContext, useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import api from "../../services";
import { IRole } from "../roles/roles.interface";
import { useSeller } from "../users";
import {
  ILog,
  IPathProps,
  IProviderProps,
  ITenant,
  ITenantByPath,
  ITenantSettings,
  IUpdateTenantSettings,
} from "./tenant.interfaces";

export const TenantContext = createContext<any | null>(null);

export const TenantProvider = ({ children }: IProviderProps) => {
  const navigate = useNavigate();
  const {
    token,
    roleAdmin,
    verifyRolePermission,
    setPageMounting,
    setApiLoading,
  } = useSeller();
  const [tenant, setTenant] = useState<ITenant | null>(null);
  const [tenantSettings, setTenantSettings] = useState<ITenantSettings | null>(
    null
  );
  const [tenantByPath, setTenantByPath] = useState<ITenantByPath | null>(null);
  const [allUsers, setAllUsers] = useState("");
  const [allLogs, setAllLogs] = useState<ILog[] | null>(null);
  const [tenantTerms, setTenantTerms] = useState<ITenantSettings | null>(null);
  const [tenantRoles, setTenantRoles] = useState<IRole[]>([]);
  const location = useLocation();
  let path = location.pathname.split("/")[1];
  const { t } = useTranslation();

  const getTenant = async () => {
    setApiLoading(true);
    await api
      .get("/tenant", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setTenant(res.data);
        if (verifyRolePermission(roleAdmin)) {
          setTenantSettings(res.data.tenantSettings);
        }
        setApiLoading(false);
      })
      .catch((err) => {
        console.log(err.response.data.message);
        setApiLoading(false);
      });
  };
  const getTenantUsers = async (mounting = true) => {
    if (mounting) {
      setPageMounting(true);
    } else {
      setApiLoading(true);
    }
    await api
      .get("/tenant/users", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setAllUsers(res.data);
        setPageMounting(false);
        setApiLoading(false);
      })
      .catch((err) => {
        console.log(err.response.data.message);
        setPageMounting(false);
        setApiLoading(false);
      });
  };
  const getTenantByPath = async (pathParam: IPathProps) => {
    if (pathParam) {
      path = pathParam.path.toLowerCase();
    }
    if (!path) {
      return navigate("/");
    }
    setApiLoading(true);

    await api
      .get(`/tenant/path/${path}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setApiLoading(false);
        window.localStorage.setItem("SPP-Session-Path", path);
        setTenantByPath(res.data);
        if (pathParam) {
          navigate(`/${pathParam.path}`);
        }
      })
      .catch((err) => {
        setApiLoading(false);
        if (pathParam) {
          toast.error(t("tenant_path_not_found"));
        }
        navigate("/");
      });
  };
  const getTenantTerms = async () => {
    setApiLoading(true);
    await api
      .get(`/tenant/terms`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setApiLoading(false);
        setTenantTerms(res.data);
      })
      .catch((err) => {
        setApiLoading(false);
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
      });
  };
  const getTenantRoles = () => {
    setApiLoading(true);
    api
      .get(`/roles`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setApiLoading(false);
        setTenantRoles(res.data);
      })
      .catch((err) => {
        setApiLoading(false);
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
      });
  };
  const updateSettings = async (data: IUpdateTenantSettings) => {
    setApiLoading(true);
    api
      .patch(`/settings/tenant`, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setApiLoading(false);
        getTenant();
        navigate(`/${path}/settings`);
      })
      .catch((err) => {
        setApiLoading(false);
        toast.error(err.response.data.message);
      });
  };
  const getAllLogs = async () => {
    setPageMounting(true);

    await api
      .get("/logs", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setAllLogs(res.data);
        if (!allUsers) {
          getTenantUsers();
        }
        setPageMounting(false);
      })
      .catch((err) => {
        console.log(err.response.data.message);
        setPageMounting(false);
      });
  };
  return (
    <TenantContext.Provider
      value={{
        updateSettings,
        getTenant,
        tenant,
        getTenantRoles,
        tenantRoles,
        tenantSettings,
        getTenantByPath,
        tenantByPath,
        getTenantTerms,
        tenantTerms,
        getTenantUsers,
        allUsers,
        getAllLogs,
        allLogs,
      }}
    >
      {children}
    </TenantContext.Provider>
  );
};

export const useTenant = () => useContext(TenantContext);
