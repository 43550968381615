import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@mui/material";
import { Toaster } from "react-hot-toast";
import { I18nextProvider } from "react-i18next";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GlobalStyle } from "./globalStyle";
import i18n from "./i18n";
import { AppRoutes } from "./routes";

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#5585f0",
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <I18nextProvider i18n={i18n}>
        <div className="App">
          <GlobalStyle />
          <AppRoutes />
          <Toaster />
          <ToastContainer />
        </div>
      </I18nextProvider>
    </ThemeProvider>
  );
}

export default App;
