import TrainingCreationForm from "../../components/TrainingCreationForm";
const TrainingCreation = () => {
  return (
    <>
      <TrainingCreationForm />
    </>
  );
};

export default TrainingCreation;
