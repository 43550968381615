import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  FormHelperText,
} from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { useTenant } from "../../providers/tenant";
import { useSeller } from "../../providers/users";
import Loading from "../Loading";
import { Container } from "./styles";
import PageHeader from "../pageHeader";
import { IoCreateOutline } from "react-icons/io5";

const SettingsUpdateForm = () => {
  const { apiLoading } = useSeller();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [newToken, setNewToken] = useState(false);
  const { updateSettings, tenantSettings } = useTenant();
  const [linkExpiration, setLinkExpiration] = useState(
    tenantSettings.link_expiration || false
  );
  const { path } = useParams();
  function removeEmptyFields(data: any) {
    Object.keys(data).forEach((key) => {
      if (data[key] === "" || data[key] == null) {
        delete data[key];
      }
    });
  }
  type FormValues = yup.InferType<typeof formSchema>;

  const onSubmit = async (data: FormValues) => {
    removeEmptyFields(data);
    if (data["x-spp-token"] === false) {
      delete data["x-spp-token"];
    }
    await updateSettings(data);
  };
  const handleChange = (event: any) => {
    if (event.target.value === false) {
      const input = document.getElementById(
        "standard-link_expiration_time"
      ) as any;
      input.value = "";
      reset(input.value);
    }

    setLinkExpiration(event.target.value);
  };
  const handleChangeToken = (event: any) => {
    setNewToken(event.target.value);
  };

  const formSchema = yup
    .object()
    .shape({
      link_expiration: yup.boolean(),
      "x-spp-token": yup.boolean(),
      link_expiration_time: yup
        .number()
        .typeError(t("link_expiration_time_typeerror"))
        .transform((value, originalValue) => {
          return originalValue === "" ? undefined : value;
        })
        .when("link_expiration", {
          is: (FieldA: any) => FieldA === true,
          then: yup
            .number()
            .required(t("link_expiration_time_required"))
            .transform((value, originalValue) => {
              return originalValue === "" ? undefined : value;
            })
            .typeError(t("link_expiration_time_typeerror"))
            .min(1, t("link_expiration_time_min"))
            .max(525960, t("link_expiration_time_max")),
        }),
      shorts_api_key: yup.string().max(128, t("shorts_api_key_max")),
      openai_api_key: yup.string().max(128, t("shorts_api_key_max")),
      shorts_domain: yup.string().max(128, t("shorts_domain_max")),
      terms_conditions: yup.string().url("terms_conditions must be a url"),
      terms_conditions_version: yup
        .string()
        .max(
          128,
          "terms_conditions_version length must have less than 128 characters. "
        ),
      privacy_policy: yup.string().url("privacy_policy must be a url"),
      privacy_policy_version: yup
        .string()
        .max(
          128,
          "privacy_policy_version length must have less than 128 characters. "
        ),
      sales_manual: yup.string().url("sales_manual must be a url"),
      sales_manual_version: yup
        .string()
        .max(
          128,
          "sales_manual_version length must have less than 128 characters. "
        ),
    })
    .required();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  return (
    <Container>
      <PageHeader
        title={t("update_settings")}
        description={`${t("fill_update")} ${t("settings")}`}
        icon={<IoCreateOutline />}
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl>
          <InputLabel sx={{ mt: 1 }} id="demo-simple-select-label">
            {t("link_expiration_time")}
          </InputLabel>
          <Select
            sx={{ mt: 1 }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={linkExpiration}
            label={t("link_expiration_time")}
            {...register("link_expiration")}
            onChange={handleChange}
          >
            <MenuItem value={true as any}> {t("true")}</MenuItem>
            <MenuItem value={false as any}> {t("false")}</MenuItem>
          </Select>
        </FormControl>
        <TextField
          id="standard-link_expiration_time"
          disabled={linkExpiration === false ? true : false}
          error={errors.link_expiration_time === undefined ? false : true}
          helperText={errors.link_expiration_time?.message?.toString()}
          label={t("link_expiration_time_label")}
          defaultValue={
            tenantSettings.link_expiration
              ? tenantSettings.link_expiration_time
              : ""
          }
          variant="standard"
          {...register("link_expiration_time")}
        />
        <TextField
          id="standard-shorts_api_key"
          error={errors.shorts_api_key === undefined ? false : true}
          helperText={errors.shorts_api_key?.message?.toString()}
          label="Shorts api key"
          defaultValue={
            tenantSettings.shorts_api_key ? tenantSettings.shorts_api_key : ""
          }
          variant="standard"
          {...register("shorts_api_key")}
        />
        <TextField
          id="standard-openai_api_key"
          error={errors.openai_api_key === undefined ? false : true}
          helperText={errors.openai_api_key?.message?.toString()}
          label="OpenAI api key"
          defaultValue={
            tenantSettings.openai_api_key ? tenantSettings.openai_api_key : ""
          }
          variant="standard"
          {...register("openai_api_key")}
        />
        <TextField
          id="standard-shorts_domain"
          error={errors.shorts_domain === undefined ? false : true}
          helperText={errors.shorts_domain?.message?.toString()}
          label="Shorts domain"
          defaultValue={
            tenantSettings.shorts_domain ? tenantSettings.shorts_domain : ""
          }
          variant="standard"
          {...register("shorts_domain")}
        />
        <TextField
          id="standard-terms_conditions"
          error={errors.terms_conditions === undefined ? false : true}
          helperText={errors.terms_conditions?.message?.toString()}
          label={t("terms_conditions") + " (URL)"}
          defaultValue={tenantSettings.terms_conditions}
          variant="standard"
          {...register("terms_conditions")}
        />
        <TextField
          id="standard-terms_conditions_version"
          error={errors.terms_conditions_version === undefined ? false : true}
          helperText={errors.terms_conditions_version?.message?.toString()}
          label={t("terms_conditions_version_label")}
          variant="standard"
          defaultValue={tenantSettings.terms_conditions_version}
          placeholder={`${t("example_abbreviation")} 1.1`}
          {...register("terms_conditions_version")}
        />
        <TextField
          id="standard-privacy_policy"
          error={errors.privacy_policy === undefined ? false : true}
          helperText={errors.privacy_policy?.message?.toString()}
          label={t("privacy_policy") + " (URL)"}
          defaultValue={tenantSettings.privacy_policy}
          variant="standard"
          {...register("privacy_policy")}
        />
        <TextField
          id="standard-privacy_policy_version"
          error={errors.privacy_policy_version === undefined ? false : true}
          helperText={errors.privacy_policy_version?.message?.toString()}
          label={t("privacy_policy_version_label")}
          defaultValue={tenantSettings.privacy_policy_version}
          placeholder={`${t("example_abbreviation")} 1.1`}
          variant="standard"
          {...register("privacy_policy_version")}
        />
        <TextField
          id="standard-sales_manual"
          error={errors.sales_manual === undefined ? false : true}
          helperText={errors.sales_manual?.message?.toString()}
          defaultValue={tenantSettings.sales_manual}
          label={t("sales_agent_manual") + " (URL)"}
          variant="standard"
          {...register("sales_manual")}
        />
        <TextField
          id="standard-sales_manual_version"
          error={errors.sales_manual_version === undefined ? false : true}
          helperText={errors.sales_manual_version?.message?.toString()}
          placeholder={`${t("example_abbreviation")} 1.1`}
          defaultValue={tenantSettings.sales_manual_version}
          label={t("sales_manual_version_label")}
          variant="standard"
          {...register("sales_manual_version")}
        />

        <FormControl>
          <InputLabel sx={{ mt: 1 }} id="demo-simple-select-label">
            {t("new_token")}
          </InputLabel>
          <Select
            sx={{ mt: 1 }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={newToken}
            label={t("new_token")}
            {...register("x-spp-token")}
            onChange={handleChangeToken}
          >
            <MenuItem value={true as any}>{t("true")}</MenuItem>
            <MenuItem value={false as any}>{t("false")}</MenuItem>
          </Select>
        </FormControl>
        <FormHelperText> {t("no_fill")}</FormHelperText>
        {apiLoading ? (
          <Loading />
        ) : (
          <div>
            <Button type="submit" variant="contained">
              {t("update")}
            </Button>
            <Button
              sx={{ ml: 1 }}
              onClick={() => navigate(`/${path}/settings`)}
              variant="outlined"
            >
              {t("cancel")}
            </Button>
          </div>
        )}
      </form>
    </Container>
  );
};

export default SettingsUpdateForm;
