import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, TextField } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AiOutlineLink } from "react-icons/ai";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import { useCandidature } from "../../providers/candidatures";
import { useSeller } from "../../providers/users";
import Loading from "../Loading";
import { Container } from "./styles";

interface Props {
  addressData: {
    country: string;
    zip_code: string;
    address_line: string;
    address_line_2?: string;
    city: string;
    state: string;
  } | null;

  setPageForm: React.Dispatch<React.SetStateAction<number>>;
  setValue: React.Dispatch<React.SetStateAction<number>>;
}

const FirstPhaseApplicationForm = ({
  addressData,
  setPageForm,
  setValue,
}: Props) => {
  const { apiLoading, createAddress } = useSeller();
  const [file, setFile] = useState<File | null>(null);
  const { recruitment_id } = useParams();
  const { createCandidature } = useCandidature();
  const { t } = useTranslation();

  type FormValues = yup.InferType<typeof formSchema>;

  const onSubmit = async (data: FormValues) => {
    setFile(null);
    if (addressData !== null) {
      await createAddress(addressData);
    }
    const created = await createCandidature(recruitment_id, data);
    if (created) {
      setValue(100);
      setPageForm(3);
    }
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    setFile(file);
  };
  const formSchema = yup
    .object()
    .shape({
      profissional_summary: yup
        .string()
        .required(t("profissional_summary_required"))
        .max(128, t("profissional_summary_max")),
      temperament_test: yup
        .string()
        .required(t("temperament_test_required"))
        .max(128, t("temperament_test_max")),
      fileInput: yup
        .mixed()
        .required(t("file_required"))
        .test("fileSize", t("file_size"), (value) => {
          return value && value[0].size <= 1000 * 1024 * 1024;
        })
        .test(
          "type",
          t("file_accept", { file_types: ".mp4, .mp3" }),
          (value) => {
            return value && value[0].type.includes("video/");
          }
        ),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          error={errors.profissional_summary === undefined ? false : true}
          helperText={errors?.profissional_summary?.message?.toString()}
          id="standard-profisional"
          label={t("profissional_summary")}
          variant="standard"
          multiline
          minRows={4}
          {...register("profissional_summary")}
        />
        <TextField
          id="standard-temperament_test"
          error={errors.temperament_test === undefined ? false : true}
          helperText={errors?.temperament_test?.message?.toString()}
          label={t("temperament_test")}
          variant="standard"
          {...register("temperament_test")}
        />

        <Button
          component="label"
          variant="outlined"
          startIcon={<AiOutlineLink />}
        >
          Upload Video
          <input
            {...register("fileInput")}
            className="inputNone"
            type="file"
            accept="video/mp4,video/x-m4v,video/*"
            required
            onChange={handleFileUpload}
          />
        </Button>
        <p className="errosForm">
          {errors.fileInput && errors?.fileInput?.message?.toString()}
        </p>
        <Box>{file?.name}</Box>
        <p>{t("record_video")}</p>
        {apiLoading ? (
          <Loading />
        ) : (
          <div className="buttons1Phase">
            <Button
              sx={{ ml: 1, px: 7 }}
              size="large"
              onClick={() => setPageForm(1)}
              variant="outlined"
            >
              {t("back")}
            </Button>
            <Button sx={{ ml: 1, px: 7 }} type="submit" variant="contained">
              {t("submit")}
            </Button>
          </div>
        )}
      </form>
    </Container>
  );
};

export default FirstPhaseApplicationForm;
