import styled from "styled-components";

export const Container = styled.div`
  .filledStar {
    color: ${({ theme }) => theme.colors.primary};
  }
  .unfilledStar {
    color: gray;
  }
`;
