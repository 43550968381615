import PaymentMethodsList from "../../components/PaymentMethodsList";

const PaymentMethods = () => {
  return (
    <>
      <PaymentMethodsList />
    </>
  );
};

export default PaymentMethods;
