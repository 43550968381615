import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  border-top: 1px solid lightgrey;
  flex-direction: column;
  /* max-width: 500px; */
  padding: 15px;

  a {
    color: blue;
  }
  .teamMemberContainer {
    display: flex;
    font-size: 0.75rem;
    justify-content: space-evenly;
    flex-direction: column;
    gap: 5px;
    max-width: 550px;
    /* max-height: 250px; */
  }
  .removeMemberButtons {
    display: flex;
    flex-direction: column;
    gap: 3px;
  }
  .teamMemberName {
    font-weight: bold;
    color: ${({ theme }) => theme.colors.primary};
  }

  @media (min-width: 600px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
  }
`;
