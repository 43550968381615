import { useEffect, useState } from "react";
import * as yup from "yup";
import { useSeller } from "../../providers/users";
import Loading from "../Loading";
import { Container } from "./styles";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { usePaymentMethod } from "../../providers/paymentMethods";
import { useProduct } from "../../providers/products";
import { useTranslation } from "react-i18next";
import { IProduct } from "../../providers/products/products.interface";
import PageHeader from "../pageHeader";
import { IoCreateOutline } from "react-icons/io5";
const PaymentMethodCreationForm = () => {
  const { apiLoading } = useSeller();
  const { allProducts } = useProduct();
  const { createPaymentMethod } = usePaymentMethod();
  const navigate = useNavigate();
  const { product_id, path } = useParams();
  const [cloaking, setCloaking] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (allProducts) {
      const productFromId = allProducts.find(
        (e: IProduct) => e.id === product_id
      );
      if (!productFromId) {
        navigate("/" + path + "/products");
      }
    }
  }, [allProducts, navigate, path, product_id]);

  const handleChangeCloaking = (event: any) => {
    setCloaking(event.target.value);
  };
  type FormValues = yup.InferType<typeof formSchema>;
  const onSubmit = async (data: FormValues) => {
    await createPaymentMethod(product_id, data);
  };

  const formSchema = yup
    .object()
    .shape({
      name: yup.string().required(t("name_required")).max(128, t("name_max")),
      description: yup
        .string()
        .required(t("description_required"))
        .max(128, t("description_max")),
      checkout_platform: yup
        .string()
        .required(t("checkout_required"))
        .max(128, t("checkout_max")),
      checkout_url: yup
        .string()
        .url(t("checkout_url_valid"))
        .required(t("checkout_url_required")),
      seller_code_parameteer: yup
        .string()
        .required(t("seller_code_parameteer_required"))
        .max(128, t("seller_code_parameteer_max")),
      checkout_product_id: yup
        .string()
        .required(t("checkout_product_id_required"))
        .max(128, t("checkout_product_id_max")),
      checkout_link_cloaking: yup
        .boolean()
        .required(t("checkout_link_cloaking_required")),
      money_back_guarantee: yup
        .number()
        .typeError(t("money_back_guarantee_typeerror"))
        .required(t("money_back_guarantee_required"))
        .min(1, t("money_back_guarantee_min"))
        .max(367, t("money_back_guarantee_max")),
      display_price: yup
        .string()
        .required(t("display_price_required"))
        .max(128, t("display_price_max")),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  return (
    <Container>
      <PageHeader
        title={t("new_payment_method")}
        description={`${t("fill_create")} ${t("payment_method")}`}
        icon={<IoCreateOutline />}
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          error={errors.name === undefined ? false : true}
          helperText={errors.name?.message?.toString()}
          id="standard-name"
          label={t("name")}
          variant="standard"
          {...register("name")}
        />
        <TextField
          id="standard-description"
          error={errors.description === undefined ? false : true}
          helperText={errors.description?.message?.toString()}
          label={t("description")}
          variant="standard"
          {...register("description")}
        />
        <TextField
          id="standard-checkout_platform"
          error={errors.checkout_platform === undefined ? false : true}
          helperText={errors.checkout_platform?.message?.toString()}
          label={t("checkout")}
          variant="standard"
          {...register("checkout_platform")}
        />
        <TextField
          id="standard-checkout_url"
          error={errors.checkout_url === undefined ? false : true}
          helperText={errors.checkout_url?.message?.toString()}
          label={t("checkout_url")}
          variant="standard"
          {...register("checkout_url")}
        />
        <TextField
          id="standard-seller_code_parameteer"
          error={errors.seller_code_parameteer === undefined ? false : true}
          helperText={errors.seller_code_parameteer?.message?.toString()}
          label={t("seller_code_parameteer")}
          variant="standard"
          {...register("seller_code_parameteer")}
        />
        <TextField
          id="standard-checkout_product_id"
          error={errors.checkout_product_id === undefined ? false : true}
          helperText={errors.checkout_product_id?.message?.toString()}
          label={t("checkout_product_id")}
          variant="standard"
          {...register("checkout_product_id")}
        />
        <FormControl>
          <InputLabel sx={{ mt: 1 }} id="select-label">
            {t("link_cloaking")}
          </InputLabel>
          <Select
            sx={{ mt: 1 }}
            labelId="select-label"
            id="select"
            value={cloaking}
            label={t("link_cloaking")}
            {...register("checkout_link_cloaking")}
            onChange={handleChangeCloaking}
          >
            <MenuItem value={false as any}>{t("false")}</MenuItem>
            <MenuItem value={true as any}>{t("true")}</MenuItem>
          </Select>
        </FormControl>
        <TextField
          id="standard-money_back_guarantee"
          error={errors.money_back_guarantee === undefined ? false : true}
          helperText={errors.money_back_guarantee?.message?.toString()}
          label={t("money_back_guarantee")}
          variant="standard"
          {...register("money_back_guarantee")}
        />
        <TextField
          id="standard-display_price"
          error={errors.display_price === undefined ? false : true}
          helperText={errors.display_price?.message?.toString()}
          label={t("display_price")}
          variant="standard"
          placeholder="ex. USD 1997 or 6 x USD 387"
          {...register("display_price")}
        />
        {apiLoading ? (
          <Loading />
        ) : (
          <div>
            <Button type="submit" variant="contained">
              {t("create")}
            </Button>
            <Button
              sx={{ ml: 1 }}
              onClick={() =>
                navigate(`/${path}/products/${product_id}/paymentmethods`)
              }
              variant="outlined"
            >
              {t("cancel")}
            </Button>
          </div>
        )}
      </form>
    </Container>
  );
};

export default PaymentMethodCreationForm;
