import SalesUpdateForm from "../../components/SalesUpdateForm";

const SalesUpdatePage = () => {
  return (
    <>
      <SalesUpdateForm />
    </>
  );
};

export default SalesUpdatePage;
