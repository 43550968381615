import styled from "styled-components";

export const Container = styled.form`
  padding: 15px 0;
  display: flex;
  flex-direction: column;
  gap: 7px;
  width: 90%;
  max-width: 500px;
  .registerRedirect {
    text-align: center;
    color: ${({ theme }) => theme.colors.primary};
  }
  .registerRedirect:hover {
    opacity: 0.8;
    cursor: pointer;
  }
  .showPasswordContainer {
    display: flex;
    gap: 5px;
  }
  .forgotPasswordLink {
    color: ${({ theme }) => theme.colors.primary};
    padding: 5px 0;
    font-size: 0.8rem;
  }
  .forgotPasswordLink:hover {
    opacity: 0.4;
    cursor: pointer;
  }
  @media (min-width: 1100px) {
    .showPasswordContainer {
      width: 80%;
      display: flex;
      gap: 5px;
    }
  }
`;
