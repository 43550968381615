import { Navigate, useLocation, useParams } from "react-router-dom";
import { useSeller } from "../providers/users";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

interface IPrivateRoutesProps {
  rolesArray?: string[];
  redirect?: string;
  redirectRecruit?: boolean;
  children: React.ReactNode;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const PrivateRoutes = ({
  rolesArray,
  redirect = "dashboard",
  redirectRecruit = true,
  children,
}: IPrivateRoutesProps) => {
  const { path } = useParams();
  const { seller, token, verifyRolePermission, isActive, Logout } = useSeller();
  const { i18n } = useTranslation();
  const query = useQuery();

  const language = query.get("language");
  const forceChangePassword =
    window.localStorage.getItem("SPP-FORCE-CHANGE-PASSWORD") || "";

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language === "pt" ? "pt" : "en");
    }
  }, [i18n, language]);
  if (!path || path == "null") {
    Logout();
  }
  if (!token) {
    return <Navigate to={`/${path}`} />;
  }
  if (forceChangePassword) {
    return <Navigate to={`/${path}/changepassword`} />;
  }

  if (!isActive && seller) {
    return <Navigate to={`/${path}`} />;
  }

  if (!seller) {
    return <></>;
  }

  if (seller) {
    const isRecruit = seller.roles.some((e: any) => e.name === "Recruit");
    if (isRecruit && redirectRecruit) {
      return <Navigate to={`/${path}/candidate`} />;
    }
    if (!rolesArray) {
      return <>{children}</>;
    }
    const canAccess = verifyRolePermission(rolesArray);
    if (canAccess) {
      return <>{children}</>;
    }
  }

  return <Navigate to={`/${path}/${redirect}`} />;
};

export default PrivateRoutes;
