import ProfileAddressForm from "../../components/ProfileAddressForm";

const ProfileAddressPage = () => {
  return (
    <>
      <ProfileAddressForm />
    </>
  );
};

export default ProfileAddressPage;
