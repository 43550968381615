import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import Loading from "../Loading";

import { useSeller } from "../../providers/users";
import { Container } from "./styles";
import { useRole } from "../../providers/roles";

interface IProps {
  setModalEnableDisableUser: React.Dispatch<React.SetStateAction<boolean>>;

  userId: string;
  disable?: boolean;
}

const ModalEnableDisableUser = ({
  setModalEnableDisableUser,
  userId,
  disable,
}: IProps) => {
  const { apiLoading } = useSeller();
  const { t } = useTranslation();
  const { adminDisableUser, adminEnableUser } = useRole();
  const onClickDisable = async () => {
    if (disable) {
      await adminDisableUser(userId);
    } else {
      await adminEnableUser(userId);
    }
    setModalEnableDisableUser(false);
  };

  return (
    <Container>
      <div className="modalContainer">
        <span>
          <button
            className="exitModalDelete"
            onClick={() => {
              setModalEnableDisableUser(false);
            }}
          >
            X
          </button>
        </span>
        <p>{disable ? t("are_u_sure_disable") : t("are_u_sure_enable")}</p>

        {apiLoading ? (
          <Loading />
        ) : (
          <div className="disableButtonsDiv">
            <Button
              sx={{ m: 2 }}
              variant={"contained"}
              type="submit"
              disabled={apiLoading}
              onClick={async () => await onClickDisable()}
            >
              {" "}
              {t("confirm")}
            </Button>
            <Button
              sx={{ m: 2 }}
              variant="contained"
              type="submit"
              disabled={apiLoading}
              onClick={() => setModalEnableDisableUser(false)}
            >
              {t("cancel")}
            </Button>
          </div>
        )}
      </div>
    </Container>
  );
};

export default ModalEnableDisableUser;
