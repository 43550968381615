import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Sales from "../../components/Sales";
import { useTenant } from "../../providers/tenant";
import { IUserFromTenant } from "../../providers/users/user.interface";

const AdminSalesPage = () => {
  const { allUsers } = useTenant();
  const navigate = useNavigate();
  const { path, user_id } = useParams();

  useEffect(() => {
    if (allUsers && !allUsers.find((e: IUserFromTenant) => e.id === user_id)) {
      navigate(`/${path}/dashboard`);
    }
  }, [allUsers, navigate, path, user_id]);
  const user = allUsers
    ? allUsers.find((e: IUserFromTenant) => e.id === user_id)
    : null;

  return (
    <>
      {user && (
        <Sales
          allSales={user.sales}
          name={`${user.first_name} ${user.last_name}`}
          isAdminView={true}
        />
      )}
    </>
  );
};

export default AdminSalesPage;
