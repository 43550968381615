import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid lightgrey;

  .logContainer {
    display: flex;
    align-items: center;
  }

  .logInfo {
    padding: 15px;
    display: flex;
    font-size: 0.75rem;
    justify-content: space-evenly;
    flex-direction: column;
    gap: 5px;
    max-height: 250px;
  }
  .logItemHeader {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }
  .logName {
    font-weight: bold;
    color: ${({ theme }) => theme.colors.primary};
  }
  .logCreatedBy {
    color: grey;
  }
`;
