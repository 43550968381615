import { Button } from "@mui/material";
import { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { AiOutlinePaperClip } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import RecruitmentCreationForm from "../../components/RecruitmentCreationForm";
import { Container } from "./styles";

const RecruitmentCreationPage = () => {
  const [created, setCreated] = useState(false);
  const { t } = useTranslation();
  const [recruitmentLink, setRecruitmentLink] = useState("");
  const navigate = useNavigate();
  const { path } = useParams();

  return (
    <Container>
      {created === false ? (
        <RecruitmentCreationForm
          setRecruitmentLink={setRecruitmentLink}
          setCreated={setCreated}
        />
      ) : (
        <div>
          <h2>{t("new_recruitment")}</h2>
          <div className="createdContainer">
            <h4>{t("recruitment_created")}</h4>
            <p>{t("share_link")}</p>
            <div className="sellerLinkCopy">
              <p>
                {window.innerWidth < 450 && recruitmentLink.length > 60
                  ? recruitmentLink.slice(0, 60 - 1) + "..."
                  : recruitmentLink}
              </p>
              <CopyToClipboard
                onCopy={() => toast.success(t("copied"))}
                text={recruitmentLink}
              >
                <button>
                  {" "}
                  <AiOutlinePaperClip />
                </button>
              </CopyToClipboard>
            </div>
            <Button
              variant="contained"
              onClick={() => navigate(`/${path}/recruitments`)}
            >
              {t("back_recruitments")}
            </Button>
          </div>
        </div>
      )}
    </Container>
  );
};

export default RecruitmentCreationPage;
