import { AiOutlineEdit } from "react-icons/ai";
import { useSeller } from "../../providers/users";
import { Container } from "./styles";
import { useNavigate, useParams } from "react-router-dom";
import { ITraining } from "../../providers/playlistsTrainings/playlistAndTrainings.interface";
import { useState } from "react";
import ModalVideoPlayer from "../ModalVideoPlayer";

interface IProps {
  training: ITraining;
}

const TrainingItem = ({ training }: IProps) => {
  const { verifyRolePermission, roleAdmin } = useSeller();
  const navigate = useNavigate();
  const { path } = useParams();
  const [open, setOpen] = useState(false);
  const [videoLink, setVideoLink] = useState<string | null>(null);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <Container
        src={
          '"' + training.training_thumbnail?.url + '"' ||
          "https://nayemdevs.com/wp-content/uploads/2020/03/default-product-image.png"
        }
      >
        {verifyRolePermission(roleAdmin) ? (
          <div
            onClick={() =>
              navigate("/" + path + "/trainings/" + training.id + "/update")
            }
            className="updateTrainingIcon"
          >
            <span>
              <AiOutlineEdit />
            </span>
          </div>
        ) : (
          <></>
        )}
        <div
          className="imageDiv"
          onClick={() => {
            setVideoLink(training.video_url);
            handleOpen();

            // window.open(training.video_url, "_blank", "noopener,noreferrer")
          }}
        ></div>
        <p>{training.name}</p>
      </Container>
      {videoLink && (
        <ModalVideoPlayer
          handleClose={handleClose}
          open={open}
          videoLink={videoLink}
        />
      )}
    </>
  );
};

export default TrainingItem;
