import { Container, ContainerChat } from "./styles";
import ReactLoading from "react-loading";

const Loading = () => {
  return (
    <Container>
      <ReactLoading
        type={"spin"}
        color={"#5584F0"}
        height={"50px"}
        width={"50px"}
      />
    </Container>
  );
};

export const LoadingChat = () => {
  return (
    <ContainerChat>
      <ReactLoading
        type={"bubbles"}
        color={"#0000008A"}
        height={"50px"}
        width={"50px"}
      />
    </ContainerChat>
  );
};

export default Loading;
