import { createGlobalStyle } from "styled-components";
import styled from "styled-components";
import { TextField } from "@material-ui/core";
export const GlobalStyle = createGlobalStyle`
* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  scroll-behavior:smooth;
  font-family: 'Montserrat', sans-serif;
}
button {
 cursor: pointer;

}
a {
  text-decoration: none;
  color: white;
}
p {
  margin-bottom:0;
}

.MuiInputBase-root:before, .MuiInputBase-root-notchedOutline, .MuiInputBase-root,.MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.54) !important;
}

`;

export const themeLight = {
  colors: {
    primary: "#5585f0",
    white: "#FFFFFF",
    secondary: "#DEDEDE",
    cream: "#97A1C2",
    gray: "#2C3030",
    black: "#263238",
    primaryHover: "#5299f0",
  },
};

export const themeDark = {
  colors: {
    primary: "#CECECE",
    white: "#FFFFFF",
    black: "#000000",
  },
};
