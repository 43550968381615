import { useTranslation } from "react-i18next";
import { MdOutlinePersonSearch } from "react-icons/md";
import { useRecruitment } from "../../providers/recruitments";
import { IRecruitment } from "../../providers/recruitments/recruitments.interface";
import { useSeller } from "../../providers/users";
import RecruitmentItem from "../RecruitmentItem";
import PageHeader from "../pageHeader";
import { Container } from "./styles";
const RecruitmentsList = () => {
  const { roleAdmin } = useSeller();
  const { allRecruitments } = useRecruitment();
  const { t } = useTranslation();

  return (
    <Container>
      <PageHeader
        title={t("recruitments")}
        description={t("recruitments")}
        buttonContent={t("create")}
        buttonPath="recruitments/create"
        verifyRole={true}
        roleToVerify={roleAdmin}
        icon={<MdOutlinePersonSearch />}
      />

      {allRecruitments && allRecruitments.length > 0 ? (
        <div className="recruitmentList">
          {allRecruitments &&
            allRecruitments.map((e: IRecruitment) => (
              <RecruitmentItem recruitment={e} key={e.id} />
            ))}
        </div>
      ) : (
        <div className="recruitmentList">
          <h4>{t("no-recruitment-found")}</h4>
        </div>
      )}
    </Container>
  );
};

export default RecruitmentsList;
