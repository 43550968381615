import PlaylistsCreationForm from "../../components/PlaylistCreationForm";

const PlaylistCreation = () => {
  return (
    <>
      <PlaylistsCreationForm />
    </>
  );
};

export default PlaylistCreation;
