import { Button, IconButton } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useTenant } from "../../providers/tenant";
import { Container } from "./styles";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { IoSettingsOutline } from "react-icons/io5";
import PageHeader from "../pageHeader";

const SettingsContainer = () => {
  const { tenantSettings } = useTenant();
  const { path } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [showSettings, setShowSettings] = useState<boolean>(false);
  const handleClickShowSettings = () => setShowSettings(!showSettings);
  const handleMouseDownSettings = () => setShowSettings(!showSettings);

  const [showApiKey, setShowApiKey] = useState<boolean>(false);
  const handleClickShowApiKey = () => setShowApiKey(!showApiKey);
  const handleMouseDownApiKey = () => setShowApiKey(!showApiKey);
  const [showOpenAiApiKey, setShowOpenAiApiKey] = useState<boolean>(false);
  const handleClickShowOpenAiApiKey = () =>
    setShowOpenAiApiKey(!showOpenAiApiKey);
  const handleMouseDownOpenAiApiKey = () =>
    setShowOpenAiApiKey(!showOpenAiApiKey);
  return (
    <Container>
      <PageHeader
        title={t("tenant_settings")}
        description={t("tenant_settings")}
        icon={<IoSettingsOutline />}
      />
      <div>
        <p className="settingsTitle">{t("link_expiration_time")}</p>
        <p>
          {tenantSettings && tenantSettings.link_expiration === true
            ? t("yes")
            : t("no")}
        </p>

        {tenantSettings && tenantSettings.link_expiration ? (
          <div>
            <p className="settingsTitle">{t("expiration_time")}:</p>
            <p>
              {tenantSettings && tenantSettings.link_expiration_time}{" "}
              {t("minutes")}.
            </p>
          </div>
        ) : (
          <></>
        )}
        <p className="settingsTitle"> Webhook endpoint:</p>
        <p>{`POST ${process.env.REACT_APP_BACKEND_URL}/sales`}</p>

        <p className="settingsTitle"> x-spp-token:</p>
        <p>
          {showSettings && tenantSettings
            ? tenantSettings["x-spp-token"]
            : "**********************"}
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleClickShowSettings}
            onMouseDown={handleMouseDownSettings}
          >
            {showSettings ? <VisibilityIcon /> : <VisibilityOffIcon />}
          </IconButton>
        </p>
        <p className="settingsTitle"> Shorts api key:</p>
        <p>
          {tenantSettings && tenantSettings.shorts_api_key === null
            ? t("not_setted")
            : showApiKey && tenantSettings
            ? tenantSettings.shorts_api_key
            : "**********************"}
          <IconButton
            aria-label="toggle api key visibility"
            onClick={handleClickShowApiKey}
            onMouseDown={handleMouseDownApiKey}
          >
            {showApiKey ? <VisibilityIcon /> : <VisibilityOffIcon />}
          </IconButton>
        </p>
        <p className="settingsTitle"> OpenAI api key:</p>
        <p>
          {tenantSettings && tenantSettings.openai_api_key === null
            ? t("not_setted")
            : showOpenAiApiKey && tenantSettings
            ? tenantSettings.openai_api_key
            : "**********************"}
          <IconButton
            aria-label="toggle api key visibility"
            onClick={handleClickShowOpenAiApiKey}
            onMouseDown={handleMouseDownOpenAiApiKey}
          >
            {showOpenAiApiKey ? <VisibilityIcon /> : <VisibilityOffIcon />}
          </IconButton>
        </p>
        <div>
          <p className="settingsTitle">Shorts domain:</p>
          <p>
            {tenantSettings && tenantSettings.shorts_domain
              ? tenantSettings.shorts_domain
              : t("not_setted")}
          </p>
        </div>

        <p className="settingsTitle">
          {t("terms_conditions")}:{" "}
          {tenantSettings && tenantSettings.terms_conditions_version}
        </p>

        <a
          href={tenantSettings && tenantSettings.terms_conditions}
          target="_blank"
          rel="noreferrer"
        >
          <Button variant="outlined">{t("read_terms_conditions")}</Button>
        </a>
        <p className="settingsTitle">
          {t("privacy_policy")}:{" "}
          {tenantSettings && tenantSettings.privacy_policy_version}
        </p>
        <a
          href={tenantSettings && tenantSettings.privacy_policy}
          target="_blank"
          rel="noreferrer"
        >
          <Button variant="outlined">{t("read_privacy_policy")}</Button>
        </a>
        <p className="settingsTitle">
          {t("sales_agent_manual")}:{" "}
          {tenantSettings && tenantSettings.sales_manual_version}
        </p>
        <a
          href={tenantSettings && tenantSettings.sales_manual}
          target="_blank"
          rel="noreferrer"
        >
          <Button variant="outlined">{t("read_sales_agent_manual")}</Button>
        </a>
      </div>
      <Button
        onClick={() => navigate("/" + path + "/settings/update")}
        sx={{ mt: 5 }}
        variant="contained"
      >
        {t("update")}{" "}
      </Button>
    </Container>
  );
};

export default SettingsContainer;
