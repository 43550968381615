import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useRecruitment } from "../../providers/recruitments";
import { useSeller } from "../../providers/users";
import Loading from "../Loading";
import { Container } from "./styles";

interface IProps {
  setModalChangePhase: React.Dispatch<React.SetStateAction<boolean>>;
  defaultPhase: string;
  candidature_id: string;
}
const ModalChangePhase = ({
  setModalChangePhase,
  defaultPhase,
  candidature_id,
}: IProps) => {
  const { adminUpdateCandidature } = useRecruitment();
  const [phase, setPhase] = useState(defaultPhase);
  const { apiLoading } = useSeller();
  const { t } = useTranslation();
  const formSchema = yup
    .object()
    .shape({
      recruitment_phase: yup.string().required(t("recruitment_phase_required")),
    })
    .required();

  const handleChangePhase = (event: any) => {
    setPhase(event.target.value);
  };
  const { register, handleSubmit } = useForm({
    resolver: yupResolver(formSchema),
  });
  type FormValues = yup.InferType<typeof formSchema>;
  const onSubmit = (data: FormValues) => {
    adminUpdateCandidature(candidature_id, setModalChangePhase, data);
    setModalChangePhase(false);
  };
  return (
    <Container>
      <div className="modalContainer">
        <span>
          <button
            className="exitModalPassword"
            onClick={() => {
              setModalChangePhase(false);
            }}
          >
            X
          </button>
        </span>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl>
            <InputLabel sx={{ mt: 1 }} id="demo-simple-select-label">
              {t("phase")}
            </InputLabel>
            <Select
              sx={{ mt: 1 }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={phase}
              label={t("phase")}
              {...register("recruitment_phase")}
              onChange={handleChangePhase}
            >
              <MenuItem value={"approved_1_phase"}>
                {t("approved_1_phase")}
              </MenuItem>
              <MenuItem value={"approved_1_phase_test"}>
                {t("approved_1_phase_test")}
              </MenuItem>
              <MenuItem value={"approved_2_phase"}>
                {" "}
                {t("approved_2_phase")}
              </MenuItem>
              <MenuItem value={"rejected"}> {t("rejected")}</MenuItem>
            </Select>
          </FormControl>
          {apiLoading ? (
            <Loading />
          ) : (
            <Button sx={{ mt: 2 }} variant="contained" type="submit">
              {t("update")}
            </Button>
          )}
        </form>
      </div>
    </Container>
  );
};

export default ModalChangePhase;
