import { createContext, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../services";
import { useTenant } from "../tenant/index";
import { IProviderProps } from "../tenant/tenant.interfaces";
import { useSeller } from "../users";
import { ICreateSaleContestation, ISale, IUpdateSale } from "./sales.interface";
export const SaleContext = createContext<any | null>(null);

export const SaleProvider = ({ children }: IProviderProps) => {
  const [allSales, setAllSales] = useState<ISale | null>(null);
  const { getTenantUsers } = useTenant();
  const { token, setApiLoading, setPageMounting, verifyUserLoggedIn } =
    useSeller();

  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const path = location.pathname.split("/")[1];
  const getAllSales = async (mount = true) => {
    if (mount) setPageMounting(true);

    await api
      .get("/sales/admin", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setAllSales(res.data);
        setPageMounting(false);
      })
      .catch((err) => {
        console.log(err.response.data.message);
        setPageMounting(false);
      });
  };
  const updateArraySalesComissionsStatus = async (
    arraySalesCommissions: string[],
    status: string,
    setModal: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    setApiLoading(true);
    const updated = await api
      .patch(
        "/salescommissions",
        {
          status: status,
          salesCommissions: arraySalesCommissions,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(async (res) => {
        toast.success(t("updated"));

        if (location.pathname.includes("allsales")) {
          getAllSales(false);
        } else {
          getTenantUsers(false);
        }
        return true;
      })
      .catch((err) => {
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
        setApiLoading(false);
        return false;
      });

    setModal(false);
    setApiLoading(false);
    return updated;
  };

  const createSaleContestation = async (data: ICreateSaleContestation) => {
    setApiLoading(true);
    let dataFile: File[];
    if (data.fileInput && data.fileInput.length !== 0) {
      dataFile = Object.values(data.fileInput);
    }
    delete data.fileInput;
    const created = await api
      .post("/sales/contestation", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async (res) => {
        for (let i = 0; i < dataFile.length; i++) {
          await uploadFileSalesContestation(res.data.id, dataFile[i]);
        }
        setApiLoading(false);
        return true;
      })
      .catch((err) => {
        setApiLoading(false);
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
        return false;
      });
    if (created) {
      verifyUserLoggedIn();
      navigate(`/${path}/sales`);
    }
  };

  const uploadFileSalesContestation = async (saleId: string, data: File) => {
    const dataFile = new FormData();
    dataFile.append("file", data);
    await api
      .post(`/sales/file/${saleId}`, dataFile, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(async (res) => {})
      .catch((err) => {
        setApiLoading(false);
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
      });
  };

  const updateSale = async (
    sale_id: string,
    data: IUpdateSale,
    isAdminView: boolean
  ) => {
    setApiLoading(true);
    const updated = await api
      .patch(`/sales/${sale_id}`, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setApiLoading(false);

        if (location.pathname.includes("allsales")) {
          getAllSales();
        } else if (location.pathname.includes("users")) {
          getTenantUsers();
        } else {
          verifyUserLoggedIn();
        }

        return true;
      })
      .catch((err) => {
        setApiLoading(false);
        toast.error(err.response.data.message);
        console.log(err.response.data.message);
        return false;
      });
    if (updated) {
      return true;
    }
  };
  const updateSaleNote = async (
    sale_id: string,
    data: IUpdateSale,
    isAdminView: boolean
  ) => {
    setApiLoading(true);
    const updated = await api
      .patch(`/sales/note/${sale_id}`, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setApiLoading(false);

        if (location.pathname.includes("allsales")) {
          getAllSales(false);
        } else if (location.pathname.includes("users")) {
          getTenantUsers(false);
        } else {
          verifyUserLoggedIn();
        }

        return true;
      })
      .catch((err) => {
        setApiLoading(false);
        toast.error(err.response.data.message);
        console.log(err.response.data.message);
        return false;
      });
    if (updated) {
      return true;
    }
  };
  return (
    <SaleContext.Provider
      value={{
        allSales,
        setAllSales,
        getAllSales,
        updateArraySalesComissionsStatus,
        createSaleContestation,
        updateSale,
        updateSaleNote,
      }}
    >
      {children}
    </SaleContext.Provider>
  );
};

export const useSale = () => useContext(SaleContext);
