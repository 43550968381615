import styled from "styled-components";

export const Container = styled.div`
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 15px;
  }
  .pagination > li {
    margin: 4px;
  }
  .pagination > li > a {
    background-color: ${({ theme }) => theme.colors.secondary};
    padding: 5px 7px;
    border-radius: 5px;
    color: ${({ theme }) => theme.colors.black};
  }
  .pagination > li > a:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
  }
`;
