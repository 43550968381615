import ChangeProductCommissions from "../../components/ChangeProductCommissions";

const UserProductCommissions = () => {
  return (
    <>
      <ChangeProductCommissions />
    </>
  );
};

export default UserProductCommissions;
