import { useTranslation } from "react-i18next";
import { AiFillEdit } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import { IAssistants } from "../../providers/salesCopilot/salesCopilot.interface";
import { Container } from "./styles";
import { useSeller } from "../../providers/users";
import { Button } from "@mui/material";
import { useSalesCopilot } from "../../providers/salesCopilot";
import path from "path";
import Loading from "../Loading";
import { toast } from "react-toastify";

interface IProps {
  assistant: IAssistants;
}
const AssistantItem = ({ assistant }: IProps) => {
  const navigate = useNavigate();
  const { path } = useParams();
  const { t } = useTranslation();
  const { uploadFileAssistants } = useSalesCopilot();
  const { verifyRolePermission, roleAdmin, apiLoading } = useSeller();
  const Onclick = (e: any) => {
    navigate(`/${path}/assistants/${assistant.id}/update`);
  };
  const containerOnclick = (e: any) => {
    if (
      e.target.className === "no-redirect" ||
      e.target.className.includes("MuiButton") === true ||
      e.target.className === "assistantLink" ||
      e.target.className === "inputNone"
    ) {
      return;
    } else {
      navigate(`/${path}/assistants/${assistant.id}/threads`);
    }
  };
  const filesAccepted = ["text/html", "application/pdf", "text/plain"];
  const handleFileUpload = async (e: any) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    if (!filesAccepted.includes(file.type)) {
      toast.error(t("file_accept", { file_types: ".pdf, .txt, .html" }));
      return;
    }
    await uploadFileAssistants(assistant.id, file, true);
  };
  return (
    <Container onClick={(e) => containerOnclick(e)}>
      <div className="assistantContainer">
        <div className="assistantInfo">
          <div className="assistantItemHeader">
            <p className="assistantName"> {assistant.name}</p>
          </div>

          <p className="assistantDescription">{assistant.description}</p>
        </div>
      </div>
      {apiLoading ? (
        <div className="loading">
          <Loading />
        </div>
      ) : (
        verifyRolePermission(roleAdmin) && (
          <div className="assistantLink">
            <Button
              onClick={(e) => {
                Onclick(e);
              }}
              color="primary"
              variant="contained"
              className="no-redirect"
            >
              {t("update")}
            </Button>
            <Button
              color="primary"
              variant="contained"
              className="no-redirect"
              component="label"
            >
              {t("upload_file")}
              <input
                className="inputNone"
                type="file"
                accept=".pdf, .txt, .html"
                required
                onChange={handleFileUpload}
                id={assistant.id}
                name="assistant"
              />
            </Button>
          </div>
        )
      )}
    </Container>
  );
};

export default AssistantItem;
