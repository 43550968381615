import styled from "styled-components";

export const Container = styled.div`
  .buttonDiv {
    justify-content: center;
  }
  .chat-threads {
    border-right: "1px solid #E0E0E0";
  }
  form {
    display: flex;
    align-items: center;
    padding: 5px;
  }
  .select-chat {
    display: none;
  }
  .button-thread {
    display: block;
    font-size: 14px;
    font-weight: 500;
    padding: 8px 16px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    border-radius: 4px;
    border: 2px solid transparent;
    transition: all 0.3s ease;
    outline: none;
    background-color: ${({ theme }) => theme.colors.primary};
    color: #fff;
    margin-bottom: 3px;
    text-transform: capitalize;
  }

  /* Estilização para quando o botão estiver em estado hover */
  .button-thread:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .delete-thread {
    background-color: #e45b5b !important;
  }
  @media (max-width: 600px) {
    .chat-threads {
      display: none;
    }
    .select-chat {
      display: block;
    }
  }
`;
