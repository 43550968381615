import { Button, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineEdit } from "react-icons/ai";
import { IoSchoolOutline } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import { isEqual } from "underscore";
import { usePlaylistTraining } from "../../providers/playlistsTrainings";
import { IPlaylist } from "../../providers/playlistsTrainings/playlistAndTrainings.interface";
import { useSeller } from "../../providers/users";
import TrainingContainer from "../TrainingContainer";
import PageHeader from "../pageHeader";
import { Container } from "./styles";

const TrainingsList = () => {
  const { verifyRolePermission, roleAdmin } = useSeller();
  const [language, setLanguage] = useState("english");
  const { allPlaylists } = usePlaylistTraining();
  const { path } = useParams();
  const { t } = useTranslation();

  const handleChange = (event: any, newLanguage: any) => {
    if (!newLanguage) {
      return;
    }
    setLanguage(newLanguage);
  };
  const navigate = useNavigate();
  const filteredPlaylists = allPlaylists
    ? allPlaylists.filter((e: IPlaylist) => e.language === language)
    : [];

  return (
    <>
      <Container>
        <PageHeader
          title={t("trainings")}
          description={t("trainings_list")}
          buttonContent={t("create")}
          buttonPath="trainings/create"
          verifyRole={true}
          roleToVerify={roleAdmin}
          icon={<IoSchoolOutline />}
        />
        <ToggleButtonGroup
          size="small"
          color="primary"
          value={language}
          exclusive
          onChange={handleChange}
          aria-label="Platform"
        >
          <ToggleButton size="small" value="english">
            {t("english")}
          </ToggleButton>
          <ToggleButton size="small" value="portuguese">
            {t("portuguese")}
          </ToggleButton>
        </ToggleButtonGroup>
        {verifyRolePermission(roleAdmin) ? (
          <div className="createPlaylistDiv">
            <Button
              variant="contained"
              sx={{ width: 1 }}
              fullWidth={false}
              onClick={() => navigate("/" + path + "/playlists/create")}
            >
              + {t("create")} Playlist
            </Button>
          </div>
        ) : (
          <></>
        )}
        {(allPlaylists && allPlaylists.length === 0) ||
        (allPlaylists &&
          isEqual(
            filteredPlaylists,
            allPlaylists.filter((e: IPlaylist) => e.trainings.length === 0)
          ) &&
          !verifyRolePermission(roleAdmin)) ? (
          <div className="trainingList">
            <h4>{t("no-training-found")}</h4>
          </div>
        ) : (
          <div className="trainingList">
            {filteredPlaylists &&
              filteredPlaylists.map((e: IPlaylist) => (
                <div key={e.id}>
                  <div className="playlistName">
                    <h3>{e.name}</h3>{" "}
                    {/* id: "no-playlist" is a fictiious playlist on backend */}
                    {verifyRolePermission(roleAdmin) &&
                    e.id !== "no-playlist" ? (
                      <div
                        onClick={() =>
                          navigate(
                            "/" + path + "/playlists/" + e.id + "/update"
                          )
                        }
                        className="updatePlaylistIcon"
                      >
                        <span>
                          <AiOutlineEdit />
                        </span>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <TrainingContainer trainings={e.trainings} />
                </div>
              ))}
          </div>
        )}
      </Container>
    </>
  );
};

export default TrainingsList;
