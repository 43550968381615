import { useTranslation } from "react-i18next";
import Chart from "../../components/Chart";
import Ranking from "../../components/Rankings";
import PageHeader from "../../components/pageHeader";
import { RxHome } from "react-icons/rx";
import { useSeller } from "../../providers/users";

const Home = () => {
  const { t } = useTranslation();
  const { seller } = useSeller();

  return (
    <>
      <PageHeader
        title={t("dashboard")}
        description={t("dashboard")}
        icon={<RxHome />}
      />
      <Chart />
      <Ranking />
    </>
  );
};

export default Home;
