import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Route, Routes, useLocation } from "react-router-dom";
import DataLayout from "../components/DataLayout";
import Layout from "../components/Layout";
import ActivationPage from "../pages/ActivationPage";
import AdminUserCreationPage from "../pages/AdminCreateUserPage";
import AdminInvoicesPage from "../pages/AdminInvoicesPage";
import AdminSalesPage from "../pages/AdminSalesPage";
import AdminUsersPage from "../pages/AdminUsersPage";
import AdminAllSalesPage from "../pages/AllSalesPage";
import ApproveCandidatePage from "../pages/ApproveCandidatePage";
import AssistantCreationPage from "../pages/AssistantCreationPage";
import AssistantUpdatePage from "../pages/AssistantUpdatePage";
import AssistantsPage from "../pages/AssistantsPage";
import CandidatePage from "../pages/CandidatePage";
import ChangePassword from "../pages/ChangePassword";
import ErrorPage from "../pages/ErrorPage";
import FindTenant from "../pages/FindTenantPage";
import Home from "../pages/Home";
import InvoicesCreationPage from "../pages/InvoicesCreationPage";
import InvoicesPage from "../pages/InvoicesPage";
import InvoicesUpdatePage from "../pages/InvoicesUpdatePage";
import Login from "../pages/LoginPage";
import LogsPage from "../pages/LogsPage";
import PaymentMethodCreation from "../pages/PaymentMethodCreation";
import PaymentMethodUpdate from "../pages/PaymentMethodUpdate";
import PaymentMethods from "../pages/PaymentMethods";
import PlaylistCreation from "../pages/PlaylistCreationPage";
import PlaylistUpdate from "../pages/PlaylistUpdatePage";
import ProductCreation from "../pages/ProductCreation";
import ProductUpdate from "../pages/ProductUpdate";
import Products from "../pages/Products";
import Profile from "../pages/Profile";
import ProfileAddressPage from "../pages/ProfileAddressPage";
import RecruitmentCandidatePage from "../pages/RecruitmentCandidatePage";
import RecruitmentsCandidaturesPage from "../pages/RecruitmentCandidatures";
import RecruitmentCreationPage from "../pages/RecruitmentCreation";
import RecruitmentUpdate from "../pages/RecruitmentUpdate";
import RecruitmentsPage from "../pages/Recruitments";
import SalesContestationCreation from "../pages/SaleContestationCreation";
import SalesPage from "../pages/SalesPage";
import SalesTeamCreationPage from "../pages/SalesTeamCreation";
import SalesTeamMembersPage from "../pages/SalesTeamMembersPage";
import SalesTeamUpdatePage from "../pages/SalesTeamUpdatePage";
import SalesTeamsPage from "../pages/SalesTeams";
import SalesTestPage from "../pages/SalesTestPage";
import SalesUpdatePage from "../pages/SalesUpdatePage";
import SendPasswordRecoveryPage from "../pages/SendPasswordRecoveryCodePage/index";
import SettingsPage from "../pages/Settings/index";
import SettingsUpdate from "../pages/SettingsUpdate";
import ThreadsPage from "../pages/Threads";
import TrainingCreation from "../pages/TrainingCreationPage";
import TraningUpdate from "../pages/TrainingUpdatePage";
import Trainings from "../pages/TrainingsPage";
import UserUpdate from "../pages/UserUpdatePage";
import UserProductCommissions from "../pages/UsersProductCommissions";
import { useInvoice } from "../providers/invoices";
import { usePlaylistTraining } from "../providers/playlistsTrainings";
import { useProduct } from "../providers/products";
import { useRecruitment } from "../providers/recruitments";
import { useSale } from "../providers/sales";
import { useSalesCopilot } from "../providers/salesCopilot";
import { useSalesTeam } from "../providers/salesTeams";
import { useTenant } from "../providers/tenant";
import { useSeller } from "../providers/users";
import PrivateRoutes from "./PrivateRoutes";
import { LogsRoutes } from "./logs";

export const AppRoutes = () => {
  const {
    verifyUserLoggedIn,
    getAllSellers,
    seller,
    roleAdmin,
    roleRecruit,
    allSellers,
  } = useSeller();
  const { getAllProducts, allProducts } = useProduct();
  const { getAllPlaylists, allPlaylists } = usePlaylistTraining();
  const { getAllRecruitments, allRecruitments } = useRecruitment();
  const { getAllAssistants, allAssistants, getAllThreads, allThreads } =
    useSalesCopilot();

  const { getAllSalesTeams, allSalesTeams } = useSalesTeam();
  const { getAllSales, allSales } = useSale();
  const { getAllInvoices, allInvoices } = useInvoice();
  const {
    getTenant,
    tenantSettings,
    getTenantUsers,
    getTenantByPath,
    tenantByPath,
    allUsers,
    roleFinance,
    getAllLogs,
    allLogs,
  } = useTenant();
  const location = useLocation();
  const { i18n } = useTranslation();
  const logsRoutes = LogsRoutes();
  useEffect(() => {
    if (
      !location.pathname.includes("/recovery/") ||
      !location.pathname.includes("/activation/")
    ) {
      verifyUserLoggedIn();
    }
    if (!tenantByPath) {
      getTenantByPath();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (seller) {
      i18n.changeLanguage(seller.default_language === "english" ? "en" : "pt");
    }
  }, [i18n, seller]);

  return (
    <Routes>
      {/* {logsRoutes} */}
      <Route path="/:path" element={<Login />} id="login" />
      <Route path="/:path/changepassword" element={<ChangePassword />} />
      <Route
        element={
          <PrivateRoutes>
            <DataLayout
              key="allsellers"
              retrieveData={getAllSellers}
              allData={allSellers}
            />
          </PrivateRoutes>
        }
      >
        <Route
          path="/:path/dashboard"
          element={
            <PrivateRoutes>
              <Layout>
                <Home />
              </Layout>
            </PrivateRoutes>
          }
        />
      </Route>
      <Route
        path="/:path/candidate"
        element={
          <PrivateRoutes rolesArray={roleRecruit} redirectRecruit={false}>
            <CandidatePage />
          </PrivateRoutes>
        }
      />
      <Route
        element={
          <PrivateRoutes rolesArray={roleFinance}>
            <DataLayout
              key={"users"}
              retrieveData={getTenantUsers}
              allData={allUsers}
            />
          </PrivateRoutes>
        }
      >
        <Route
          path="/:path/users"
          element={
            <PrivateRoutes rolesArray={roleFinance}>
              <Layout>
                <AdminUsersPage />
              </Layout>
            </PrivateRoutes>
          }
        />

        <Route
          path="/:path/users/sales/:user_id"
          element={
            <PrivateRoutes rolesArray={roleFinance}>
              <Layout>
                <AdminSalesPage />
              </Layout>
            </PrivateRoutes>
          }
        />
        <Route
          path="/:path/users/commissions/:user_id"
          element={
            <PrivateRoutes rolesArray={roleFinance}>
              <Layout>
                <UserProductCommissions />
              </Layout>
            </PrivateRoutes>
          }
        />
        <Route
          path="/:path/users/invoices/:user_id"
          element={
            <PrivateRoutes rolesArray={roleFinance}>
              <Layout>
                <AdminInvoicesPage />
              </Layout>
            </PrivateRoutes>
          }
        />
      </Route>
      <Route
        element={
          <PrivateRoutes>
            <DataLayout
              key={"playlists"}
              retrieveData={getAllPlaylists}
              allData={allPlaylists}
            />
          </PrivateRoutes>
        }
      >
        <Route
          path="/:path/trainings"
          element={
            <PrivateRoutes>
              <Layout>
                <Trainings />
              </Layout>
            </PrivateRoutes>
          }
        />
        <Route
          path="/:path/trainings/create"
          element={
            <PrivateRoutes rolesArray={roleAdmin}>
              <Layout>
                <TrainingCreation />
              </Layout>
            </PrivateRoutes>
          }
        />

        <Route
          path="/:path/trainings/:training_id/update"
          element={
            <PrivateRoutes rolesArray={roleAdmin}>
              <Layout>
                <TraningUpdate />{" "}
              </Layout>
            </PrivateRoutes>
          }
        />
        <Route
          path="/:path/playlists/create"
          element={
            <PrivateRoutes rolesArray={roleAdmin}>
              <Layout>
                <PlaylistCreation />
              </Layout>
            </PrivateRoutes>
          }
        />
        <Route
          path="/:path/playlists/:playlist_id/update"
          element={
            <PrivateRoutes rolesArray={roleAdmin}>
              <Layout>
                <PlaylistUpdate />
              </Layout>
            </PrivateRoutes>
          }
        />
      </Route>
      <Route
        element={
          <DataLayout
            key={"products"}
            retrieveData={getAllProducts}
            allData={allProducts}
          />
        }
      >
        <Route
          path="/:path/products"
          element={
            <PrivateRoutes>
              <Layout>
                <Products />
              </Layout>
            </PrivateRoutes>
          }
        />
        <Route
          path="/:path/products/create"
          element={
            <PrivateRoutes rolesArray={roleAdmin}>
              <Layout>
                <ProductCreation />{" "}
              </Layout>
            </PrivateRoutes>
          }
        />
        <Route
          path="/:path/products/:product_id/update"
          element={
            <PrivateRoutes rolesArray={roleAdmin}>
              <Layout>
                <ProductUpdate />
              </Layout>
            </PrivateRoutes>
          }
        />
        <Route
          path="/:path/products/:product_id/paymentmethods"
          element={
            <PrivateRoutes>
              <Layout>
                <PaymentMethods />
              </Layout>
            </PrivateRoutes>
          }
        />
        <Route
          path="/:path/products/:product_id/paymentmethods/create"
          element={
            <PrivateRoutes rolesArray={roleAdmin}>
              <Layout>
                <PaymentMethodCreation />
              </Layout>
            </PrivateRoutes>
          }
        />
        <Route
          path="/:path/products/:product_id/paymentmethods/:payment_method_id"
          element={
            <PrivateRoutes rolesArray={roleAdmin}>
              <Layout>
                <PaymentMethodUpdate />
              </Layout>
            </PrivateRoutes>
          }
        />
        <Route
          path="/:path/salescontestation/create"
          element={
            <PrivateRoutes>
              <Layout>
                <SalesContestationCreation />
              </Layout>
            </PrivateRoutes>
          }
        />
      </Route>
      <Route
        path="/:path/sales"
        element={
          <PrivateRoutes>
            <Layout>
              <SalesPage />
            </Layout>
          </PrivateRoutes>
        }
      />
      <Route
        element={
          <PrivateRoutes>
            <DataLayout
              key={"invoices"}
              retrieveData={getAllInvoices}
              allData={allInvoices}
            />
          </PrivateRoutes>
        }
      >
        <Route
          path="/:path/invoices"
          element={
            <PrivateRoutes>
              <Layout>
                <InvoicesPage />
              </Layout>
            </PrivateRoutes>
          }
        />
        <Route
          path="/:path/invoices/create"
          element={
            <PrivateRoutes>
              <Layout>
                <InvoicesCreationPage />
              </Layout>
            </PrivateRoutes>
          }
        />
        <Route
          path="/:path/invoices/:invoice_id/update"
          element={
            <PrivateRoutes>
              <Layout>
                <InvoicesUpdatePage />
              </Layout>
            </PrivateRoutes>
          }
        />
      </Route>
      <Route
        path="/:path/profile"
        element={
          <PrivateRoutes>
            <Layout>
              <Profile />
            </Layout>
          </PrivateRoutes>
        }
      />
      <Route
        path="/:path/profile/update"
        element={
          <PrivateRoutes>
            <Layout>
              <UserUpdate />
            </Layout>
          </PrivateRoutes>
        }
      />
      <Route
        path="/:path/profile/address"
        element={
          <PrivateRoutes>
            <Layout>
              <ProfileAddressPage />
            </Layout>
          </PrivateRoutes>
        }
      />
      <Route
        element={
          <DataLayout
            key={"settings"}
            retrieveData={getTenant}
            allData={tenantSettings}
          />
        }
      >
        <Route
          path="/:path/settings"
          element={
            <PrivateRoutes rolesArray={roleAdmin}>
              <Layout>
                <SettingsPage />
              </Layout>
            </PrivateRoutes>
          }
        />
        <Route
          path="/:path/settings/update"
          element={
            <PrivateRoutes rolesArray={roleAdmin}>
              <Layout>
                <SettingsUpdate />
              </Layout>
            </PrivateRoutes>
          }
        />
      </Route>
      <Route
        element={
          <DataLayout
            key={"recruitments"}
            retrieveData={getAllRecruitments}
            allData={allRecruitments}
          />
        }
      >
        <Route
          path="/:path/recruitments"
          element={
            <PrivateRoutes rolesArray={roleAdmin}>
              <Layout>
                {" "}
                <RecruitmentsPage />{" "}
              </Layout>
            </PrivateRoutes>
          }
        />
        <Route
          path="/:path/recruitments/:recruitment_id"
          element={
            <PrivateRoutes rolesArray={roleAdmin}>
              <Layout>
                <RecruitmentsCandidaturesPage />
              </Layout>
            </PrivateRoutes>
          }
        />
        <Route
          path="/:path/recruitments/create"
          element={
            <PrivateRoutes rolesArray={roleAdmin}>
              <Layout>
                <RecruitmentCreationPage />
              </Layout>
            </PrivateRoutes>
          }
        />
        <Route
          path="/:path/recruitments/:recruitment_id/update"
          element={
            <PrivateRoutes rolesArray={roleAdmin}>
              <Layout>
                <RecruitmentUpdate />
              </Layout>
            </PrivateRoutes>
          }
        />
        <Route
          path="/:path/candidature/:candidature_id"
          element={
            <PrivateRoutes rolesArray={roleAdmin}>
              <Layout>
                <ApproveCandidatePage />
              </Layout>
            </PrivateRoutes>
          }
        />
      </Route>
      <Route
        path="/:path/recruitments/:recruitment_id/candidate"
        element={<RecruitmentCandidatePage />}
      />
      <Route
        path="/:path/candidatures/:candidature_id/salestest"
        element={
          <PrivateRoutes rolesArray={roleRecruit} redirectRecruit={false}>
            <SalesTestPage />{" "}
          </PrivateRoutes>
        }
      />
      `
      <Route
        element={
          <PrivateRoutes rolesArray={roleAdmin}>
            <DataLayout
              key={"logs"}
              retrieveData={getAllLogs}
              allData={allLogs}
            />
          </PrivateRoutes>
        }
      >
        <Route
          element={
            <PrivateRoutes rolesArray={roleAdmin}>
              <DataLayout
                key={"products"}
                retrieveData={getAllProducts}
                allData={allProducts}
              />
            </PrivateRoutes>
          }
        >
          <Route
            element={
              <PrivateRoutes rolesArray={roleAdmin}>
                <DataLayout
                  key={"users"}
                  retrieveData={getTenantUsers}
                  allData={allUsers}
                />
              </PrivateRoutes>
            }
          >
            <Route
              path="/:path/users/create"
              element={
                <PrivateRoutes>
                  <Layout>
                    <AdminUserCreationPage />
                  </Layout>
                </PrivateRoutes>
              }
            />
            <Route
              path="/:path/logs"
              element={
                <PrivateRoutes rolesArray={roleAdmin}>
                  <Layout>
                    <LogsPage />
                  </Layout>
                </PrivateRoutes>
              }
            />
          </Route>
        </Route>
      </Route>
      `
      <Route
        element={
          <DataLayout
            key={"salesteams"}
            retrieveData={getAllSalesTeams}
            allData={allSalesTeams}
          />
        }
      >
        <Route
          path="/:path/salesteams"
          element={
            <PrivateRoutes rolesArray={roleAdmin}>
              <Layout>
                <SalesTeamsPage />
              </Layout>
            </PrivateRoutes>
          }
        />

        <Route
          path="/:path/salesteams/create"
          element={
            <PrivateRoutes rolesArray={roleAdmin}>
              <Layout>
                <SalesTeamCreationPage />
              </Layout>
            </PrivateRoutes>
          }
        />
        <Route
          path="/:path/salesteams/:sales_team_id/update"
          element={
            <PrivateRoutes rolesArray={roleAdmin}>
              <Layout>
                <SalesTeamUpdatePage />
              </Layout>
            </PrivateRoutes>
          }
        />
        <Route
          path="/:path/salesteams/:sales_team_id/members"
          element={
            <PrivateRoutes rolesArray={roleAdmin}>
              <Layout>
                <SalesTeamMembersPage />
              </Layout>
            </PrivateRoutes>
          }
        />
      </Route>
      <Route
        element={
          <DataLayout
            key={"salesPage"}
            retrieveData={getAllSales}
            allData={allSales}
          />
        }
      >
        <Route
          path="/:path/allsales"
          element={
            <PrivateRoutes rolesArray={roleFinance}>
              <Layout>
                <AdminAllSalesPage />
              </Layout>
            </PrivateRoutes>
          }
        />
        <Route
          element={
            <DataLayout
              key={"updateSales"}
              retrieveData={getTenantUsers}
              allData={allUsers}
            />
          }
        >
          <Route
            path="/:path/allsales/:sales_id/update"
            element={
              <PrivateRoutes rolesArray={roleFinance}>
                <Layout>
                  <SalesUpdatePage />
                </Layout>
              </PrivateRoutes>
            }
          />
        </Route>
      </Route>
      <Route path="/:path/*" element={<ErrorPage dashboardLink={true} />} />
      <Route path="/" element={<FindTenant />} />
      <Route path="/:path/activation" element={<ActivationPage />} />
      {/* <Route
        path="/:path/recovery/:token_recovery"
        element={<PasswordRecovery />}
      /> */}
      <Route
        path="/:path/recovery/password"
        element={<SendPasswordRecoveryPage />}
      />
      <Route
        element={
          <DataLayout
            key={"assistants"}
            retrieveData={getAllAssistants}
            allData={allAssistants}
          />
        }
      >
        <Route
          path="/:path/assistants"
          element={
            <PrivateRoutes>
              <Layout>
                {" "}
                <AssistantsPage />{" "}
              </Layout>
            </PrivateRoutes>
          }
        />

        <Route
          path="/:path/assistants/create"
          element={
            <PrivateRoutes rolesArray={roleAdmin}>
              <Layout>
                <AssistantCreationPage />
              </Layout>
            </PrivateRoutes>
          }
        />
        <Route
          path="/:path/assistants/:assistant_id/update"
          element={
            <PrivateRoutes rolesArray={roleAdmin}>
              <Layout>
                <AssistantUpdatePage />
              </Layout>
            </PrivateRoutes>
          }
        />
        <Route
          element={
            <DataLayout
              key={"threads"}
              retrieveData={getAllThreads}
              allData={allThreads}
            />
          }
        >
          <Route
            path="/:path/assistants/:assistant_id/threads"
            element={
              <PrivateRoutes>
                <Layout>
                  {" "}
                  <ThreadsPage />{" "}
                </Layout>
              </PrivateRoutes>
            }
          />
        </Route>
      </Route>
    </Routes>
  );
};
