import { useTranslation } from "react-i18next";
import { useSeller } from "../../providers/users";
import Loading from "../Loading";
import TrainingItem from "../TrainingItem";
import { Container } from "./styles";
import { ITraining } from "../../providers/playlistsTrainings/playlistAndTrainings.interface";

interface IProps {
  trainings: ITraining[];
}
const TrainingContainer = ({ trainings }: IProps) => {
  const { t } = useTranslation();
  const { apiLoading } = useSeller();
  return (
    <Container>
      {apiLoading ? (
        <Loading />
      ) : trainings.length > 0 ? (
        trainings.map((e) => <TrainingItem key={e.id} training={e} />)
      ) : (
        <h5>{t("no-training-found")}</h5>
      )}
    </Container>
  );
};

export default TrainingContainer;
