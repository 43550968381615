import styled from "styled-components";
export const Container = styled.div`
  .createdContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
  }
  .sellerLinkCopy {
    margin-top: 15px;
    margin-bottom: 15px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    max-width: 600px;
  }
  .sellerLinkCopy > p {
    width: 87%;
    display: flex;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px;
    align-items: center;
    border-radius: 10px;
    font-size: 9px;
    background-color: ${({ theme }) => theme.colors.secondary};
  }
  .sellerLinkCopy > button {
    padding: 5px;
    border-radius: 9px;
    border: none;
    background-color: ${({ theme }) => theme.colors.primary};
  }
  .sellerLinkCopy > button > svg {
    width: 20px;
    height: 20px;
    color: white;
  }
`;
