import { useTranslation } from "react-i18next";
import { ILog } from "../../providers/tenant/tenant.interfaces";
import { Container } from "./styles";
import { IUserFromTenant } from "../../providers/users/user.interface";
import { useTenant } from "../../providers/tenant";
import { useState } from "react";

interface IProps {
  log: ILog;
}

const LogItem = ({ log }: IProps) => {
  const { t } = useTranslation();
  const { allUsers, tenantByPath } = useTenant();

  const status = log.description.split(" TO ") || log.description;
  const formattedDate = new Date(log.createdAt);
  const userFor =
    allUsers && allUsers.find((e: IUserFromTenant) => e.id === log.created_for);
  const userBy =
    (allUsers &&
      allUsers.find((e: IUserFromTenant) => e.id === log.created_by)) ||
    "Tenant";
  return (
    <Container>
      <div className="logContainer">
        <div className="logInfo">
          {/* @ts-ignore */}
          <p className="logName"> {t(log.action)}</p>

          <p className="logDescription">
            {/* @ts-ignore */}
            {t(`${log.action}Description`, {
              // @ts-ignore
              previous: t(status[0]),
              // @ts-ignore
              new: t(status[1]),
            })}
          </p>
          <p className="logDescription">Item: {log.item_affected}</p>
          <p className="logCreatedBy">
            {t("date")}:{" "}
            {log &&
              t("date_format", {
                month: formattedDate.getMonth() + 1,
                day: formattedDate.getDate(),
                year: formattedDate.getFullYear(),
              })}
            {" - "}
            {formattedDate.getHours()}:
            {formattedDate.getMinutes() < 10
              ? `0${formattedDate.getMinutes()}`
              : formattedDate.getMinutes()}
          </p>
          <p className="logCreatedBy">
            {t("for")}:{" "}
            {log.created_for === tenantByPath.id
              ? "System"
              : `${userFor?.first_name || log.created_for} ${
                  userFor?.last_name || ""
                }`}
          </p>
          <p className="logCreatedBy">
            {t("by")}:{" "}
            {log.created_by === tenantByPath.id
              ? "System"
              : `${userBy?.first_name || ""} ${userBy?.last_name || ""}`}
          </p>
        </div>
      </div>
    </Container>
  );
};

export default LogItem;
