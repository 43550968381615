import { createContext, useContext } from "react";
import { toast } from "react-toastify";
import api from "../../services";
import { useSeller } from "../users";
import { IProviderProps } from "../tenant/tenant.interfaces";
import {
  ICreateCandidature,
  IUpdateCandidature,
} from "./candidatures.interface";
export const CandidaturesContext = createContext<any | null>(null);
export const CandidatureProvider = ({ children }: IProviderProps) => {
  const { token, getSeller, setApiLoading } = useSeller();

  const createCandidature = async (
    recruitment_id: string,
    data: ICreateCandidature
  ) => {
    setApiLoading(true);
    const dataFile = new FormData();
    dataFile.append("file", data.fileInput[0]);
    let created;
    setApiLoading(false);
    await api
      .post(
        "/candidatures/" + recruitment_id,
        {
          profissional_summary: data.profissional_summary,
          temperament_test: data.temperament_test,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(async (res) => {
        // @ts-ignore
        const key = await getS3PutUrl(dataFile.get("file"));

        if (key) {
          await addVideoOnCandidature(key, res.data.id);
          created = true;
        }
      })
      .catch((err) => {
        toast.error(err.response);
        console.log(err);
        created = false;
      });
    if (created) {
      setApiLoading(false);
      return true;
    }
    setApiLoading(false);
    return false;
  };
  const addAudioToCandidature = async (candidature_id: string, data: any) => {
    setApiLoading(true);
    const dataFile = new FormData();
    dataFile.append("file", data.fileInput[0]);
    delete data.fileInput;

    const added = await api
      .post(`/candidatures/${candidature_id}/audio`, dataFile, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setApiLoading(false);
        return true;
      })
      .catch((err) => {
        setApiLoading(false);
        toast.error(err.response.data.message);
        console.log(err.response.data.message);
        return false;
      });
    if (added) {
      return true;
    }
  };
  const updateCandidature = async (
    candidature_id: string,
    data: IUpdateCandidature
  ) => {
    setApiLoading(true);
    const updated = await api
      .patch(`/candidatures/${candidature_id}`, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setApiLoading(false);
        getSeller();
        return true;
      })
      .catch((err) => {
        setApiLoading(false);
        toast.error(err.response.data.message);
        console.log(err.response.data.message);
        return false;
      });
    if (updated) {
      return true;
    }
  };

  const addVideoOnCandidature = async (key: string, id: string) => {
    await api
      .post(
        `/candidatures/${id}/video`,
        { key },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        getSeller();

        window.localStorage.removeItem("SPP-Recruitment-ID");
      })
      .catch((err) => {
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
        api.delete("/candidatures/" + id, {
          headers: { Authorization: `Bearer ${token}` },
        });
      });
  };
  const getS3PutUrl = async (dataFile: any) => {
    const key = await api
      .post(
        `/upload`,
        { fileName: dataFile.name, contentType: dataFile.name.type },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(async (res) => {
        const keyResponse = await api
          .put(res.data.url, dataFile)
          .then((response) => {
            return res.data.key;
          })
          .catch((err) => {
            console.log(err);
            toast.error(err);
          });
        return keyResponse;
      })
      .catch((err) => {
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
      });
    return key;
  };
  return (
    <CandidaturesContext.Provider
      value={{ createCandidature, addAudioToCandidature, updateCandidature }}
    >
      {children}
    </CandidaturesContext.Provider>
  );
};

export const useCandidature = () => useContext(CandidaturesContext);
