import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AiOutlineLink } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { useCandidature } from "../../providers/candidatures";
import { ICandidatureFromSeller } from "../../providers/candidatures/candidatures.interface";
import { useSeller } from "../../providers/users";
import Loading from "../Loading";
import { Container } from "./styles";

interface IProps {
  setPageForm: React.Dispatch<React.SetStateAction<number>>;
  setValue: React.Dispatch<React.SetStateAction<number>>;
  candidature: ICandidatureFromSeller;
}

const SecondPhaseSalesForm = ({
  candidature,
  setPageForm,
  setValue,
}: IProps) => {
  const { apiLoading } = useSeller();
  const navigate = useNavigate();
  const [file, setFile] = useState<File | null>(null);
  const { addAudioToCandidature } = useCandidature();
  const { path } = useParams();
  const { t } = useTranslation();
  type FormValues = yup.InferType<typeof formSchema>;

  const onSubmit = async (data: FormValues) => {
    const added = await addAudioToCandidature(candidature.id, data);
    setFile(null);
    if (added) {
      setValue(100);
      setPageForm(2);
    }
  };

  const handleFileUpload = (e: any) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    setFile(file);
  };
  const formSchema = yup
    .object()
    .shape({
      fileInput: yup
        .mixed()
        .required(t("file_required"))
        .test("fileSize", t("file_size"), (value) => {
          return value && value[0].size <= 1000 * 1024 * 1024;
        })
        .test(
          "type",
          t("file_accept", {
            file_types: ".wav, .mp3, .mpeg, .aac, .ogg, 3gpp, .mp4",
          }),
          (value) => {
            return (
              value &&
              (value[0].type.includes("audio/") ||
                value[0].type.includes("video/"))
            );
          }
        ),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Button
          component="label"
          variant="outlined"
          startIcon={<AiOutlineLink />}
        >
          Upload Audio
          <input
            {...register("fileInput")}
            className="inputNone"
            type="file"
            accept="audio/mp3,audio/*"
            required
            onChange={handleFileUpload}
          />
        </Button>
        <p className="errosForm">
          {errors.fileInput && errors.fileInput.message?.toString()}
        </p>
        <Box>{file?.name}</Box>
        <p>{t("record_voice")} </p>
        {apiLoading ? (
          <Loading />
        ) : (
          <div className="buttons2Phase">
            <Button
              sx={{ ml: 1, px: 7 }}
              onClick={() => {
                navigate(`/${path}/candidate`);
              }}
              variant="outlined"
            >
              {t("back")}
            </Button>
            <Button type="submit" sx={{ ml: 1, px: 7 }} variant="contained">
              {t("submit")}
            </Button>
          </div>
        )}
      </form>
    </Container>
  );
};

export default SecondPhaseSalesForm;
