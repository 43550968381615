import ProductsList from "../../components/ProductsList";

const Products = () => {
  return (
    <>
      <ProductsList />
    </>
  );
};

export default Products;
