import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RiTeamLine } from "react-icons/ri";
import { useNavigate, useParams } from "react-router-dom";
import { useSalesTeam } from "../../providers/salesTeams/index";
import { ISalesTeam } from "../../providers/salesTeams/salesTeams.interface";
import { useSeller } from "../../providers/users";
import { IUserFromSalesTeam } from "../../providers/users/user.interface";
import ModalAddUserToSalesTeam from "../ModalAddUserToSalesTeam";
import TeamMemberItem from "../SalesTeamMemberItem";
import PageHeader from "../pageHeader";
import { Container } from "./styles";

const SalesTeamMembersList = () => {
  const [members, setMembers] = useState<IUserFromSalesTeam[] | null>(null);
  const [modalAddUserToSalesTeam, setModalAddUserToSalesTeam] = useState(false);
  const { roleAdmin } = useSeller();
  const [salesTeam, setSalesTeam] = useState<ISalesTeam | null>(null);
  const { allSalesTeams } = useSalesTeam();
  const { sales_team_id, path } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (allSalesTeams) {
      const salesTeamFromId = allSalesTeams.find(
        (e: ISalesTeam) => e.id === sales_team_id
      );
      if (salesTeamFromId) {
        setMembers(salesTeamFromId.sellers);
        setSalesTeam(salesTeamFromId);
      } else {
        navigate("/" + path + "/salesteams");
      }
    }
  }, [allSalesTeams, navigate, path, sales_team_id]);
  return (
    <>
      <Container>
        <PageHeader
          title={salesTeam?.name!}
          description={salesTeam?.description!}
          buttonContent={t("update")}
          buttonPath={`salesteams/${salesTeam?.id}/update`}
          verifyRole={true}
          roleToVerify={roleAdmin}
          icon={<RiTeamLine />}
        />

        <Button
          onClick={() => navigate("/" + path + "/salesTeams")}
          variant="outlined"
        >
          {t("back")}
        </Button>
        <div className="addUser">
          <Button
            variant="contained"
            onClick={() => setModalAddUserToSalesTeam(true)}
          >
            {t("add_member")}
          </Button>
        </div>
        {members && members.length === 0 ? (
          <div className="membersNone">
            <h4>{t("no_member_found")}</h4>
          </div>
        ) : (
          <div className="membersList">
            {members &&
              members.map((e) => <TeamMemberItem key={e.id} teamMember={e} />)}
          </div>
        )}
      </Container>

      {modalAddUserToSalesTeam && (
        <ModalAddUserToSalesTeam
          modalAddUserToSalesTeam={modalAddUserToSalesTeam}
          salesTeamId={sales_team_id!}
          setModalAddUserToSalesTeam={setModalAddUserToSalesTeam}
        />
      )}
    </>
  );
};

export default SalesTeamMembersList;
