import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { useRecruitment } from "../../providers/recruitments";
import { useSeller } from "../../providers/users";
import Loading from "../Loading";
import { Container } from "./styles";
import PageHeader from "../pageHeader";
import { IoCreateOutline } from "react-icons/io5";

interface IProps {
  setRecruitmentLink: React.Dispatch<React.SetStateAction<string>>;
  setCreated: React.Dispatch<React.SetStateAction<boolean>>;
}
const RecruitmentCreationForm = ({
  setRecruitmentLink,
  setCreated,
}: IProps) => {
  const { apiLoading } = useSeller();
  const { createRecruitment } = useRecruitment();
  const navigate = useNavigate();
  const { path } = useParams();
  const [language, setLanguage] = useState("english");
  const { t } = useTranslation();

  const handleChange = (event: any) => {
    setLanguage(event.target.value);
  };
  type FormValues = yup.InferType<typeof formSchema>;
  const onSubmit = async (data: FormValues) => {
    await createRecruitment(data, setCreated, setRecruitmentLink);
  };

  const formSchema = yup
    .object()
    .shape({
      name: yup.string().required(t("name_required")).max(128, t("name_max")),
      description: yup
        .string()
        .required(t("description_required"))
        .max(128, t("description_max")),
      language: yup
        .string()
        .required("Language é necessário para criar Recrutamento.")
        .max(128, "Language deve ter menos de 128 caractéres. "),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  return (
    <Container>
      <PageHeader
        title={t("new_recruitment")}
        description={`${t("fill_create")} ${t("recruitment")}`}
        icon={<IoCreateOutline />}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          error={errors.name === undefined ? false : true}
          helperText={errors.name?.message?.toString()}
          id="standard-name"
          label={t("name")}
          variant="standard"
          {...register("name")}
        />
        <TextField
          id="standard-description"
          error={errors.description === undefined ? false : true}
          helperText={errors.description?.message?.toString()}
          label={t("description")}
          variant="standard"
          {...register("description")}
        />
        <FormControl>
          <InputLabel sx={{ mt: 1 }} id="demo-simple-select-label">
            {t("language")}
          </InputLabel>
          <Select
            sx={{ mt: 1 }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={language}
            label={t("language")}
            {...register("language")}
            onChange={handleChange}
          >
            <MenuItem value={"english"}>{t("english")}</MenuItem>
            <MenuItem value={"portuguese"}>{t("portuguese")}</MenuItem>
          </Select>
        </FormControl>

        {apiLoading ? (
          <Loading />
        ) : (
          <div>
            <Button type="submit" variant="contained">
              {t("create")}
            </Button>
            <Button
              sx={{ ml: 1 }}
              onClick={() => navigate(`/${path}/recruitments`)}
              variant="outlined"
            >
              {t("cancel")}
            </Button>
          </div>
        )}
      </form>
    </Container>
  );
};

export default RecruitmentCreationForm;
