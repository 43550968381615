import { yupResolver } from "@hookform/resolvers/yup";
import { Button, FormControl, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useSeller } from "../../providers/users";
import Loading from "../Loading";
import { Container } from "./styles";

interface IPropsFunction {
  note: string;
}

interface IProps {
  setModalChangeNote: React.Dispatch<React.SetStateAction<boolean>>;
  defaultNote: string | undefined;
  item_id: string;
  isAdminView: boolean;
  functionToUpdate: (
    item_id: string,
    data: IPropsFunction,
    isAdminView: boolean
  ) => Promise<boolean>;
}

const ModalUpdateNote = ({
  setModalChangeNote,
  defaultNote,
  item_id,
  isAdminView,
  functionToUpdate,
}: IProps) => {
  const { apiLoading } = useSeller();
  const { t } = useTranslation();
  const formSchema = yup
    .object()
    .shape({
      note: yup
        .string()
        .max(256, t("note_length"))
        .required(t("note_required")),
    })
    .required();
  const { register, handleSubmit } = useForm({
    resolver: yupResolver(formSchema),
  });

  type FormValues = yup.InferType<typeof formSchema>;
  const onSubmit = async (data: FormValues) => {
    const updated = await functionToUpdate(item_id, data, isAdminView);
    if (updated) {
      setModalChangeNote(false);
    }
  };
  return (
    <Container>
      <div className="modalContainer">
        <span>
          <button
            className="exitModalPassword"
            onClick={() => {
              setModalChangeNote(false);
            }}
          >
            X
          </button>
        </span>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl>
            <TextField
              {...register("note")}
              multiline
              label={t("note")}
              defaultValue={defaultNote || ''}
              rows={2}
            />
          </FormControl>
          {apiLoading ? (
            <Loading />
          ) : (
            <Button sx={{ mt: 2 }} variant="contained" type="submit">
              {t("update")}
            </Button>
          )}
        </form>
      </div>
    </Container>
  );
};

export default ModalUpdateNote;
