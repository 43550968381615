import SalesTeamUpdateForm from "../../components/SalesTeamUpdateForm";

const SalesTeamUpdatePage = () => {
  return (
    <>
      <SalesTeamUpdateForm />
    </>
  );
};

export default SalesTeamUpdatePage;
