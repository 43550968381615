import styled from "styled-components";

export const Container = styled.section`
  margin-top: 15px;
  .settingsTitle {
    color: ${({ theme }) => theme.colors.primary};
    font-weight: bold;
    margin-top: 15px;
  }
`;
