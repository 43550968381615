import { useTranslation } from "react-i18next";
import { useSeller } from "../../providers/users";
import { Container } from "./styles";
import { ICandidatureFromSeller } from "../../providers/candidatures/candidatures.interface";

interface IProps {
  candidature: ICandidatureFromSeller;
}

const RejectedCandidature = ({ candidature }: IProps) => {
  const { seller } = useSeller();
  const { t } = useTranslation();
  return (
    <Container>
      <h3>
        {t("welcome")} {seller?.first_name} {seller?.last_name}!
      </h3>
      <p>
        {t("recruitment_process")}{" "}
        {candidature && candidature.createdAt.split("T")[0]}
      </p>
      <p>
        Status: <span className="statusCandidature">{t("rejected")}</span>
      </p>
      <p>{t("rejected_message")}</p>
      <p>{t("rejected_motivation")}</p>
      <p> {t("good_luck")}</p>
    </Container>
  );
};

export default RejectedCandidature;
