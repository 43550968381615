import styled from "styled-components";

export const Container = styled.section`
  width: 100%;
  .sellerLink {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
  }
  .sellerLink > p {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }
  .sellerLinkCopy {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
  }
  .sellerLinkCopy > p {
    width: 87%;
    display: flex;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.7) 0px 5px 15px;
    align-items: center;
    border-radius: 10px;
    font-size: 9px;

    background-color: ${({ theme }) => theme.colors.secondary};
  }
  .sellerLinkCopy > button {
    padding: 5px;
    border-radius: 9px;
    border: none;
    background-color: ${({ theme }) => theme.colors.primary};
  }
  .sellerLinkCopy > button > svg {
    width: 20px;
    height: 20px;
    color: white;
  }
  .sellerLink > span {
    width: 87%;
    display: flex;
    justify-content: center;
    font-size: 9px;
    font-weight: bold;
    font-style: capitalize;
    margin-top: 10px;
    color: ${({ theme }) => theme.colors.primary};
  }

  .sellerLinkButton {
    display: flex;
    justify-content: center;
    padding: 5px 0;
  }
  .sellerLinkButton > button {
    padding: 10px;
    width: 200px;
    border: none;
    border-radius: 9px;
    background-color: ${({ theme }) => theme.colors.primary};
    color: #fff;
    font-weight: 700;
    box-shadow: rgba(0, 0, 0, 0.7) 0px 5px 15px;
  }
  .sellerLinkButton > button:hover {
    background-color: ${({ theme }) => theme.colors.primaryHover};
  }
  @media (min-width: 550px) {
    .sellerLink {
      align-items: center;
    }
    .sellerLinkCopy > p {
      min-width: 95%;
      margin-right: 10px;
      font-size: 12px;
    }
  }
`;
