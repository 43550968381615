import styled from "styled-components";

export const Container = styled.header`
  display: flex;
  color: ${({ theme }) => theme.colors.white};
  flex-direction: column;
  gap: 8px;
  margin-top: 15px;
  .statusCandidature {
    color: red;
  }
`;
