import { Modal } from "@material-ui/core";
import { Container } from "./styles";
import ReactPlayer from "react-player";
interface IProps {
  handleClose: any;
  open: boolean;
  videoLink: string;
}
const ModalVideoPlayer = ({ open, handleClose, videoLink }: IProps) => {
  //   const style = {
  //     position: "absolute" as "absolute",
  //     top: "50%",
  //     left: "50%",
  //     transform: "translate(-50%, -50%)",
  //     width: 400,
  //     bgcolor: "background.paper",
  //     border: "2px solid #000",
  //     boxShadow: 24,
  //     p: 4,
  //   };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Container>
        <ReactPlayer url={videoLink} controls={true} className="react-player" />
      </Container>
    </Modal>
  );
};

export default ModalVideoPlayer;
