import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BsCart4 } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import { IPaymentMethod } from "../../providers/paymentMethods/paymentMethods.interface";
import { IProductComission } from "../../providers/productCommissions/productCommissions.interface";
import { useProduct } from "../../providers/products";
import { IProduct } from "../../providers/products/products.interface";
import { useSeller } from "../../providers/users";
import Loading from "../Loading";
import PaymentMethodItem from "../PaymentMethodItem";
import PageHeader from "../pageHeader";
import { Container } from "./styles";

const PaymentMethodsList = () => {
  const { seller, roleAdmin, apiLoading } = useSeller();
  const [paymentMethods, setPaymentMethods] = useState<IPaymentMethod[]>([]);
  const { allProducts } = useProduct();
  const { product_id, path } = useParams();
  const [product, setProduct] = useState<IProduct | null>(null);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (allProducts) {
      const productFromId = allProducts.find(
        (e: IProduct) => e.id === product_id
      );
      if (productFromId) {
        setPaymentMethods(productFromId.payment_methods);
        setProduct(productFromId);
      } else {
        navigate("/" + path + "/products");
      }
    }
  }, [allProducts, navigate, path, product_id]);

  const productCommission =
    seller &&
    product &&
    seller.productCommissions.find((e: IProductComission) =>
      e.productId.includes(product_id!)
    )?.percentage;
  return (
    <Container>
      {apiLoading ? (
        <Loading />
      ) : (
        <>
          <PageHeader
            title={product?.name!}
            description={product?.description!}
            buttonContent={t("create")}
            buttonPath={`products/${product?.id}/paymentmethods/create`}
            verifyRole={true}
            roleToVerify={roleAdmin}
            icon={<BsCart4 />}
          />
          <Button
            onClick={() => navigate("/" + path + "/products")}
            variant="outlined"
          >
            {t("back")}
          </Button>
          <p className="paymentCommission">
            {t("commission_product")}: {productCommission}%
          </p>
          {paymentMethods && paymentMethods.length === 0 ? (
            <div className="paymentMethodsList">
              <h4>{t("no-payment-found")}</h4>
            </div>
          ) : (
            <div className="paymentMethodsList">
              {paymentMethods &&
                paymentMethods.map((e) => (
                  <PaymentMethodItem
                    key={e.id}
                    productId={product_id!}
                    paymentMethod={e}
                  />
                ))}
            </div>
          )}
        </>
      )}
    </Container>
  );
};

export default PaymentMethodsList;
