import { createContext, useContext, useState } from "react";
import { toast } from "react-toastify";

import { useLocation, useNavigate } from "react-router-dom";
import api from "../../services";
import { useProduct } from "../products";
import { useSeller } from "../users";
import { IProviderProps } from "../tenant/tenant.interfaces";
import {
  ICreatePaymentMethod,
  IUpdatePaymentMethod,
} from "./paymentMethods.interface";
export const PaymentMethodsContext = createContext<any | null>(null);
export const PaymentMethodProvider = ({ children }: IProviderProps) => {
  const navigate = useNavigate();
  const { token, setApiLoading } = useSeller();
  const { getAllProducts } = useProduct();
  const location = useLocation();
  const path = location.pathname.split("/")[1];
  const [loadingLink, setLoadingLink] = useState(false);
  const createPaymentMethod = (
    product_id: string,
    data: ICreatePaymentMethod
  ) => {
    setApiLoading(true);
    api
      .post(`/paymentmethods/${product_id}`, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        getAllProducts();
        setApiLoading(false);
        navigate(`${path}/products/${product_id}/paymentmethods`);
      })
      .catch((err) => {
        setApiLoading(false);
        toast.error(err.response.data.message);
      });
  };

  const editPaymentMethod = (
    product_id: string,
    payment_method_id: string,
    data: IUpdatePaymentMethod
  ) => {
    setApiLoading(true);
    api
      .patch(`/paymentmethods/${payment_method_id}`, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        getAllProducts();
        setApiLoading(false);
        navigate(`${path}/products/${product_id}/paymentmethods`);
      })
      .catch((err) => {
        setApiLoading(false);
        toast.error(err.response.data.message);
      });
  };
  const deletePaymentMethod = (
    product_id: string,
    payment_method_id: string
  ) => {
    setApiLoading(true);
    api
      .delete(`/paymentmethods/${payment_method_id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        getAllProducts();
        setApiLoading(false);
        navigate(`${path}/products/${product_id}/paymentmethods`);
      })
      .catch((err) => {
        setApiLoading(false);
        toast.error(err.response.data.message);
      });
  };

  const getLinkPaymentMethod = async (payment_method_id: string) => {
    setLoadingLink(true);
    let link;
    await api
      .get(`/link/${payment_method_id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        link = res.data.link_shorts;
        return;
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
    setLoadingLink(false);
    if (link) {
      return link;
    }
  };
  return (
    <PaymentMethodsContext.Provider
      value={{
        createPaymentMethod,
        editPaymentMethod,
        loadingLink,
        getLinkPaymentMethod,
        deletePaymentMethod,
      }}
    >
      {children}
    </PaymentMethodsContext.Provider>
  );
};

export const usePaymentMethod = () => useContext(PaymentMethodsContext);
