import SalesContestationCreationForm from "../../components/SalesContestationCreationForm";

const SalesContestationCreation = () => {
  return (
    <>
      <SalesContestationCreationForm />
    </>
  );
};

export default SalesContestationCreation;
