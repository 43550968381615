import styled from "styled-components";

export const Container = styled.div`
  border-bottom: 1px solid lightgrey;
  min-height: 100vh;
  h2 {
    text-transform: capitalize;
    max-width: 70vw;
  }
  .candidaturePageBall {
    min-width: 35px;
    min-height: 35px;
    margin-right: 5px;
  }
  .candidaturesList {
    margin-top: 15px;
    width: 100%;
    display: flex;
    padding: 5px;
    gap: 15px;
    flex-direction: column;
  }
  .candidaturesNone {
    text-align: center;
  }
`;
