import styled from "styled-components";

export const Container = styled.div`
  margin-bottom: 15px;
  display: flex;
  text-transform: capitalize;
  overflow-x: auto;
  gap: 20px;
  @media (min-width: 1100px) {
    &::-webkit-scrollbar-track {
      padding: 1px 0;
      background-color: rgba(44, 48, 48, 0.1);
      opacity: 1;
      cursor: pointer;

      border-radius: 10px;
    }

    &::-webkit-scrollbar {
      width: 10px;
      height: 8px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
      background-color: rgba(90, 90, 90, 0.2);
      opacity: 0.2;
      /* border: 1px solid #000; */
    }
  }
`;
