import { useTranslation } from "react-i18next";
import { AiFillTrophy, AiOutlineInfoCircle } from "react-icons/ai";
import { useSeller } from "../../providers/users";
import PaginatedItems from "../Paginate";
import { RankingUserList } from "../RankingUserList";
import { Container } from "./styles";
import { ISellersFromTenant } from "../../providers/users/user.interface";

const Ranking = () => {
  const { allSellers } = useSeller();
  const { t } = useTranslation();
  return (
    <Container>
      <h2>
        <AiFillTrophy /> Ranking
        <div className="tool">
          <AiOutlineInfoCircle />
          <span className="tooltext">
            <p>
              1 {t("star")}:5 {t("sales")};
            </p>
            <p>
              2 {t("star")}:10 {t("sales")};
            </p>
            <p>
              3 {t("star")}:15 {t("sales")};
            </p>
            <p>
              4 {t("star")}:20 {t("sales")};
            </p>
            <p>
              5 {t("star")}:25 {t("sales")};
            </p>
          </span>
        </div>
      </h2>
      <div className="rankingContainer">
        <div className="rankingLabel">
          <p>{t("seller")}</p>
          <p>{t("sales")}</p>
        </div>

        {allSellers && (
          <PaginatedItems
            items={allSellers
              .filter((e: ISellersFromTenant) => e.inactive !== true)
              .sort(
                (a: ISellersFromTenant, b: ISellersFromTenant) =>
                  b.sales_count - a.sales_count
              )}
            itemsPerPage={10}
            Items={RankingUserList}
          />
        )}
      </div>
    </Container>
  );
};

export default Ranking;
