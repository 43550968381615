import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import { useState } from "react";
import "react-calendar/dist/Calendar.css";
import { useTranslation } from "react-i18next";
import { MdOutlinePersonSearch } from "react-icons/md";
import { useTenant } from "../../providers/tenant";
import { ILog } from "../../providers/tenant/tenant.interfaces";
import LogItem from "../LogsItem";
import PaginatedItems from "../Paginate";
import PageHeader from "../pageHeader";
import { Container } from "./styles";

interface IPropsPaginate {
  currentItems: ILog[];
}
type Value = any;
export const LogsList = () => {
  const { allLogs } = useTenant();
  const { t } = useTranslation();
  let weekAgo = new Date(new Date().setDate(new Date().getDate() - 7));
  const [datePicked, onChange] = useState<Value>([weekAgo, new Date()]);
  const [actionFilter, setActionFiltered] = useState("");

  const filteredLogs =
    allLogs && allLogs.length > 0 && datePicked
      ? allLogs.filter(
          (e: ILog) =>
            new Date(e.createdAt) < new Date(datePicked[1]) &&
            new Date(e.createdAt) > new Date(datePicked[0]) &&
            e.action.includes(actionFilter)
        )
      : [];
  const handleChange = (e: any) => {
    setActionFiltered(e.target.value);
  };

  return (
    <Container>
      <PageHeader
        title={t("logs")}
        description={t("logs")}
        icon={<MdOutlinePersonSearch />}
      />
      <div className="filterAndUpdateContainer"></div>
      <div className="datePickerContainer custom-select">
        <DateRangePicker
          className="datePicker"
          onChange={onChange}
          // @ts-nocheck
          value={datePicked}
        />
        <select id="select-filter" defaultValue={""} onChange={handleChange}>
          <option id="01" value="">
            {t("all")}
          </option>
          <option id="01" value="updateSaleStatus">
            {t("updateSaleStatus")}
          </option>
          <option id="02" value="updateProductCommission">
            {t("updateProductCommission")}
          </option>
        </select>
      </div>
      {allLogs && filteredLogs.length > 0 ? (
        <PaginatedItems
          items={filteredLogs}
          itemsPerPage={10}
          Items={({ currentItems }: IPropsPaginate) =>
            currentItems.map((e: ILog) => <LogItem log={e} key={e.id} />)
          }
        />
      ) : (
        <div className="logList">
          <h4>{t("no_log_found")}</h4>
        </div>
      )}
    </Container>
  );
};
