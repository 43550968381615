import TrainingsList from "../../components/TrainingList";

const Trainings = () => {
  return (
    <>
      <TrainingsList />
    </>
  );
};

export default Trainings;
