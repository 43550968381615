import { createContext, useContext, useState } from "react";
import { toast } from "react-toastify";
import api from "../../services";
import { useSeller } from "../users";
import { useLocation, useNavigate } from "react-router-dom";
import { IProviderProps } from "../tenant/tenant.interfaces";
import {
  ICreatePlaylist,
  ICreateTraining,
  IPlaylist,
  IUpdatePlaylist,
  IUpdateTraining,
} from "./playlistAndTrainings.interface";

export const PlaylistTrainingsContext = createContext<any | null>(null);
export const PlaylistTrainingProvider = ({ children }: IProviderProps) => {
  const [allPlaylists, setAllPlaylists] = useState<IPlaylist[] | null>(null);
  const navigate = useNavigate();
  const { token, setApiLoading, setPageMounting } = useSeller();
  const location = useLocation();
  const path = location.pathname.split("/")[1];

  const getAllPlaylists = () => {
    setPageMounting(true);
    api
      .get("/playlists", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setAllPlaylists(res.data);
        setPageMounting(false);
      })
      .catch((err) => {
        console.log(err.response.data.message);
        setPageMounting(false);
      });
  };

  const createPlaylist = (data: ICreatePlaylist) => {
    setApiLoading(true);
    api
      .post("/playlists", data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setApiLoading(false);
        getAllPlaylists();
        navigate(`/${path}/trainings`);
      })
      .catch((err) => {
        setApiLoading(false);
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
      });
  };

  const editPlaylist = (playlist_id: string, data: IUpdatePlaylist) => {
    setApiLoading(true);
    api
      .patch(`/playlists/${playlist_id}`, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setApiLoading(false);
        getAllPlaylists();
        navigate(`/${path}/trainings`);
      })
      .catch((err) => {
        setApiLoading(false);
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
      });
  };
  const deletePlaylist = (playlist_id: string) => {
    setApiLoading(true);
    api
      .delete(`/playlists/${playlist_id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setApiLoading(false);
        getAllPlaylists();
        navigate(`/${path}/trainings`);
      })
      .catch((err) => {
        setApiLoading(false);
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
      });
  };

  const createTraining = (data: ICreateTraining) => {
    setApiLoading(true);
    const dataFile = new FormData();
    dataFile.append("file", data.fileInput[0]);
    api
      .post(
        "/trainings",
        {
          name: data.name,
          description: data.description,
          language: data.language,
          video_url: data.video_url,
          playlists: data.playlists,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        api
          .post(`/trainings/${res.data.id}/file`, dataFile, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((res) => {
            setApiLoading(false);
            getAllPlaylists();
            navigate(`/${path}/trainings`);
          })
          .catch((err) => {
            setApiLoading(false);
            toast.error(err.response.data.message);
            console.log(err.response.data.message);
          });
      })
      .catch((err) => {
        setApiLoading(false);
        toast.error(err.response.data.message);
        console.log(err.response.data.message);
      });
  };
  const editTraining = (id: string, data: IUpdateTraining) => {
    setApiLoading(true);
    let dataFile: FormData;
    if (data.fileInput && data.fileInput.length !== 0) {
      dataFile = new FormData();
      dataFile.append("file", data.fileInput[0]);
    }
    delete data.fileInput;
    api
      .patch(`/trainings/${id}`, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (dataFile) {
          api
            .post(`/trainings/${id}/file`, dataFile, {
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
              setApiLoading(false);
              getAllPlaylists();
              navigate(`/${path}/trainings`);
            })
            .catch((err) => {
              setApiLoading(false);
              toast.error(err.response.data.message);
              console.log(err.response.data.message);
            });
        } else {
          getAllPlaylists();
          setApiLoading(false);
          navigate(`/${path}/trainings`);
        }
      })
      .catch((err) => {
        setApiLoading(false);
        toast.error(err.response.data.message);
        console.log(err.response.data.message);
      });
  };

  const deleteTraining = (id: string) => {
    setApiLoading(true);

    api
      .delete(`/trainings/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setApiLoading(false);
        getAllPlaylists();
        navigate(`/${path}/trainings`);
      })
      .catch((err) => {
        setApiLoading(false);
        toast.error(err.response.data.message);
        console.log(err.response.data.message);
      });
  };
  return (
    <PlaylistTrainingsContext.Provider
      value={{
        allPlaylists,
        setAllPlaylists,
        getAllPlaylists,
        deletePlaylist,
        createPlaylist,
        createTraining,
        editTraining,
        deleteTraining,
        editPlaylist,
      }}
    >
      {children}
    </PlaylistTrainingsContext.Provider>
  );
};

export const usePlaylistTraining = () => useContext(PlaylistTrainingsContext);
