import { yupResolver } from "@hookform/resolvers/yup";
import { Button, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { useSeller } from "../../providers/users";
import Loading from "../Loading";
import { Container } from "./styles";
import { useSalesTeam } from "../../providers/salesTeams";
import PageHeader from "../pageHeader";
import { IoCreateOutline } from "react-icons/io5";
const SalesTeamCreationForm = () => {
  const { apiLoading } = useSeller();
  const { createSalesTeam } = useSalesTeam();
  const navigate = useNavigate();
  const { path } = useParams();
  const { t } = useTranslation();
  type FormValues = yup.InferType<typeof formSchema>;
  const onSubmit = async (data: FormValues) => {
    await createSalesTeam(data);
  };

  const formSchema = yup
    .object()
    .shape({
      name: yup.string().required(t("name_required")).max(128, t("name_max")),
      description: yup
        .string()
        .required(t("description_required"))
        .max(128, t("description_max")),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  return (
    <Container>
      <PageHeader
        title={t("new_sales_team")}
        description={`${t("fill_create")} ${t("sales_team")}`}
        icon={<IoCreateOutline />}
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          error={errors.name === undefined ? false : true}
          helperText={errors.name?.message?.toString()}
          id="standard-name"
          label={t("name")}
          variant="standard"
          {...register("name")}
        />
        <TextField
          id="standard-description"
          error={errors.description === undefined ? false : true}
          helperText={errors.description?.message?.toString()}
          label={t("description")}
          variant="standard"
          {...register("description")}
        />

        {apiLoading ? (
          <Loading />
        ) : (
          <div>
            <Button type="submit" variant="contained">
              {t("create")}
            </Button>
            <Button
              sx={{ ml: 1 }}
              onClick={() => navigate(`/${path}/salesteams`)}
              variant="outlined"
            >
              {t("cancel")}
            </Button>
          </div>
        )}
      </form>
    </Container>
  );
};

export default SalesTeamCreationForm;
