import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSeller } from "../../providers/users";
import { useTenant } from "../../providers/tenant";
import Loading from "../Loading";
import { Container } from "./styles";
import { useRole } from "../../providers/roles";
import { IRole } from "../../providers/roles/roles.interface";
import { IUser } from "../../providers/users/user.interface";
interface IProps {
  setModalChangeRole: React.Dispatch<React.SetStateAction<boolean>>;
  ModalChangeRole: boolean;
  userToUpdateId: string;
}
const ModalChangeRole = ({
  setModalChangeRole,
  userToUpdateId,
  ModalChangeRole,
}: IProps) => {
  const [roles, setRoles] = useState<any>([]);
  const { apiLoading } = useSeller();
  const { adminUpdateRole } = useRole();
  const { getTenantUsers, allUsers, tenantRoles, getTenantRoles } = useTenant();
  const { t } = useTranslation();

  useEffect(() => {
    if (tenantRoles.length === 0 && !apiLoading) {
      getTenantRoles();
    }
    if (roles.length === 0) {
      const rolesToSet = allUsers
        .find((e: IUser) => e.id === userToUpdateId)
        .roles.map((e: IRole) => e.id);
      setRoles(rolesToSet);
    }
  }, [
    ModalChangeRole,
    allUsers,
    apiLoading,
    getTenantRoles,
    roles,
    tenantRoles.length,
    userToUpdateId,
  ]);

  const handleChangeRole = (event: any): any => {
    const {
      target: { value },
    } = event;

    setRoles(typeof value === "string" ? value.split(",") : value);
  };
  const { register, handleSubmit } = useForm();

  const onSubmit = async (data: any) => {
    data.user_id = userToUpdateId;
    const changed = await adminUpdateRole(setModalChangeRole, data);
    if (changed) {
      await getTenantUsers();
    }
  };
  return (
    <Container>
      <div className="modalContainer">
        <span>
          <button
            className="exitModalPassword"
            onClick={() => {
              setModalChangeRole(false);
            }}
          >
            X
          </button>
        </span>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl>
            <InputLabel sx={{ mt: 1 }} id="demo-simple-select-label">
              {t("role")}
            </InputLabel>
            <Select
              labelId="demo-simple-select-roles"
              id="demo-simple-select-roles"
              multiple
              value={roles}
              required
              label={t("roles")}
              {...register("roles")}
              onChange={handleChangeRole}
            >
              {tenantRoles &&
                tenantRoles.map((e: IRole) => (
                  <MenuItem key={e.id} value={e.id}>
                    {e.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          {apiLoading ? (
            <Loading />
          ) : (
            <Button sx={{ mt: 2 }} variant="contained" type="submit">
              {t("update")}
            </Button>
          )}
        </form>
      </div>
    </Container>
  );
};

export default ModalChangeRole;
