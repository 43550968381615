import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  FormHelperText,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { useRecruitment } from "../../providers/recruitments";
import { IRecruitment } from "../../providers/recruitments/recruitments.interface";
import { useSeller } from "../../providers/users";
import Loading from "../Loading";
import ModalConfirmDeletion from "../ModalConfirmDeletion/index";
import { Container } from "./styles";
import PageHeader from "../pageHeader";
import { IoCreateOutline } from "react-icons/io5";
const RecruitmentUpdateForm = () => {
  const { apiLoading } = useSeller();
  const { editRecruitment, allRecruitments, deleteRecruitment } =
    useRecruitment();
  const navigate = useNavigate();
  const [recruitment, setRecruitment] = useState<IRecruitment | null>(null);
  const { recruitment_id, path } = useParams();
  const [language, setLanguage] = useState("english");
  const [status, setStatus] = useState("");
  const { t } = useTranslation();
  const [modalConfirmDeletion, setModalConfirmDeletion] = useState(false);
  useEffect(() => {
    if (allRecruitments) {
      const recruitmentFromId = allRecruitments.find(
        (e: IRecruitment) => e.id === recruitment_id
      );
      if (recruitmentFromId) {
        setLanguage(recruitmentFromId.language);
        setRecruitment(recruitmentFromId);
        setStatus(recruitmentFromId.status);
      } else {
        navigate("/" + path + "/recruitments");
      }
    }
  }, [allRecruitments, navigate, path, recruitment_id]);

  const handleChange = (event: any) => {
    setLanguage(event.target.value);
  };
  const handleChangeStatus = (event: any) => {
    setStatus(event.target.value);
  };
  function removeEmptyFields(data: any) {
    Object.keys(data).forEach((key) => {
      if (data[key] === "" || data[key] == null) {
        delete data[key];
      }
    });
  }
  type FormValues = yup.InferType<typeof formSchema>;
  const onSubmit = async (data: FormValues) => {
    removeEmptyFields(data);

    await editRecruitment(recruitment_id, data);
  };

  const formSchema = yup
    .object()
    .shape({
      name: yup.string().max(128, t("name_max")),
      description: yup.string().max(128, t("description_max")),
      language: yup
        .string()
        .max(128, "Language deve ter menos de 128 caractéres. "),
      status: yup
        .string()
        .max(128, "Status deve ter menos de 128 caractéres. "),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  return (
    <>
      <Container>
        <PageHeader
          title={t("update_recruitment")}
          description={`${t("fill_update")} ${t("recruitment")}`}
          icon={<IoCreateOutline />}
        />

        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            error={errors.name === undefined ? false : true}
            helperText={errors.name?.message?.toString()}
            id="standard-name"
            label={t("name")}
            variant="standard"
            {...register("name")}
          />
          <TextField
            id="standard-description"
            error={errors.description === undefined ? false : true}
            helperText={errors.description?.message?.toString()}
            label={t("description")}
            variant="standard"
            {...register("description")}
          />
          <FormControl>
            <InputLabel sx={{ mt: 1 }} id="demo-simple-select-label">
              {t("language")}
            </InputLabel>
            <Select
              sx={{ mt: 1 }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={language}
              label={t("language")}
              {...register("language")}
              onChange={handleChange}
            >
              <MenuItem value={"english"}>{t("english")}</MenuItem>
              <MenuItem value={"portuguese"}>{t("portuguese")}</MenuItem>
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel sx={{ mt: 1 }} id="demo-simple-select-label-status">
              {t("status")}
            </InputLabel>
            <Select
              sx={{ mt: 1 }}
              labelId="demo-simple-select-label-status"
              id="demo-simple-select"
              value={status}
              label={t("status")}
              {...register("status")}
              onChange={handleChangeStatus}
            >
              <MenuItem value={"active"}>{t("active")}</MenuItem>
              <MenuItem value={"inactive"}>{t("inactive")}</MenuItem>
            </Select>
          </FormControl>
          <FormHelperText> {t("no_fill")}</FormHelperText>
          {apiLoading ? (
            <Loading />
          ) : (
            <div>
              <Button type="submit" variant="contained">
                {t("confirm")}
              </Button>
              <Button
                sx={{ ml: 1 }}
                onClick={() => navigate(`/${path}/recruitments`)}
                variant="outlined"
              >
                {t("cancel")}
              </Button>
              <Button
                sx={{ ml: 1 }}
                onClick={() => setModalConfirmDeletion(true)}
                variant="outlined"
                color="error"
              >
                {t("delete")}
              </Button>
            </div>
          )}
        </form>
      </Container>
      {modalConfirmDeletion && (
        <ModalConfirmDeletion
          setModalConfirmDeletion={setModalConfirmDeletion}
          deleteId={recruitment_id!}
          deleteFunction={deleteRecruitment}
          warningMessage={
            recruitment && recruitment.candidatures.length > 0
              ? t("recruitment_delete")
              : ""
          }
        />
      )}
    </>
  );
};

export default RecruitmentUpdateForm;
