import { yupResolver } from "@hookform/resolvers/yup";
import { Button, TextField, FormHelperText } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { useSalesTeam } from "../../providers/salesTeams";
import { ISalesTeam } from "../../providers/salesTeams/salesTeams.interface";
import { useSeller } from "../../providers/users";
import Loading from "../Loading";
import ModalConfirmDeletion from "../ModalConfirmDeletion/index";
import { Container } from "./styles";
import PageHeader from "../pageHeader";
import { IoCreateOutline } from "react-icons/io5";
const SalesTeamUpdateForm = () => {
  const { apiLoading } = useSeller();
  const { editSalesTeam, allSalesTeams, deleteSalesTeam } = useSalesTeam();
  const navigate = useNavigate();
  const [salesTeam, setSalesTeam] = useState<ISalesTeam | null>(null);
  const { sales_team_id, path } = useParams();

  const { t } = useTranslation();
  const [modalConfirmDeletion, setModalConfirmDeletion] = useState(false);
  useEffect(() => {
    if (allSalesTeams) {
      const salesTeamFromId = allSalesTeams.find(
        (e: ISalesTeam) => e.id === sales_team_id
      );
      if (salesTeamFromId) {
        setSalesTeam(salesTeamFromId);
      } else {
        navigate("/" + path + "/salesTeams");
      }
    }
  }, [allSalesTeams, navigate, path, sales_team_id]);

  function removeEmptyFields(data: any) {
    Object.keys(data).forEach((key) => {
      if (data[key] === "" || data[key] == null) {
        delete data[key];
      }
    });
  }
  type FormValues = yup.InferType<typeof formSchema>;

  const onSubmit = async (data: FormValues) => {
    removeEmptyFields(data);

    await editSalesTeam(sales_team_id, data);
  };

  const formSchema = yup
    .object()
    .shape({
      name: yup.string().max(128, t("name_max")),
      description: yup.string().max(128, t("description_max")),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  return (
    <>
      <Container>
        <PageHeader
          title={t("update_sales_team")}
          description={`${t("fill_update")} ${t("sales_team")}`}
          icon={<IoCreateOutline />}
        />

        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            error={errors.name === undefined ? false : true}
            helperText={errors.name?.message?.toString()}
            id="standard-name"
            label={t("name")}
            variant="standard"
            {...register("name")}
          />
          <TextField
            id="standard-description"
            error={errors.description === undefined ? false : true}
            helperText={errors.description?.message?.toString()}
            label={t("description")}
            variant="standard"
            {...register("description")}
          />
          <FormHelperText> {t("no_fill")}</FormHelperText>
          {apiLoading ? (
            <Loading />
          ) : (
            <div>
              <Button type="submit" variant="contained">
                {t("confirm")}
              </Button>
              <Button
                sx={{ ml: 1 }}
                onClick={() => navigate(`/${path}/salesTeams`)}
                variant="outlined"
              >
                {t("cancel")}
              </Button>
              <Button
                sx={{ ml: 1 }}
                onClick={() => setModalConfirmDeletion(true)}
                variant="outlined"
                color="error"
              >
                {t("delete")}
              </Button>
            </div>
          )}
        </form>
      </Container>
      {modalConfirmDeletion && (
        <ModalConfirmDeletion
          setModalConfirmDeletion={setModalConfirmDeletion}
          deleteId={sales_team_id!}
          deleteFunction={deleteSalesTeam}
          warningMessage={
            salesTeam && salesTeam.sellers.length > 0
              ? t("sales_team_delete")
              : ""
          }
        />
      )}
    </>
  );
};

export default SalesTeamUpdateForm;
