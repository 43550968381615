import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;

  .modalContainer {
    width: 90%;
    max-width: 400px;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.colors.secondary};
  }
  .modalContainer > span {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    padding: 12px;
    height: 12px;
    margin-bottom: 10px;
  }
  .modalContainer > span > p {
    font-size: 18px;
  }
  .modalContainer > form {
    display: flex;
    justify-content: center;
    padding: 15px;
    flex-direction: column;
  }
  .modalContainer > span > button {
    background-color: ${({ theme }) => theme.colors.secondary};
    color: ${({ theme }) => theme.colors.gray};
    border: none;
    border-radius: 5px;
  }
  .modalBody {
    padding: 10px;
  }
  .fileDiv {
    display: flex;
    gap: 2px;
    text-align: center;
    padding: 2px;
  }
  .fileDiv > p {
    text-decoration: capitalize;
    color: ${({ theme }) => theme.colors.gray};
    font-weight: bold;
  }
  .fileDiv > a {
    color: ${({ theme }) => theme.colors.primary};
  }
`;
