import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTenant } from "../../providers/tenant";

import { InvoiceContainer } from "../../components/InvoicesContainer";
import { useSeller } from "../../providers/users";
import Loading from "../../components/Loading";

const AdminInvoicesPage = () => {
  const { allUsers } = useTenant();
  const navigate = useNavigate();
  const { path, user_id } = useParams();
  const { apiLoading } = useSeller();
  useEffect(() => {
    if (allUsers && !allUsers.find((e: any) => e.id === user_id)) {
      navigate(`/${path}/dashboard`);
    }
  }, [allUsers, navigate, path, user_id]);
  const user = allUsers ? allUsers.find((e: any) => e.id === user_id) : null;

  return (
    <>
      {apiLoading ? (
        <Loading />
      ) : (
        user && <InvoiceContainer invoices={user.invoices} isAdminView={true} />
      )}
    </>
  );
};

export default AdminInvoicesPage;
