import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Input from "../Input";
import { Container } from "./styles";
import * as yup from "yup";
import { useSeller } from "../../providers/users";
import { useTranslation } from "react-i18next";

interface IPropps {
  setModalChangePassword: React.Dispatch<React.SetStateAction<boolean>>;
}
const ModalChangePassword = ({ setModalChangePassword }: IPropps) => {
  const { updatePassword } = useSeller();
  const { t } = useTranslation();

  const formSchema = yup
    .object()
    .shape({
      old_password: yup.string().required(t("password_required")),
      password: yup
        .string()
        .required(t("password_required"))
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#/$%/^&/*])(?=.{8,})/,
          t("password_matches")
        ),
      confirm: yup
        .string()
        .required(t("confirm_required"))
        .oneOf([yup.ref("password"), null], t("confirm_mathces")),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });
  type FormValues = yup.InferType<typeof formSchema>;
  const onSubmit = (data: FormValues) => {
    updatePassword(data);
    setModalChangePassword(false);
  };

  return (
    <Container>
      <div className="modalContainer">
        <span>
          <button
            className="exitModalPassword"
            onClick={() => {
              setModalChangePassword(false);
            }}
          >
            X
          </button>
        </span>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            label={t("old_password")}
            register={register}
            name="old_password"
            error={errors.old_password?.message}
            type="password"
          />
          <Input
            label={t("password")}
            register={register}
            name="password"
            error={errors.password?.message}
            type="password"
          />
          <Input
            label={t("confirm_password")}
            register={register}
            name="confirm"
            error={errors.confirm?.message}
            type="password"
          />

          <button type="submit">{t("update")}</button>
        </form>
      </div>
    </Container>
  );
};

export default ModalChangePassword;
