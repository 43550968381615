import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;

  .modalContainer {
    width: 90%;
    max-width: 400px;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.colors.secondary};
  }
  .modalContainer > span {
    display: flex;
    justify-content: flex-end;
    width: 90%;
    margin: 10px auto;
    height: 12px;
  }
  .exitModalDelete {
    background: none;
    border: none;
  }

  .modalContainer > p {
    display: flex;
    justify-content: center;
    padding: 15px;
    font-weight: 600;
    flex-direction: column;
    text-align: center;
  }

  .disableButtonsDiv {
    display: flex;
    justify-content: center;
  }
`;
