import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/logo-removebg-preview.svg";
import FindTenantForm from "../../components/FindTenantForm";
import { useSeller } from "../../providers/users";
import { Container } from "./styles";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const FindTenant = () => {
  const { isLogged, isActive, seller, verifyRolePermission, roleRecruit } =
    useSeller();
  const navigate = useNavigate();
  const query = useQuery();
  const { i18n } = useTranslation();
  useEffect(() => {
    const language = query.get("language");
    if (language) {
      i18n.changeLanguage(language === "pt" ? "pt" : "en");
    }
    let pathToRedirect = window.localStorage.getItem("SPP-Session-Path");

    if (isLogged && isActive === true) {
      if (seller && verifyRolePermission(roleRecruit)) {
        const recruitment_id =
          window.localStorage.getItem("SPP-Recruitment-ID") || "";
        if (recruitment_id) {
          return navigate(
            "/" +
              pathToRedirect +
              "/recruitments/" +
              window.localStorage.getItem("SPP-Recruitment-ID") +
              "/candidate"
          );
        }

        navigate("/" + pathToRedirect + "/candidate");
      } else {
        window.localStorage.removeItem("SPP-Recruitment-ID");
        navigate(`/${pathToRedirect}/dashboard`);
      }
    }
  });

  return (
    <Container>
      <header>
        <img src={logo} alt="SPP Logo" className="tenantPageLogo" />
      </header>{" "}
      <FindTenantForm />{" "}
    </Container>
  );
};

export default FindTenant;
