import { Button, Slider } from "@mui/material";
import { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import logo from "../../assets/logo-removebg-preview.svg";
import SecondPhaseSalesForm from "../../components/2PhaseSalesForm";
import { useSeller } from "../../providers/users";
import { Container } from "./styles";
import { useTranslation } from "react-i18next";
import { ICandidatureFromSeller } from "../../providers/candidatures/candidatures.interface";

const SalesTestPage = () => {
  const { seller, getSeller } = useSeller();
  const navigate = useNavigate();
  const { path, candidature_id } = useParams();
  const [pageForm, setPageForm] = useState(0);
  const [candidature, setCandidature] = useState<ICandidatureFromSeller | null>(
    null
  );
  const [value, setValue] = useState(50);
  const { t } = useTranslation();

  useEffect(() => {
    const candidatureTest = seller.candidatures.filter(
      (e: ICandidatureFromSeller) => e.id === candidature_id
    )[0];

    if (!candidatureTest) {
      navigate(`/${path}/candidate`);
      return;
    }
    if (candidatureTest.recruitment_phase !== "approved_1_phase_test") {
      navigate(`/${path}/candidate`);
    } else {
      setCandidature(candidatureTest);
      setPageForm(1);
    }
  }, [seller]);

  return (
    <Container>
      <header>
        <img src={logo} alt="SPP Logo" className="loginLogo" />
      </header>
      <main>
        {pageForm === 1 ? (
          <>
            <div className="formHeader">
              <p>
                {t("follow_steps")} {candidature?.recruitment?.name}.
              </p>
              <p>{t("be_sure")} </p>
              <Slider
                value={value}
                disableSwap={true}
                sx={{ maxWidth: 150 }}
                aria-label="Default"
              />
            </div>
            <SecondPhaseSalesForm
              setPageForm={setPageForm}
              candidature={candidature!}
              setValue={setValue}
            />
          </>
        ) : pageForm === 2 ? (
          <>
            <div className="formHeader">
              <Slider
                value={value}
                disableSwap={true}
                sx={{ maxWidth: 150 }}
                aria-label="Default"
                // valueLabelDisplay="auto"
              />
              <h4>{t("sales_test_submitted")}</h4>
              <p>{t("results_update")}</p>
            </div>
            <Button
              variant="contained"
              sx={{ mt: 3 }}
              onClick={async () => {
                await getSeller();
                navigate(`/${path}/candidate`);
              }}
            >
              {t("go_back_main_page")}
            </Button>
          </>
        ) : (
          <div>
            <h3>Loading</h3>
          </div>
        )}
      </main>
    </Container>
  );
};

export default SalesTestPage;
