import ProductCreationForm from "../../components/ProductCreationForm";

const ProductCreation = () => {
  return (
    <>
      <ProductCreationForm />
    </>
  );
};

export default ProductCreation;
