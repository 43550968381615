import styled, { keyframes } from "styled-components";
import { fadeIn } from "react-animations";
const fadeAnimation = keyframes`${fadeIn}`;

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  min-height: 100vh;
  padding: 1px;
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  flex-direction: column;
  align-items: center;
  header {
    text-align: center;
  }
  .loginLogo {
    width: 220px;
    height: 120px;
  }
  .formContainer {
    animation: ${fadeAnimation} 1s;
    width: 95%;
    margin: 50px auto;
    border-radius: 5px;
    display: flex;
    flex-direction: column;

    background-color: ${({ theme }) => theme.colors.secondary};
  }

  .buttonSellected {
    border-bottom: 1px solid ${({ theme }) => theme.colors.primary} !important;
    color: ${({ theme }) => theme.colors.primary} !important;
  }
  .formSwitch > button {
    border-radius: 5px 5px 0 0;
    width: 50%;
    border: none;
    border-bottom: 1px solid gray;
    background-color: ${({ theme }) => theme.colors.secondary};
    padding: 12px;
    margin-left: 1px;
    font-weight: 800;
    color: ${({ theme }) => theme.colors.black};
    font-size: 17px;
  }
  .formSwitch > button:hover {
    border-bottom: 1px solid ${({ theme }) => theme.colors.primary} !important;
    color: ${({ theme }) => theme.colors.primary};
  }
  @media (min-width: 500px) {
    .formContainer {
      max-width: 500px;
    }
  }
  @media (min-width: 1100px) {
    max-width: 80vw;
    padding: 0px 200px;
    margin: 0 auto;
    width: 100%;
  }
`;
