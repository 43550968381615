import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AiOutlineLink } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { usePlaylistTraining } from "../../providers/playlistsTrainings";
import {
  IPlaylist,
  ITraining,
} from "../../providers/playlistsTrainings/playlistAndTrainings.interface";
import { useSeller } from "../../providers/users";
import Loading from "../Loading";
import ModalConfirmDeletion from "../ModalConfirmDeletion";
import { Container } from "./styles";
import PageHeader from "../pageHeader";
import { IoCreateOutline } from "react-icons/io5";

const TrainingUpdateForm = () => {
  const { apiLoading } = useSeller();
  const navigate = useNavigate();
  const [language, setLanguage] = useState("english");
  const [file, setFile] = useState<File | null>(null);
  const { path, training_id } = useParams();
  const { allPlaylists, editTraining, deleteTraining } = usePlaylistTraining();
  const { t } = useTranslation();
  const [modalConfirmDeletion, setModalConfirmDeletion] = useState(false);
  const [training, setTraining] = useState<ITraining | null>(null);
  const [playlists, setPlaylists] = useState<string[]>([]);
  useEffect(() => {
    if (allPlaylists) {
      const playlistFromId = allPlaylists.find((e: IPlaylist) =>
        e.trainings.find((j: ITraining) => j.id === training_id)
      );
      if (playlistFromId) {
        const trainingFromId = playlistFromId.trainings.find(
          (e: ITraining) => e.id === training_id
        );
        if (!trainingFromId) {
          navigate("/" + path + "/trainings");
        }
        setTraining(trainingFromId);
        setLanguage(trainingFromId.language);
        setPlaylists(trainingFromId.playlists);
      } else {
        navigate("/" + path + "/trainings");
      }
    }
  }, [allPlaylists, navigate, path, training_id]);

  const handleChange = (event: any) => {
    setLanguage(event.target.value);
    setPlaylists([]);
  };
  function removeEmptyFields(data: any) {
    Object.keys(data).forEach((key) => {
      if (data[key] === "" || data[key] == null) {
        delete data[key];
      }
    });
  }

  type FormValues = yup.InferType<typeof formSchema>;
  const onSubmit = async (data: FormValues) => {
    setFile(null);
    removeEmptyFields(data);

    data.playlists = playlists.filter(
      (e: string) => e && allPlaylists.find((p: IPlaylist) => p.id === e)
    );
    await editTraining(training_id, data);
  };
  const handleChangePlaylists = (event: any) => {
    const {
      target: { value },
    } = event;

    setPlaylists(typeof value === "string" ? value.split(",") : value);
  };
  const handleFileUpload = (e: any) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    setFile(file);
  };

  const formSchema = yup
    .object()
    .shape({
      name: yup.string().max(128, t("name_max")),
      description: yup.string().max(128, t("description_max")),
      video_url: yup.string().url(t("video_url_type")),
      fileInput: yup
        .mixed()
        .notRequired()
        .nullable()
        .test("fileSize", t("file_size"), (value) => {
          return (
            value.length === 0 || (value && value[0].size <= 1000 * 1024 * 1024)
          );
        })

        .test(
          "type",
          t("file_accept", { file_types: ".jpeg, .jpg, .gif, .png" }),
          (value) => {
            return (
              value.length === 0 ||
              value[0].type === "image/jpg" ||
              value[0].type === "image/jpeg" ||
              value[0].type === "image/gif" ||
              value[0].type === "image/png"
            );
          }
        )
        .notRequired()
        .nullable(),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  return (
    <>
      <Container>
        <PageHeader
          title={t("update_training")}
          description={`${t("fill_update")} ${t("training")}`}
          icon={<IoCreateOutline />}
        />

        {training && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              error={errors.name === undefined ? false : true}
              helperText={errors.name?.message?.toString()}
              id="standard-name"
              label={t("name")}
              defaultValue={training.name}
              variant="standard"
              {...register("name")}
            />
            <TextField
              id="standard-description"
              error={errors.description === undefined ? false : true}
              helperText={errors.description?.message?.toString()}
              label={t("description")}
              defaultValue={training.description}
              variant="standard"
              {...register("description")}
            />
            <TextField
              id="standard-video_url"
              error={errors.video_url === undefined ? false : true}
              helperText={errors.video_url?.message?.toString()}
              label={t("video_url")}
              defaultValue={training.video_url}
              variant="standard"
              {...register("video_url")}
            />
            <Button
              component="label"
              variant="outlined"
              startIcon={<AiOutlineLink />}
            >
              Upload Thumbnail
              <input
                {...register("fileInput")}
                className="inputNone"
                type="file"
                accept=".png, .jpg, .jpeg"
                onChange={handleFileUpload}
              />
            </Button>
            <p className="errosForm">
              {errors.fileInput && errors.fileInput.message?.toString()}
            </p>
            <Box>{file?.name}</Box>

            <FormControl>
              <InputLabel id="demo-simple-select-label">
                {t("language")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={language}
                label={t("language")}
                {...register("language")}
                onChange={handleChange}
              >
                <MenuItem value={"english"}>{t("english")}</MenuItem>
                <MenuItem value={"portuguese"}>{t("portuguese")}</MenuItem>
              </Select>
            </FormControl>

            <FormControl>
              <InputLabel id="demo-simple-select-playlists">
                {t("playlists")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-playlists"
                id="demo-simple-select-playlists"
                multiple
                value={playlists}
                required
                label={t("playlists")}
                {...register("playlists")}
                onChange={handleChangePlaylists}
              >
                {allPlaylists &&
                  allPlaylists
                    .filter(
                      (e: IPlaylist) =>
                        e.language === language && e.id !== "no-playlist"
                    )
                    .map((e: IPlaylist) => (
                      <MenuItem key={e.id} value={e.id}>
                        {e.name}
                      </MenuItem>
                    ))}
              </Select>
              <FormHelperText>{t("playlists_message")}</FormHelperText>
            </FormControl>

            {apiLoading ? (
              <Loading />
            ) : (
              <div>
                <Button type="submit" variant="contained">
                  {t("confirm")}
                </Button>
                <Button
                  sx={{ ml: 1 }}
                  onClick={() => navigate("/" + path + "/trainings")}
                  variant="outlined"
                >
                  {t("cancel")}
                </Button>
                <Button
                  sx={{ ml: 1 }}
                  onClick={() => setModalConfirmDeletion(true)}
                  variant="outlined"
                  color="error"
                >
                  {t("delete")}
                </Button>
              </div>
            )}
          </form>
        )}
      </Container>
      {modalConfirmDeletion && (
        <ModalConfirmDeletion
          setModalConfirmDeletion={setModalConfirmDeletion}
          deleteId={training_id!}
          deleteFunction={deleteTraining}
        />
      )}
    </>
  );
};

export default TrainingUpdateForm;
