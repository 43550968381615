import { useTranslation } from "react-i18next";
import { RiTeamLine } from "react-icons/ri";
import { useSalesTeam } from "../../providers/salesTeams";
import { ISalesTeam } from "../../providers/salesTeams/salesTeams.interface";
import { useSeller } from "../../providers/users";
import SalesTeamItem from "../SalesTeamItem";
import PageHeader from "../pageHeader";
import { Container } from "./styles";
const SalesTeamsList = () => {
  const { roleAdmin } = useSeller();
  const { allSalesTeams } = useSalesTeam();

  const { t } = useTranslation();
  return (
    <Container>
      <PageHeader
        title={t("sales_teams")}
        description={t("sales_teams")}
        buttonContent={t("create")}
        buttonPath="salesteams/create"
        verifyRole={true}
        roleToVerify={roleAdmin}
        icon={<RiTeamLine />}
      />
      {allSalesTeams && allSalesTeams.length > 0 ? (
        <div className="salesTeamList">
          {allSalesTeams &&
            allSalesTeams.map((e: ISalesTeam) => (
              <SalesTeamItem salesTeam={e} key={e.id} />
            ))}
        </div>
      ) : (
        <div className="salesTeamList">
          <h4>{t("no_sales_team_found")}</h4>
        </div>
      )}
    </Container>
  );
};

export default SalesTeamsList;
