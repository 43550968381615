import { createContext, useContext, useState } from "react";
import { toast } from "react-toastify";

import api from "../../services";
import { useSeller } from "../users";
import { useLocation, useNavigate } from "react-router-dom";
import { IProviderProps } from "../tenant/tenant.interfaces";
import { ICreateProduct, IProduct, IUpdateProduct } from "./products.interface";
import { useTenant } from "../tenant";

export const ProductsContext = createContext<any | null>(null);

export const ProductProvider = ({ children }: IProviderProps) => {
  const [allProducts, setAllProducts] = useState<IProduct[] | null>(null);
  const navigate = useNavigate();
  const { token, getSeller, setApiLoading, setPageMounting } = useSeller();
  const { allUsers, getTenantUsers } = useTenant();
  const location = useLocation();
  const path = location.pathname.split("/")[1];
  const getAllProducts = () => {
    setPageMounting(true);
    api
      .get("/products", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setAllProducts(res.data);
        setPageMounting(false);
      })
      .catch((err) => {
        setPageMounting(false);
        console.log(err.response.data.message);
      });
  };
  const createProduct = async (data: ICreateProduct) => {
    setApiLoading(true);
    const dataFile = new FormData();
    dataFile.append("file", data.fileInput[0]);
    await api
      .post(
        "/products",
        {
          name: data.name,
          description: data.description,
          language: data.language,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        api
          .post(`/products/${res.data.id}/file`, dataFile, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((res) => {
            setApiLoading(false);
            getAllProducts();
            getSeller();
            if (allUsers) {
              getTenantUsers();
            }
            navigate(`/${path}/products`);
          })
          .catch((err) => {
            setApiLoading(false);
            console.log(err.response.data.message);
          });
      })
      .catch((err) => {
        setApiLoading(false);
        console.log(err.response.data.message);
      });
  };
  const editProduct = async (id: string, data: IUpdateProduct) => {
    setApiLoading(true);
    let dataFile: FormData;
    if (data.fileInput && data.fileInput.length !== 0) {
      dataFile = new FormData();
      dataFile.append("file", data.fileInput[0]);
    }
    delete data.fileInput;
    await api
      .patch(`/products/${id}`, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (dataFile) {
          api
            .post(`/products/${id}/file`, dataFile, {
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
              setApiLoading(false);
              getAllProducts();
              if (allUsers) {
                getTenantUsers();
              }
              navigate(`/${path}/products`);
            })
            .catch((err) => {
              setApiLoading(false);
              toast.error(err.response.data.message);
              console.log(err.response.data.message);
            });
        } else {
          getAllProducts();
          setApiLoading(false);
          if (allUsers) {
            getTenantUsers();
          }
          navigate(`/${path}/products`);
        }
      })
      .catch((err) => {
        setApiLoading(false);
        toast.error(err.response.data.message);
        console.log(err.response.data.message);
      });
  };

  const deleteProduct = async (id: string) => {
    setApiLoading(true);
    await api
      .delete(`/products/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setApiLoading(false);
        getAllProducts();
        if (allUsers) {
          getTenantUsers();
        }
        navigate(`/${path}/products`);
      })
      .catch((err) => {
        setApiLoading(false);
        toast.error(err.response.data.message);
        console.log(err.response.data.message);
      });
  };
  return (
    <ProductsContext.Provider
      value={{
        allProducts,
        setAllProducts,
        createProduct,
        editProduct,
        deleteProduct,
        getAllProducts,
      }}
    >
      {children}
    </ProductsContext.Provider>
  );
};

export const useProduct = () => useContext(ProductsContext);
