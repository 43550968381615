import { createContext, useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import api from "../../services";
import { useSeller } from "../users";
import { IProviderProps } from "../tenant/tenant.interfaces";
import {
  IAdminUpdateCandidature,
  ICreateRecruitment,
  IRecruitment,
  IUpdateRecruitment,
} from "./recruitments.interface";
export const RecruitmentsContext = createContext<any | null>(null);
export const RecruitmentProvider = ({ children }: IProviderProps) => {
  const [allRecruitments, setAllRecruitments] = useState<IRecruitment[] | null>(
    null
  );
  const [oneRecruitment, setOneRecruitment] = useState<IRecruitment | null>(
    null
  );
  const navigate = useNavigate();
  const { token, setApiLoading, setPageMounting } = useSeller();
  const location = useLocation();
  const path = location.pathname.split("/")[1];

  const getAllRecruitments = () => {
    setPageMounting(true);
    api
      .get("/recruitments", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setAllRecruitments(res.data);
        setPageMounting(false);
      })
      .catch((err) => {
        console.log(err.response.data.message);
        setPageMounting(false);
      });
  };
  const getOneRecruitmentRecruit = async (id: string) => {
    setApiLoading(true);
    const exists = await api
      .get("/recruitments/" + id, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setApiLoading(false);
        if (res.data.status === "inactive") {
          navigate(`/${path}/candidate`);
        }
        setOneRecruitment(res.data);
        return true;
      })
      .catch((err) => {
        console.log(err.response.data.message);
        setApiLoading(false);
        return false;
      });
    if (exists) {
      return true;
    }
  };
  const createRecruitment = async (
    data: ICreateRecruitment,
    setCreated: React.Dispatch<React.SetStateAction<boolean>>,
    setRecruitmentLink: React.Dispatch<React.SetStateAction<string>>
  ) => {
    setApiLoading(true);

    await api
      .post("/recruitments", data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setApiLoading(false);
        res.data.createdAt = new Date(res.data.createdAt).toISOString()
        res.data.updatedAt = new Date(res.data.updatedAt).toISOString()
        setAllRecruitments([...allRecruitments!, res.data]);
        setCreated(true);
        setRecruitmentLink(
          `${process.env.REACT_APP_FRONTEND_URL}/${path}/recruitments/${res.data.id}/candidate`
        );

        return res.data;
      })
      .catch((err) => {
        setApiLoading(false);
        console.log(err.response.data.message);
      });
  };
  const editRecruitment = (id: string, data: IUpdateRecruitment) => {
    setApiLoading(true);

    api
      .patch(`/recruitments/${id}`, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        getAllRecruitments();
        setApiLoading(false);
        navigate(`/${path}/recruitments`);
      })
      .catch((err) => {
        setApiLoading(false);
        console.log(err.response.data.message);
      });
  };
  const deleteRecruitment = (id: string) => {
    setApiLoading(true);

    api
      .delete(`/recruitments/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        getAllRecruitments();
        setApiLoading(false);
        navigate(`/${path}/recruitments`);
      })
      .catch((err) => {
        setApiLoading(false);
        console.log(err.response.data.message);
      });
  };

  const adminUpdateCandidature = (
    candidature_id: string,
    setModalChangePhase: React.Dispatch<React.SetStateAction<boolean>>,
    data: IAdminUpdateCandidature
  ) => {
    setApiLoading(true);

    api
      .patch(`/candidatures/admin/${candidature_id}`, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        getAllRecruitments();
        setApiLoading(false);
        setModalChangePhase(false);
      })
      .catch((err) => {
        setApiLoading(false);
        console.log(err.response.data.message);
      });
  };
  return (
    <RecruitmentsContext.Provider
      value={{
        allRecruitments,
        setAllRecruitments,
        createRecruitment,
        editRecruitment,
        deleteRecruitment,
        getOneRecruitmentRecruit,
        oneRecruitment,
        adminUpdateCandidature,
        getAllRecruitments,
      }}
    >
      {children}
    </RecruitmentsContext.Provider>
  );
};

export const useRecruitment = () => useContext(RecruitmentsContext);
