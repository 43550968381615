import styled from "styled-components";

interface IProps {
  apiLoading: number;
}

export const Container: any = styled.div<IProps>`
  margin-bottom: 15px;
  .paymentMethodContainer {
    padding: 15px;
    display: flex;
    font-size: 0.75rem;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    width: 250px;
    height: 150px;
    background-color: ${({ theme }) => theme.colors.secondary};
    border-radius: 5px;
    max-height: 250px;
  }
  .paymentMethodName {
    font-weight: bold;
  }
  .paymentMethodPrice {
    font-weight: bold;
    /* margin-top: 15px; */
  }
  .paymentMethodDescription {
    text-align: center;
    height: 45px;
  }
  .updatePaymentMethodIcon {
    position: relative;
    background-color: ${({ theme }) => theme.colors.primary};
    width: 30px;
    border-radius: 100%;
    text-align: center;
    height: 30px;
    z-index: 1;
    top: 12px;
    left: -5px;
    color: white;
  }
  .getLinkPaymentMethodIcon > span > svg {
    width: 15px;
    height: 15px;
    font-weight: bold;
  }
  .updatePaymentMethodIcon:hover {
    cursor: pointer;
    background-color: #1272a2;
  }
  .getLinkPaymentMethodIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: ${(props) =>
      props.apiLoading ? "gray" : ({ theme }) => theme.colors.primary};
    width: 30px;
    border-radius: 100%;
    text-align: center;
    height: 30px;
    z-index: 1;
    top: -20px;
    color: white;
    margin: 0 auto;
    border: none;
  }

  .getLinkPaymentMethodIcon:hover {
    cursor: ${(props) => (props.apiLoading ? "not-allowed" : "pointer")};
    opacity: 0.8;
  }
  @media (min-width: 1100px) {
    /* max-width: 30%; */
    img {
      max-height: 275px;
    }
  }
`;
