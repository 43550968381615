import styled from "styled-components";

export const Container = styled.section`
  margin: 50px 0;
  h2 {
    display: flex;
    align-items: center;
  }
  h2 > svg {
    margin-right: 5px;
  }
  .rankingLabel {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid ${({ theme }) => theme.colors.black};
    margin-top: 20px;
  }
  .rankingLabel > p {
    padding: 5px 0;
    font-weight: 700;
  }

  .tool {
    position: relative;
    display: inline-block;
    margin-left: 10px;
    font-size: 22px;
  }

  .tool .tooltext {
    font-size: 12px;
    visibility: hidden;
    width: 240px;
    background-color: ${({ theme }) => theme.colors.primary};
    color: #fff;
    text-align: start;
    border-radius: 6px;
    position: absolute;
    width: 130px;
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    padding: 7px;
  }
  .tool:hover {
    cursor: pointer;
  }
  .tool:hover .tooltext {
    visibility: visible;
  }
`;
