import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useSeller } from "../../providers/users";
import { Container } from "./styles";

const TokenVerificationForm = () => {
  const [countResend, setCountResent] = useState<number>(
    JSON.parse(window.localStorage.getItem("countTimer") as any) === true
      ? 60
      : 0
  );
  const { path } = useParams();
  const { sendActivationToken, Logout } = useSeller();
  const { t } = useTranslation();

  useEffect(() => {
    if (countResend === 60) {
      setTimeout((event) => {
        window.localStorage.removeItem("resendCount");
      }, 61000);
    }
  }, []);

  useEffect(() => {
    const timer =
      countResend > 0 &&
      setInterval(() => setCountResent(countResend - 1), 1000);
    return () => clearInterval(timer as any);
  }, [countResend]);

  const sendSubmit = (event: any) => {
    sendActivationToken(path);
    window.localStorage.setItem("countTimer", true as any);
    setCountResent(60);
    setTimeout((event) => {
      window.localStorage.removeItem("resendCount");
    }, 61000);
  };

  return (
    <>
      <Container>
        <h4>{t("inactive_account")}</h4>
        <p className="activationMessage">{t("resend_message")}</p>
        {
          <Button
            variant={"contained"}
            disabled={countResend > 0 ? true : false}
            id="btnResend"
            onClick={(event) => sendSubmit(event)}
            className="resend"
          >
            {t("resend_code")} {countResend > 0 ? "(" + countResend + ")" : ""}
          </Button>
        }
        <p className="logoutLink" onClick={() => Logout()}>
          {t("login_different_account")}
        </p>
      </Container>
    </>
  );
};

export default TokenVerificationForm;
