import { Button, TextField, FormHelperText } from "@mui/material";
import { useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useProductCommissions } from "../../providers/productCommissions";
import { useTenant } from "../../providers/tenant";
import { useSeller } from "../../providers/users";
import Loading from "../Loading";
import { Container } from "./styles";
import { IUserFromTenant } from "../../providers/users/user.interface";
import { IProductComission } from "../../providers/productCommissions/productCommissions.interface";
import { IoCreateOutline } from "react-icons/io5";
import PageHeader from "../pageHeader";

const ChangeProductCommissions = () => {
  const { apiLoading } = useSeller();
  const { allUsers } = useTenant();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user_id, path } = useParams();
  const { editProductCommissions } = useProductCommissions();
  useEffect(() => {
    if (allUsers && !allUsers.find((e: IUserFromTenant) => e.id === user_id)) {
      navigate(`/${path}/dashboard`);
    }
  }, [allUsers, navigate, path, user_id]);
  const user = allUsers
    ? allUsers.find((e: IUserFromTenant) => e.id === user_id)
    : null;
  const onSubmit = async (data: any) => {
    data.productCommissions = [];

    user.productCommissions.forEach((e: IProductComission) => {
      data.productCommissions.push(e.id);
    });

    // Filtra os itens NaN de ambos os arrays simultaneamente
    const validItems = data.percentages.reduce(
      (acc: any[], percentage: number, index: number) => {
        if (!isNaN(percentage)) {
          acc.push({
            percentage: percentage,
            productCommission: data.productCommissions[index],
          });
        }
        return acc;
      },
      []
    );

    // Atualiza os arrays com os itens válidos
    data.percentages = validItems.map((item: any) => item.percentage);
    data.productCommissions = validItems.map(
      (item: any) => item.productCommission
    );

    if (data.percentages.length === 0) {
      return;
    }

    editProductCommissions(data, "users", true);
  };

  const { register, handleSubmit, control } = useForm({
    // resolver: yupResolver(formSchema),
  });
  useFieldArray({
    control,
    name: "percentages",
  });

  return (
    <Container>
      {apiLoading ? (
        <Loading />
      ) : (
        user && (
          <>
            <PageHeader
              title={t("define_commissions")}
              description={`${user.first_name} ${user.last_name}`}
              icon={<IoCreateOutline />}
            />

            <form onSubmit={handleSubmit(onSubmit)}>
              {user.productCommissions.length > 0 ? (
                user.productCommissions.map(
                  (e: IProductComission, index: number) => (
                    <TextField
                      id={"standard-description/" + e.id}
                      key={e.id}
                      type="number"
                      InputProps={{ inputProps: { min: 1, max: 100 } }}
                      label={`${e.product.name} (${e.percentage}%)`}
                      variant="standard"
                      {...register(`percentages.${index}`, {
                        valueAsNumber: true,
                      })}
                    />
                  )
                )
              ) : (
                <></>
              )}
              <FormHelperText> {t("no_fill")}</FormHelperText>

              {apiLoading ? (
                <Loading />
              ) : (
                <div>
                  <Button type="submit" variant="contained">
                    {t("confirm")}
                  </Button>
                  <Button
                    sx={{ ml: 1 }}
                    onClick={() =>
                      navigate(`/${path}/users
                  `)
                    }
                    variant="outlined"
                  >
                    {t("cancel")}
                  </Button>
                </div>
              )}
            </form>
          </>
        )
      )}
    </Container>
  );
};

export default ChangeProductCommissions;
