import RegisterForm from "../../components/RegisterForm";

const AdminUserCreationPage = () => {
  return (
    <>
      {" "}
      <RegisterForm />{" "}
    </>
  );
};

export default AdminUserCreationPage;
