import styled, { keyframes } from "styled-components";
import { fadeIn } from "react-animations";
const fadeAnimation = keyframes`${fadeIn}`;

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  min-height: 100vh;
  /* margin: 1px; */
  padding: 15px;
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  flex-direction: column;
  header {
    text-align: center;
  }
  .loginLogo {
    width: 200px;
    height: 100px;
  }
  main {
    animation: ${fadeAnimation} 1s;
    text-align: center;
  }
  h5 {
    margin-top: 10px;
  }
  .recruitmentButtons {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    margin: 0 auto;
    max-width: 200px;
  }
  @media (min-width: 800px) {
    display: flex;
    padding: 0px;

    main {
      padding: 40px 50px;
      max-width: 80vw;
      width: 100%;
    }
  }

  @media (min-width: 1100px) {
    main {
      max-width: 80vw;
      padding: 40px 150px;
      margin: 0 auto;
      width: 100%;
    }
  }
`;
