import PlaylistsUpdateForm from "../../components/PlaylistUpdateForm";

const PlaylistUpdate = () => {
  return (
    <>
      <PlaylistsUpdateForm />
    </>
  );
};

export default PlaylistUpdate;
