import { Route } from "react-router-dom";
import DataLayout from "../../components/DataLayout";
import Layout from "../../components/Layout";
import LogsPage from "../../pages/LogsPage";
import { useTenant } from "../../providers/tenant";
import { useSeller } from "../../providers/users";
import PrivateRoutes from "../PrivateRoutes";

export const LogsRoutes = () => {
  const { roleAdmin } = useSeller();
  const { getAllLogs, allLogs } = useTenant();
  return (
    <>
      <Route
        element={
          <PrivateRoutes rolesArray={roleAdmin}>
            <DataLayout
              key={"logs"}
              retrieveData={getAllLogs}
              allData={allLogs}
            />
          </PrivateRoutes>
        }
      >
        <Route
          path="/:path/logs"
          element={
            <PrivateRoutes rolesArray={roleAdmin}>
              <Layout>
                <LogsPage />
              </Layout>
            </PrivateRoutes>
          }
        />
      </Route>
    </>
  );
};
