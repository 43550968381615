import InvoicesCreationForm from "../../components/InvoicesCreationForm";

const InvoicesCreationPage = () => {
  return (
    <>
      {" "}
      <InvoicesCreationForm />{" "}
    </>
  );
};

export default InvoicesCreationPage;
