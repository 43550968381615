import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useSeller } from "../../providers/users";

interface IProps {
  retrieveData: any;
  allData: any;
}

const DataLayout = ({ retrieveData, allData }: IProps) => {
  const { seller } = useSeller();
  useEffect(() => {
    if ((seller && !allData) || (seller && allData.length === 0)) {
      retrieveData();
    }
  }, [seller]);

  return <Outlet />;
};

export default DataLayout;
