import { Button, Slider } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import logo from "../../assets/logo-removebg-preview.svg";
import FirstPhaseApplicationForm from "../../components/1PhaseApplicationForm";
import AddressCreationForm from "../../components/AddressCreationForm";
import { useRecruitment } from "../../providers/recruitments";
import { useSeller } from "../../providers/users";
import { Container } from "./styles";
import { ICandidatureFromSeller } from "../../providers/candidatures/candidatures.interface";
import { IAddress } from "../../providers/users/user.interface";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const RecruitmentCandidatePage = () => {
  const { token, isActive, seller, Logout, verifyRolePermission, roleRecruit } =
    useSeller();
  const navigate = useNavigate();
  const { getOneRecruitmentRecruit, oneRecruitment } = useRecruitment();
  const { path, recruitment_id } = useParams();
  const [applicationStarted, setApplicationStarted] = useState(false);
  const [pageForm, setPageForm] = useState(0);
  const [addressData, setAddressData] = useState<IAddress | null>(null);
  const [value, setValue] = useState(30);
  const { t, i18n } = useTranslation();
  const query = useQuery();

  useEffect(() => {
    const language = query.get("language");
    if (language) {
      i18n.changeLanguage(language === "pt" ? "pt" : "en");
    }

    if (isActive === false) {
      localStorage.setItem("SPP-Recruitment-ID", recruitment_id!);
    }
  });

  useEffect(() => {
    if (seller && !verifyRolePermission(roleRecruit)) {
      navigate("/" + path + "/dashboard");
    }
    if (
      seller &&
      seller.candidatures.find(
        (e: ICandidatureFromSeller) => e.recruitmentId === recruitment_id
      )
    ) {
      navigate("/" + path + "/candidate");
    }

    if (
      pageForm !== 3 &&
      seller &&
      seller.candidatures.filter(
        (e: ICandidatureFromSeller) => e.recruitment_phase !== "rejected"
      ).length > 0
    ) {
      navigate("/" + path + "/candidate");
    }
    const verifyRecruitment = async () => {
      const recruitment = await getOneRecruitmentRecruit(recruitment_id);
      if (!recruitment) {
        navigate("/" + path + "/candidate");
      }
    };
    verifyRecruitment();
  }, [seller]);

  if (!token) {
    localStorage.setItem("SPP-Recruitment-ID", recruitment_id!);
    return <Navigate to={"/" + path + "/"} />;
  }

  if (!oneRecruitment) {
    return <div></div>;
  }

  return (
    <Container>
      <header>
        <img src={logo} alt="SPP Logo" className="loginLogo" />
      </header>
      <main>
        {applicationStarted && pageForm === 1 ? (
          <>
            <div className="formHeader">
              <p>
                {t("follow_steps")} {oneRecruitment?.name}.
              </p>
              <p>{t("be_sure")}</p>
              <Slider
                value={value}
                disableSwap={true}
                sx={{ maxWidth: 150 }}
                aria-label="Default"
              />
            </div>
            <AddressCreationForm
              setValue={setValue}
              setPageForm={setPageForm}
              setAddressData={setAddressData}
            />
          </>
        ) : applicationStarted && pageForm === 2 ? (
          <>
            <div className="formHeader">
              <p>
                {t("follow_steps")} {oneRecruitment?.name}.
              </p>
              <p>{t("follow_steps")}.</p>
              <Slider
                value={value}
                disableSwap={true}
                sx={{ maxWidth: 150 }}
                aria-label="Default"
                // valueLabelDisplay="auto"
              />
            </div>
            <FirstPhaseApplicationForm
              setPageForm={setPageForm}
              setValue={setValue}
              addressData={addressData}
            />
          </>
        ) : applicationStarted && pageForm === 3 ? (
          <>
            <div className="formHeader">
              <Slider
                value={value}
                disableSwap={true}
                sx={{ maxWidth: 150 }}
                aria-label="Default"
                // valueLabelDisplay="auto"
              />
              <h4>{t("application_submited")}</h4>
              <p>{t("results_update")}</p>
            </div>
            <Button
              variant="contained"
              sx={{ mt: 3 }}
              onClick={() => navigate(`/${path}/candidate`)}
            >
              {t("go_back_main_page")}
            </Button>
          </>
        ) : (
          <div>
            <h3>
              {t("welcome")} {seller && seller.first_name}{" "}
              {seller && seller.last_name}!
            </h3>
            <h5>
              {t("recruitment_process")} {oneRecruitment?.name}
            </h5>
            <div className="recruitmentButtons">
              <Button
                variant="contained"
                sx={{ mt: 3 }}
                onClick={() => {
                  setApplicationStarted(true);
                  setPageForm(1);
                }}
              >
                {t("start_application")}
              </Button>
              <Button
                onClick={() => Logout()}
                variant="contained"
                sx={{ mt: 1 }}
              >
                {t("logout")}
              </Button>
            </div>
          </div>
        )}
      </main>
    </Container>
  );
};

export default RecruitmentCandidatePage;
