import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import logo from "../../assets/color-spp.png";
import AcceptTermsCreationForm from "../../components/AcceptTermsForm";
import ApprovedPhases from "../../components/ApprovedPhases";
import ApplicationAnalisys from "../../components/CandidatureAnalisys";
import RejectedCandidature from "../../components/RejectedCandidature";
import { useSeller } from "../../providers/users";
import { Container } from "./styles";
import { ICandidatureFromSeller } from "../../providers/candidatures/candidatures.interface";

const CandidatePage = () => {
  const { seller, Logout } = useSeller();
  const [candidature, setCandidature] = useState<ICandidatureFromSeller | null>(
    null
  );
  const { t } = useTranslation();

  useEffect(() => {
    if (seller && seller.candidatures.length > 0) {
      seller.candidatures.sort(
        (a: ICandidatureFromSeller, b: ICandidatureFromSeller) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );
      setCandidature(seller.candidatures[0]);
    }
  }, [seller]);
  return (
    <Container>
      <header>
        <img src={logo} alt="SPP Logo" className="candidatePageLogo" />
      </header>
      <main className="candidatureMain">
        {seller && seller.candidatures.length === 0 ? (
          <>
            <h3>
              {t("welcome")} {seller.first_name} {seller.last_name}!
            </h3>
            <div>{t("no_recruitment_process")}</div>
          </>
        ) : candidature &&
          candidature.recruitment_phase === "application_analysis" ? (
          <ApplicationAnalisys candidature={candidature} />
        ) : candidature &&
          candidature.recruitment_phase === "sales_test_analysis" ? (
          <ApplicationAnalisys candidature={candidature} />
        ) : candidature && candidature.recruitment_phase === "rejected" ? (
          <RejectedCandidature candidature={candidature} />
        ) : candidature &&
          candidature.recruitment_phase === "approved_1_phase" ? (
          <ApprovedPhases candidature={candidature} />
        ) : candidature &&
          candidature.recruitment_phase === "approved_1_phase_test" ? (
          <ApprovedPhases candidature={candidature} test={true} />
        ) : candidature &&
          candidature.recruitment_phase === "approved_2_phase" ? (
          <AcceptTermsCreationForm candidature={candidature} />
        ) : candidature &&
          candidature.recruitment_phase ===
            "approved_2_phase_accepted_terms" ? (
          <ApplicationAnalisys candidature={candidature} />
        ) : (
          <>
            <h3>
              {t("welcome")} {seller && seller.first_name}{" "}
              {seller && seller.last_name}!
            </h3>
            <div>{t("no_recruitment_process")}</div>
          </>
        )}
        <Button onClick={() => Logout()} variant="contained" sx={{ mt: 4 }}>
          {t("logout")}
        </Button>
      </main>
    </Container>
  );
};

export default CandidatePage;
