import ProductUpdateForm from "../../components/ProductUpdateForm";

const ProductUpdate = () => {
  return (
    <>
      <ProductUpdateForm />
    </>
  );
};

export default ProductUpdate;
