import { Button } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useSeller } from "../../providers/users";
import { Container } from "./styles";

interface IProps {
  verifyRole?: boolean;
  roleToVerify?: string[];
  title: string;
  description: string;
  buttonPath?: string;
  buttonContent?: string;
  icon: React.ReactNode;
  showButton?: boolean;
}

const PageHeader = ({
  verifyRole = false,
  roleToVerify,
  title,
  description,
  buttonPath,
  buttonContent,
  icon,
  showButton,
}: IProps) => {
  const { verifyRolePermission } = useSeller();
  const navigate = useNavigate();
  const { path } = useParams();

  return (
    <Container>
      {" "}
      <div className="headerTitleAndSubtitle">
        <h2 className="headerTitle">
          {icon} {title}
        </h2>
        <p className="headerDescription">{description}</p>
      </div>
      {(verifyRole && verifyRolePermission(roleToVerify)) || showButton ? (
        <Button
          variant="contained"
          className="pageHeaderButton"
          onClick={() => navigate(`/${path}/${buttonPath}`)}
        >
          {buttonContent}
        </Button>
      ) : (
        <></>
      )}
    </Container>
  );
};

export default PageHeader;
