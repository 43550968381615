import styled from "styled-components";

export const Container = styled.section`
  .adminListSellerContainer {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin: 15px 0;
    padding: 15px;
    border-radius: 10px;
    transition: all 0.3s ease;
  }

  .active-user-container {
    background-color: ${({ theme }) => theme.colors.secondary};
    border-left: 4px solid ${({ theme }) => theme.colors.success || "#4caf50"};
  }

  .inactive-user-container {
    background-color: ${({ theme }) => theme.colors.secondary}E6;
    border-left: 4px solid ${({ theme }) => theme.colors.error || "#f44336"};
  }

  .adminListSellerInfo {
    display: flex;
    align-items: center;
  }

  .status-indicator {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 10px;
  }

  .status-indicator.active {
    background-color: ${({ theme }) => theme.colors.success || "#4caf50"};
  }

  .status-indicator.inactive {
    background-color: ${({ theme }) => theme.colors.error || "#f44336"};
  }

  .adminListSellerNameCity {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .adminListSellerName {
    font-size: 12px;
    font-weight: 700;
    text-transform: capitalize;
  }

  .inactive-user-container .adminListSellerName,
  .inactive-user-container .adminListSellerEmail,
  .inactive-user-container .adminListSellerCity,
  .inactive-user-container .adminListSellerRole {
    opacity: 0.7;
  }

  .adminListSellerCity {
    font-size: 9px;
    text-transform: capitalize;
  }

  .adminListSellerEmail {
    font-size: 12px;
  }

  .adminListSellerRole {
    display: flex;
    font-size: 12px;
    margin-top: 5px;
  }

  .updateRoleIcon {
    background-color: ${({ theme }) => theme.colors.primary};
    padding: 1px 5px;
    margin-left: 4px;
    margin-right: 4px;
    border-radius: 50%;
    text-align: center;
    color: white;
    min-width: 22px;
    max-height: 20px;
  }

  .updateRoleIcon:hover {
    cursor: pointer;
    background-color: #1272a2;
  }

  .updateRoleIcon > span {
    position: relative;
    top: -1px;
  }

  .adminListSellerInfo > img {
    width: 45px;
    height: 45px;
    border-radius: 100%;
    font-size: 11px;
  }

  .adminButtonDiv {
    display: flex;
    gap: 5px;
    justify-content: center;
    margin-top: 5px;
    flex-wrap: wrap;
  }

  @media (min-width: 470px) {
    .adminListSellerContainer {
      flex-direction: row;
    }
    .adminButtonDiv {
      flex-direction: column;
    }
  }

  @media (min-width: 800px) {
    .adminListSellerInfo > img {
      width: 60px;
      height: 60px;
      border-radius: 100%;
    }
    .adminListSellerNameCity {
      margin-left: 30px;
    }
    .adminListSellerName {
      font-size: 15px;
      font-weight: 700;
      text-transform: capitalize;
    }
    .adminListSellerCity {
      font-size: 11px;
      text-transform: capitalize;
      color: ${({ theme }) => theme.colors.black};
    }
  }
`;
