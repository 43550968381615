import { AiOutlineEdit } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import { IProduct } from "../../providers/products/products.interface";
import { useSeller } from "../../providers/users";
import { Container } from "./styles";

interface IProps {
  product: IProduct;
}

const ProductsItem = ({ product }: IProps) => {
  const { verifyRolePermission, roleAdmin } = useSeller();
  const navigate = useNavigate();
  const { path } = useParams();
  return (
    <Container
      src={
        product.productImage?.url
          ? '"' + product.productImage?.url + '"'
          : "https://reinvent.awsevents.com/content/dam/reinvent/2023/media/ripples/secondary-hero-mobile-2.png"
      }
    >
      {verifyRolePermission(roleAdmin) ? (
        <div
          onClick={() =>
            navigate("/" + path + "/products/" + product.id + "/update")
          }
          className="updateProductIcon"
        >
          <span>
            <AiOutlineEdit />
          </span>
        </div>
      ) : (
        <></>
      )}
      <div
        className="imageDiv"
        onClick={() =>
          navigate("/" + path + "/products/" + product.id + "/paymentmethods")
        }
      ></div>
    </Container>
  );
};

export default ProductsItem;
