import Sales from "../../components/Sales";
import { useSeller } from "../../providers/users";

const SalesPage = () => {
  const { sales, seller } = useSeller();

  return (
    <>
      <Sales
        allSales={sales}
        isAdminView={false}
        name={`${seller.first_name} ${seller.last_name}`}
      />
    </>
  );
};

export default SalesPage;
