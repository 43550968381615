import styled from "styled-components";

export const Container = styled.div`
  form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .inputNone {
    opacity: 0;
    width: 1px;
  }
  .paymentMethodsList {
    margin-top: 15px;
    width: 100%;

    display: flex;
    padding: 5px;
    overflow: scroll;
    gap: 6px;
  }
  .dropContainer {
    border: 1px dashed #ddd;
    border-radius: 4px;
    cursor: pointer;
    transition: height 0.2s ease;
  }
  .errosForm {
    color: #d32f2f;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin-top: 3px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
  }
  @media (min-width: 1100px) {
    .paymentMethodsList {
      overflow: hidden;
      flex-wrap: wrap;
      gap: 5px;
      justify-content: center;
    }
  }
`;
