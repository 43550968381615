import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import Divider from "@mui/material/Divider";
import Fab from "@mui/material/Fab";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { FaRobot } from "react-icons/fa";
import { IoSend } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import { useSalesCopilot } from "../../providers/salesCopilot";
import {
  IAssistants,
  IMessage,
  IThread,
} from "../../providers/salesCopilot/salesCopilot.interface";
import { LoadingChat } from "../Loading";
import ModalConfirmDeletion from "../ModalConfirmDeletion";
import PageHeader from "../pageHeader";
import { Container } from "./styles";

const Chat = () => {
  const {
    allThreads,
    allAssistants,
    loadingAI,
    addMessagesToThread,
    deleteThread,
    createThread,
  } = useSalesCopilot();
  const { assistant_id, path } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (allAssistants) {
      const assistantFromId = allAssistants.find(
        (e: IAssistants) => e.id === assistant_id
      );
      if (!assistantFromId) {
        navigate("/" + path + "/assistants");
      }
    }
  }, [allAssistants, navigate, path, assistant_id]);

  const [thread, setThread] = React.useState<null | IThread>(
    allThreads.length > 0
      ? allThreads.filter(
          (e: IThread) =>
            e.messages[e.messages.length - 1].assistant_id === assistant_id
        )[0]
      : null
  );
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [selectedThreadId, setSelectedThreadId] = useState<string>("");
  const [modalConfirmDeletion, setModalConfirmDeletion] = useState(false);

  useEffect(() => {
    scrollToBottom();
  }, [thread, allThreads, loadingAI]);
  const handleChange = (event: any) => {
    const selectedId = event.target.value as string;
    const selectedThread = allThreads.find(
      (thread: IThread) => thread.id === selectedId
    );
    if (selectedThread) {
      selectedThread.messages.sort(
        (a: IMessage, b: IMessage) => a.created_at - b.created_at
      );
      setThread(selectedThread);
      // setSelectedThreadId(selectedId);
    }
  };

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth", // Para um scroll suave, utilize 'smooth'
    });
  };
  const onSubmit = async (data: any) => {
    if (data.message.length === 0 || loadingAI) {
      return;
    }
    reset();
    data.assistant_id = assistant_id;
    scrollToBottom();
    if (!thread) {
      setThread(newThread(data.message));
      data.assistant_id = assistant_id;
      const threadResponse = await createThread(data);
      if (threadResponse) {
        setThread(threadResponse[0]);
      }
      return;
    }
    data.thread_id = thread.id;
    const message = newMessage(data.message, thread.id, assistant_id!);
    thread.messages.push(message);
    const threadResponse = await addMessagesToThread(data);
    if (threadResponse) {
      setThread(threadResponse[0]);
    }
    scrollToBottom();
  };
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  return (
    <>
      <Container>
        <PageHeader
          title={t("sales_copilot")}
          description={t("sales_copilot")}
          icon={<FaRobot />}
        />
        <FormControl className="select-chat">
          <InputLabel sx={{ my: 1 }} id="select-label">
            Thread
          </InputLabel>
          <Select
            sx={{ my: 1, p: 1 }}
            labelId="select-label"
            id="select"
            value={thread ? thread.id : " "}
            label="Thread"
            onChange={handleChange}
          >
            {allThreads.map((e: IThread) => (
              <MenuItem
                style={{ whiteSpace: "normal" }}
                key={e.id}
                value={e.id}
              >
                {cutString(e.messages[0].content[0].text.value)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <button onClick={() => setThread(null)} className="button-thread">
          + THREAD
        </button>
        {thread && thread.id && (
          <button
            onClick={() => setModalConfirmDeletion(true)}
            className="button-thread delete-thread"
          >
            DELETE THREAD
          </button>
        )}
        <div>
          <Grid
            container
            component={Paper}
            sx={{ width: "100%", height: "80vh" }}
          >
            <Grid item xs={3} className={"chat-threads"}>
              <List>
                <ListItem button key="RemySharp">
                  <ListItemText
                    primaryTypographyProps={{
                      style: {
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      },
                    }}
                    primary={
                      !thread
                        ? "New Thread"
                        : thread.messages[0].content[0].text.value
                    }
                  ></ListItemText>
                </ListItem>
              </List>
              <Divider />
              <Divider />
              <List>
                {allThreads
                  .filter(
                    (e: IThread) =>
                      e.messages[e.messages.length - 1].assistant_id ===
                      assistant_id
                  )
                  .map((e: IThread) => {
                    return (
                      <ListItem
                        button
                        onClick={() => {
                          e.messages.sort(
                            (a, b) => a.created_at - b.created_at
                          );
                          setThread(e);
                        }}
                        key={`item-${e.id}`}
                      >
                        <ListItemText
                          primaryTypographyProps={{
                            style: {
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            },
                          }}
                          primary={e.messages[0].content[0].text.value}
                        />
                      </ListItem>
                    );
                  })}
              </List>
            </Grid>
            <Grid item sm={9} xs={12}>
              <List
                sx={{
                  height: "70vh",
                  overflowY: "auto",
                }}
              >
                {thread &&
                  thread.messages.map((e: IMessage) => (
                    <ListItem key={e.id}>
                      <Grid container>
                        <Grid item xs={12}>
                          <ListItemText
                            // @ts-ignore
                            align={e.role === "assistant" ? "left" : "right"}
                            primary={e.content[0].text.value}
                          ></ListItemText>
                        </Grid>
                        <Grid item xs={12}>
                          <ListItemText
                            // @ts-ignore
                            align={e.role === "assistant" ? "left" : "right"}
                            secondary={converterTimestampParaHora(e.created_at)}
                          ></ListItemText>
                        </Grid>
                      </Grid>
                    </ListItem>
                  ))}
                {loadingAI && <LoadingChat />}
                <div ref={messagesEndRef} />
              </List>
              <Divider />
              <form onSubmit={handleSubmit(onSubmit)}>
                <TextField
                  id="outlined-basic-email"
                  label="Type Something"
                  fullWidth
                  autoComplete="off"
                  {...register("message")}
                />
                <Fab
                  type="submit"
                  size="small"
                  color="primary"
                  aria-label="add"
                >
                  <IoSend />
                </Fab>
              </form>
            </Grid>
          </Grid>
        </div>
      </Container>
      {modalConfirmDeletion && (
        <ModalConfirmDeletion
          setModalConfirmDeletion={setModalConfirmDeletion}
          deleteId={thread!.id}
          deleteFunction={deleteThread}
        />
      )}
    </>
  );
};
function cutString(str: string) {
  if (str.length > 20) {
    return str.substring(0, 30) + "...";
  }
  return str;
}
export default Chat;
function converterTimestampParaHora(timestamp: number) {
  const data = new Date(timestamp);
  const horas = data.getHours();
  const minutos = data.getMinutes();
  const segundos = data.getSeconds();
  // Adiciona um zero à esquerda se os minutos forem menores que 10
  const minutosFormatados = minutos < 10 ? `0${minutos}` : minutos;
  const segundosFormatados = segundos < 10 ? `0${segundos}` : segundos;
  return `${horas}:${minutosFormatados}`;
}
const newMessage = (
  message: string,
  thread_id: string,
  assistant_id: string
): IMessage => {
  return {
    id: "msg_ASDADASDWAASDWASD",
    object: "thread.message",
    created_at: new Date().getTime(),
    thread_id: thread_id,
    role: "user",
    content: [
      {
        type: "text",
        text: {
          value: message,
          annotations: [],
        },
      },
    ],
    assistant_id: assistant_id,
    run_id: "run_ed6Eptki73wUPfKJGcuXk1Pv",
    file_ids: [],
  };
};
const newThread = (message: string): IThread => {
  return {
    id: "new_thread",
    object: "thread",
    messages: [
      {
        id: "",
        object: "thread.message",
        created_at: 1703293507,
        thread_id: "new_thread",
        role: "user",
        content: [
          {
            type: "text",
            text: {
              value: message,
              annotations: [],
            },
          },
        ],
        file_ids: [],
        assistant_id: "",
        run_id: "",
      },
    ],
  };
};
