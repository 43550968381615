import styled from "styled-components";

export const Container = styled.div`
  form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  form > label {
    margin-top: 10px;
  }
  .inputNone {
    opacity: 0;
    width: 1px;
  }
  .emptyPlaylistMessage {
    margin: 5px 0px;
  }
  .errosForm {
    color: #d32f2f;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin-top: 3px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
  }
`;
