import { useTranslation } from "react-i18next";
import Sales from "../../components/Sales";
import { useSale } from "../../providers/sales";

const AdminAllSalesPage = () => {
  const { allSales } = useSale();
  const { t } = useTranslation();
  return (
    <>
      {allSales && (
        <Sales
          allSales={allSales}
          name={t("all_sellers")}
          isAdminView={true}
          showUserName={t("all_sellers")}
        />
      )}
    </>
  );
};

export default AdminAllSalesPage;
