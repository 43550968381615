import React, { useState } from "react";
import { Button, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AiOutlineEdit } from "react-icons/ai";
import { BsPeople } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import { useSeller } from "../../providers/users";
import { useTenant } from "../../providers/tenant";
import ModalChangeRole from "../ModalChangeRole";
import ModalEnableDisableUser from "../ModalEnableDisableUser";
import { Container } from "./styles";
import { IUserFromTenant } from "../../providers/users/user.interface";
import PageHeader from "../pageHeader";

interface IProps {
  currentItems: IUserFromTenant[];
}

export const AdminUserList = ({ currentItems }: IProps) => {
  const { verifyRolePermission, roleAdmin } = useSeller();
  const { t } = useTranslation();
  const { path } = useParams();
  const { tenantByPath } = useTenant();
  const navigate = useNavigate();
  const [disable, setDisable] = useState(true);
  const [modalChangeRole, setModalChangeRole] = useState(false);
  const [modalEnableDisableUser, setModalEnableDisableUser] = useState(false);
  const [userToUpdateId, setUserToUpdateId] = useState("");

  return (
    <>
      <Container>
        <PageHeader
          title={t("users")}
          description={t("users_list")}
          buttonContent={t("create")}
          buttonPath="users/create"
          verifyRole={true}
          roleToVerify={roleAdmin}
          icon={<BsPeople />}
        />
        {currentItems &&
          currentItems.map((item) => (
            <React.Fragment key={item.id}>
              <div
                className={`adminListSellerContainer ${
                  item.inactive
                    ? "inactive-user-container"
                    : "active-user-container"
                }`}
              >
                <div className="adminListSellerInfo">
                  <Tooltip
                    title={item.inactive ? t("inactive") : t("active")}
                    placement="top"
                  >
                    <div
                      className={`status-indicator ${
                        item.inactive ? "inactive" : "active"
                      }`}
                    ></div>
                  </Tooltip>
                  <img
                    src={
                      item.userProfileImage
                        ? item.userProfileImage.url
                        : "https://as2.ftcdn.net/v2/jpg/04/10/43/77/1000_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg"
                    }
                    alt="Imagem de Usuário"
                  />
                  <div className="adminListSellerNameCity">
                    <p className="adminListSellerName">
                      {item.first_name + " " + item.last_name}
                    </p>
                    <p className="adminListSellerEmail">
                      {t("email")}: {item.email}
                    </p>
                    <p className="adminListSellerCity">
                      {item.address
                        ? `${item.address.city}, ${item.address.country}`
                        : ""}
                    </p>
                    <div className="adminListSellerRole">
                      {t("role")}: {item.roles.map((u) => u.name).join(", ")}{" "}
                      {tenantByPath && tenantByPath.email === item.email ? (
                        <></>
                      ) : verifyRolePermission(roleAdmin) ? (
                        <div
                          onClick={() => {
                            setModalChangeRole(true);
                            setUserToUpdateId(item.id);
                          }}
                          className="updateRoleIcon"
                        >
                          <span>
                            <AiOutlineEdit />
                          </span>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>

                <div className="adminButtonDiv">
                  <Button
                    variant="contained"
                    onClick={() =>
                      navigate(`/${path}/users/commissions/${item.id}`)
                    }
                  >
                    {t("commissions")}
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => navigate(`/${path}/users/sales/${item.id}`)}
                  >
                    {t("sales")}
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() =>
                      navigate(`/${path}/users/invoices/${item.id}`)
                    }
                  >
                    {t("invoices")}
                  </Button>
                  <Button
                    variant="contained"
                    disabled={tenantByPath && tenantByPath.email === item.email}
                    onClick={() => {
                      setUserToUpdateId(item.id);
                      setDisable(!item.inactive);
                      setModalEnableDisableUser(true);
                    }}
                  >
                    {item.inactive ? t("enable") : t("disable")}
                  </Button>
                </div>
              </div>

              {modalChangeRole && (
                <ModalChangeRole
                  ModalChangeRole={modalChangeRole}
                  setModalChangeRole={setModalChangeRole}
                  userToUpdateId={userToUpdateId}
                />
              )}
              {modalEnableDisableUser && (
                <ModalEnableDisableUser
                  setModalEnableDisableUser={setModalEnableDisableUser}
                  userId={userToUpdateId}
                  disable={disable}
                />
              )}
            </React.Fragment>
          ))}
      </Container>
    </>
  );
};
