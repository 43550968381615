import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import axios from "axios";
import { MuiTelInput } from "mui-tel-input";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { useTenant } from "../../providers/tenant";
import { useSeller } from "../../providers/users";
import Loading from "../Loading";
import { Container } from "./styles";
import { t } from "i18next";
import PageHeader from "../pageHeader";
import { IoCreateOutline } from "react-icons/io5";
import { formSchema } from "../AcceptTermsForm/resolvers";

const RegisterForm = () => {
  const { createUser, apiLoading } = useSeller();
  const { tenantByPath } = useTenant();
  const navigate = useNavigate();
  const { path } = useParams();
  const [language, setLanguage] = useState("english");
  const { t } = useTranslation();
  const [ip, setIp] = useState("");
  const [defaultCountry, setDefaultCountry] = useState("BR");

  useEffect(() => {
    if (!ip) {
      const ipResponse = axios
        .get("https://api.ipify.org?format=json")
        .then((res) =>
          axios
            .get("http://ip-api.com/json/" + res.data.ip)
            .then((response) => {
              setDefaultCountry(response.data.countryCode);
            })
        )
        .catch((err) => {
          return;
        });
    }
  }, [ip]);

  const handleChange = (event: any) => {
    setLanguage(event.target.value);
  };

  const onSubmit = async (data: any) => {
    await createUser(data);
  };
  const handleDateChange = (date: any) => {
    setValue("date_birth", date.$d.toISOString().split("T")[0]); // Atualiza o valor do campo date_birth
  };
  const formSchema = yup.object().shape({
    first_name: yup
      .string()
      .required(t("first_name_required"))
      .max(128, t("first_name_max")),
    last_name: yup
      .string()
      .required(t("last_name_required"))
      .max(128, t("last_name_max")),
    email: yup.string().required(t("email_required")).email(t("email_invalid")),
    phone: yup.string(),
    gender: yup.string(),
  });

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {
      first_name: "",
      last_name: "",
      date_birth: "",
      email: "",

      phone: "",
      gender: "",
      default_language: "english",
    },
  });

  return (
    <Container onSubmit={handleSubmit(onSubmit)}>
      <PageHeader
        title={t("new_user")}
        description={`${t("fill_create")} ${t("user")}`}
        icon={<IoCreateOutline />}
      />
      <TextField
        error={errors.first_name === undefined ? false : true}
        helperText={errors.first_name?.message}
        id="standard-name"
        label={t("first_name")}
        variant="standard"
        {...register("first_name")}
      />
      <TextField
        error={errors.last_name === undefined ? false : true}
        helperText={errors.last_name?.message}
        id="standard-last_name"
        label={t("last_name")}
        variant="standard"
        {...register("last_name")}
      />

      {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          sx={{ mt: 1 }}
          label={t("date_birth")}
          onChange={handleDateChange}
          disableFuture
        />
      </LocalizationProvider> */}

      {/* <FormControl>
        <FormLabel id="demo-radio-buttons-group-label">{t("gender")}</FormLabel>
        <Controller
          control={control}
          name={"gender"}
          render={({ field }) => (
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={t("female")}
              {...field}
              name="radio-buttons-group"
            >
              <FormControlLabel
                value="female"
                control={<Radio />}
                label={t("female")}
              />
              <FormControlLabel
                value="male"
                control={<Radio />}
                label={t("male")}
              />
            </RadioGroup>
          )}
        />
        <FormHelperText sx={{ color: "red" }}>
          {errors.gender && errors.gender.message}
        </FormHelperText>
      </FormControl> */}

      {/* <Controller
        control={control}
        // rules={{
        //   validate: isValidPhoneNumber,
        // }}
        render={({ field, fieldState }) => (
          <MuiTelInput
            {...field}
            //@ts-ignore
            defaultCountry={defaultCountry}
            variant="standard"
            label={t("phone_number")}
            error={errors.phone === undefined ? false : true}
            helperText={errors.phone?.message?.toString()}
          />
        )}
        name="phone"
      /> */}
      <TextField
        error={errors.email === undefined ? false : true}
        helperText={errors.email?.message}
        id="standard-email"
        label={t("email")}
        variant="standard"
        {...register("email")}
      />
      <FormControl sx={{ mt: 1 }}>
        <InputLabel id="demo-simple-select-label">{t("language")}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={language}
          label={t("language")}
          {...register("default_language")}
          onChange={handleChange}
        >
          <MenuItem value={"english"}>{t("english")}</MenuItem>
          <MenuItem value={"portuguese"}>{t("portuguese")}</MenuItem>
        </Select>
      </FormControl>
      {apiLoading ? (
        <Loading />
      ) : (
        <div>
          <Button type="submit" variant="contained">
            {t("create")}
          </Button>
          <Button
            sx={{ ml: 1 }}
            onClick={() => navigate(`/${path}/users`)}
            variant="outlined"
          >
            {t("cancel")}
          </Button>
        </div>
      )}
    </Container>
  );
};

export default RegisterForm;
