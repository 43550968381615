import {
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineFileAdd } from "react-icons/ai";
import { IoMdRemoveCircle } from "react-icons/io";
import { useInvoice } from "../../providers/invoices";
import { IFile, IInvoice } from "../../providers/invoices/invoices.interface";
import ModalUpdateNote from "../ModalAddNote";
import ModalChangeInvoiceStatus from "../ModalChangeInvoiceStatus";
import ModalConfirmDeletion from "../ModalConfirmDeletion";
import { Container } from "./styles";
import { AiOutlineEdit } from "react-icons/ai";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
interface IProps {
  invoices: IInvoice[];
  isAdminView: boolean;
}

export const InvoiceList = ({ invoices, isAdminView }: IProps) => {
  const { t } = useTranslation();
  const { addAttachmentToInvoice, deleteInvoiceAttachment, updateInvoice } =
    useInvoice();
  const [modalConfirmDeletion, setModalConfirmDeletion] = useState(false);
  const [modalChangeStatus, setModalChangeStatus] = useState(false);
  const [modalAddNote, setModalAddNote] = useState(false);
  const { path } = useParams();
  const [invoice, setInvoice] = useState<IInvoice | null>(null);
  const [fileToRemove, setFileToRemove] = useState<IFile | null>(null);
  const navigate = useNavigate();

  const handleFileUpload = async (e: any) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    await addAttachmentToInvoice(
      e.currentTarget.id,
      file,
      e.currentTarget.name,
      isAdminView
    );
  };

  return (
    <>
      <Container>
        {invoices && (
          <div className="tableContainer">
            <TableContainer component={Paper}>
              <Table sx={{ tableLayout: "auto" }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">{t("month")}</TableCell>
                    <TableCell align="center">{t("status")}</TableCell>
                    <TableCell align="center">{t("invoice")}</TableCell>
                    <TableCell align="center">{t("payment_receipt")}</TableCell>
                    <TableCell sx={{ maxWidth: 100 }} align="center">
                      {t("note")}{" "}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {invoices &&
                    invoices
                      .sort((a: any, b: any) => {
                        return monthOrder[a.month] - monthOrder[b.month];
                      })
                      .map((row: IInvoice) => {
                        const payment_receipt = row.attachments.find(
                          (e: any) => e.type_name === "payment_receipt"
                        );
                        const invoice = row.attachments.find(
                          (e: any) => e.type_name === "invoice"
                        );
                        return (
                          <TableRow
                            key={row.id}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell
                              style={{
                                height: 90,
                              }}
                              component="th"
                              scope="row"
                              align="center"
                              className="updateInvoiceDiv"
                            >
                              {row.month}{" "}
                              {isAdminView === false ? (
                                <div
                                  onClick={() =>
                                    navigate(
                                      `/${path}/invoices/${row.id}/update`
                                    )
                                  }
                                  className="updateProductIcon"
                                >
                                  <span>
                                    <AiOutlineEdit />
                                  </span>
                                </div>
                              ) : (
                                <></>
                              )}
                            </TableCell>
                            <TableCell
                              align="center"
                              component="th"
                              scope="row"
                              onClick={() => {
                                setInvoice(row);
                                setModalChangeStatus(true);
                              }}
                            >
                              <p className="invoiceChange">
                                {row.status === "in_progress"
                                  ? t("in_progress")
                                  : row.status === "concluded"
                                  ? t("concluded")
                                  : row.status === "not_applicable"
                                  ? t("not_applicable")
                                  : ""}{" "}
                              </p>
                            </TableCell>
                            <TableCell align="center">
                              {" "}
                              {invoice ? (
                                <div className="attachedDiv">
                                  <a
                                    className="pdfLink"
                                    target="_blank"
                                    href={invoice.url}
                                    rel="noreferrer"
                                  >
                                    PDF
                                  </a>
                                  {!isAdminView ? (
                                    <i
                                      onClick={() => {
                                        setFileToRemove(invoice);
                                        setInvoice(row);
                                        setModalConfirmDeletion(true);
                                      }}
                                      className="removeFile"
                                    >
                                      <IoMdRemoveCircle />
                                    </i>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              ) : !isAdminView ? (
                                <IconButton
                                  disabled={
                                    row.status === "not_applicable"
                                      ? true
                                      : false
                                  }
                                  color="primary"
                                  component="label"
                                >
                                  <AiOutlineFileAdd className="iconInvoice" />
                                  <input
                                    className="inputNone"
                                    type="file"
                                    accept=".pdf"
                                    required
                                    onChange={handleFileUpload}
                                    id={row.id}
                                    name="invoice"
                                  />
                                </IconButton>
                              ) : (
                                ""
                              )}
                            </TableCell>
                            <TableCell align="center">
                              {" "}
                              {payment_receipt ? (
                                <div className="attachedDiv">
                                  <a
                                    className="pdfLink"
                                    target="_blank"
                                    href={payment_receipt.url}
                                    rel="noreferrer"
                                  >
                                    PDF
                                  </a>
                                  {isAdminView ? (
                                    <i
                                      onClick={() => {
                                        setFileToRemove(payment_receipt);
                                        setInvoice(row);
                                        setModalConfirmDeletion(true);
                                      }}
                                      className="removeFile"
                                    >
                                      <IoMdRemoveCircle />
                                    </i>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              ) : isAdminView ? (
                                <IconButton
                                  disabled={
                                    row.status === "not_applicable"
                                      ? true
                                      : false
                                  }
                                  color="primary"
                                  component="label"
                                >
                                  <AiOutlineFileAdd className="iconInvoice" />
                                  <input
                                    id={row.id}
                                    name="paymentreceipt"
                                    className="inputNone"
                                    type="file"
                                    accept=".pdf"
                                    onChange={handleFileUpload}
                                  />
                                </IconButton>
                              ) : (
                                ""
                              )}
                            </TableCell>

                            <TableCell
                              style={{
                                maxWidth: 150,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                borderStyle: "border-box",
                              }}
                              align="center"
                              className="noteInvoice"
                            >
                              <span
                                title={row.note}
                                onClick={() => {
                                  setInvoice(row);
                                  setModalAddNote(true);
                                }}
                              >
                                {row.note ? (
                                  <p className="invoiceChange">{row.note}</p>
                                ) : (
                                  <Button variant="outlined">
                                    {" "}
                                    {t("add")}
                                  </Button>
                                )}
                              </span>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
      </Container>
      {modalConfirmDeletion && fileToRemove && invoice && (
        <ModalConfirmDeletion
          setModalConfirmDeletion={setModalConfirmDeletion}
          deleteId={fileToRemove.id}
          parentId={invoice.id}
          deleteFunction={deleteInvoiceAttachment}
        />
      )}
      {modalChangeStatus && invoice && (
        <ModalChangeInvoiceStatus
          setModalChangeStatus={setModalChangeStatus}
          invoice_id={invoice.id}
          defaultStatus={invoice.status}
          isAdminView={isAdminView}
        />
      )}
      {modalAddNote && invoice && (
        <ModalUpdateNote
          setModalChangeNote={setModalAddNote}
          item_id={invoice.id}
          defaultNote={invoice.note}
          isAdminView={isAdminView}
          functionToUpdate={updateInvoice}
        />
      )}
    </>
  );
};

const monthOrder: any = {
  January: 0,
  February: 1,
  March: 2,
  April: 3,
  May: 4,
  June: 5,
  July: 6,
  August: 7,
  September: 8,
  October: 9,
  November: 10,
  December: 11,
};
