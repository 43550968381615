import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSalesTeam } from "../../providers/salesTeams";
import { useSeller } from "../../providers/users";
import { useTenant } from "../../providers/tenant";
import Loading from "../Loading";
import { Container } from "./styles";
import { IUserFromTenant } from "../../providers/users/user.interface";

interface IProps {
  setModalAddUserToSalesTeam: React.Dispatch<React.SetStateAction<boolean>>;
  modalAddUserToSalesTeam: boolean;
  salesTeamId: string;
}

const ModalAddUserToSalesTeam = ({
  setModalAddUserToSalesTeam,
  modalAddUserToSalesTeam,
  salesTeamId,
}: IProps) => {
  const { apiLoading } = useSeller();
  const { addUserToSalesTeam } = useSalesTeam();
  const { getTenantUsers, allUsers } = useTenant();
  const { t } = useTranslation();
  const [userToAdd, setUserToAdd] = useState("");

  useEffect(() => {
    getTenantUsers(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalAddUserToSalesTeam]);

  const usersWithoutSalesTeam = allUsers
    ? allUsers.filter((user: IUserFromTenant) => !user.salesTeamId)
    : [];

  const handleChangeUser = (event: any) => {
    setUserToAdd(event.target.value);
  };
  const { register, handleSubmit } = useForm();
  const onSubmit = async (data: any) => {
    await addUserToSalesTeam(
      salesTeamId,
      data.userId,
      setModalAddUserToSalesTeam
    );
  };
  return (
    <Container>
      <div className="modalContainer">
        <span>
          <button
            className="exitModal"
            onClick={() => {
              setModalAddUserToSalesTeam(false);
            }}
          >
            X
          </button>
        </span>
        <form onSubmit={handleSubmit(onSubmit)}>
          {usersWithoutSalesTeam && usersWithoutSalesTeam.length > 0 ? (
            <FormControl>
              <InputLabel sx={{ mt: 1 }} id="demo-simple-select-label">
                {t("choose_user")}
              </InputLabel>
              <Select
                labelId="select-users"
                id="select-users"
                value={userToAdd}
                required
                label={t("choose_user")}
                {...register("userId")}
                onChange={handleChangeUser}
              >
                {usersWithoutSalesTeam.map((e: IUserFromTenant) => (
                  <MenuItem key={e.id} value={e.id}>
                    {`${e.first_name} ${e.last_name}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : apiLoading ? (
            <></>
          ) : (
            <p className="noUserFound">{t("no_user_found")}</p>
          )}
          {apiLoading ? (
            <Loading />
          ) : (
            <Button
              sx={{ mt: 2 }}
              variant="contained"
              type="submit"
              disabled={
                usersWithoutSalesTeam && usersWithoutSalesTeam.length === 0
                  ? true
                  : false
              }
            >
              {t("add")}
            </Button>
          )}
        </form>
      </div>
    </Container>
  );
};

export default ModalAddUserToSalesTeam;
