import { useTranslation } from "react-i18next";
import { ICandidatureFromSeller } from "../../providers/candidatures/candidatures.interface";
import { useSeller } from "../../providers/users";
import { Container } from "./styles";

interface IProps {
  candidature: ICandidatureFromSeller;
}
const ApplicationAnalisys = ({ candidature }: IProps) => {
  const { seller } = useSeller();
  const { t } = useTranslation();
  return (
    <Container>
      <h3>
        {t("welcome")} {seller && seller.first_name}{" "}
        {seller && seller.last_name}!
      </h3>
      <p>
        {t("recruitment_process")}{" "}
        {candidature && candidature.createdAt.split("T")[0]}
      </p>
      <p>
        Status:{" "}
        <span className="statusCandidature">
          {candidature &&
          candidature.recruitment_phase === "application_analysis"
            ? t("application_analysis")
            : candidature &&
              candidature.recruitment_phase === "sales_test_analysis"
            ? t("sales_test_analysis")
            : t("waiting_approvation")}
        </span>
      </p>
      <p>{t("analysis_message")}</p>
      <p>{t("atualization_message")}</p>
      <p> {t("comeback_later")}</p>
    </Container>
  );
};

export default ApplicationAnalisys;
