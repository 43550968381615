import { Button } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useSalesTeam } from "../../providers/salesTeams";
import ModalConfirmDeletion from "../ModalConfirmDeletion";
import { Container } from "./styles";
import { IUserFromSalesTeam } from "../../providers/users/user.interface";

interface IProps {
  teamMember: IUserFromSalesTeam;
}

const TeamMemberItem = ({ teamMember }: IProps) => {
  const [modalRemoveUser, setModalRemoveUser] = useState(false);
  const { sales_team_id } = useParams();
  const { removeUserFromSalesTeam } = useSalesTeam();
  const { t } = useTranslation();
  return (
    <>
      <Container>
        <div className="teamMemberContainer">
          <p>
            <span className="teamMemberName">{t("name")}: </span>{" "}
            {teamMember.first_name + " " + teamMember.last_name}
          </p>
          <p>
            <span className="teamMemberName">{t("email")}: </span>{" "}
            {teamMember.email}
          </p>
          <p>
            <span className="teamMemberName">{t("role")}: </span>{" "}
            {teamMember.roles.map((u) => u.name).join(", ")}{" "}
          </p>
          <p>
            <span className="teamMemberName">{t("phone_number")}: </span>{" "}
            {teamMember.phone}
          </p>
        </div>

        <div className="removeMemberButtons">
          <Button
            onClick={() => setModalRemoveUser(true)}
            size={"small"}
            variant="outlined"
            color="error"
          >
            {t("remove")}
          </Button>
        </div>
      </Container>
      {modalRemoveUser && (
        <ModalConfirmDeletion
          setModalConfirmDeletion={setModalRemoveUser}
          deleteId={teamMember.id}
          deleteFunction={removeUserFromSalesTeam}
          remove={true}
        />
      )}
    </>
  );
};

export default TeamMemberItem;
