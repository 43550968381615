import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  .createPlaylistDiv {
    max-width: 200px;
  }
  .playlistName {
    display: flex;
    gap: 15px;
  }

  .trainingList {
    margin-top: 15px;
    display: flex;
    text-transform: capitalize;
    flex-direction: column;
    gap: 6px;
    max-width: 100vw;
  }
  .updatePlaylistIcon {
    background-color: ${({ theme }) => theme.colors.primary};
    width: 30px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 30px;
    color: white;
  }
  .updatePlaylistIcon:hover {
    cursor: pointer;
    background-color: #1272a2;
  }

  @media (min-width: 1100px) {
    .trainingList {
      gap: 5px;
      justify-content: flex-start;
    }
  }
`;
