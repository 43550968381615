import AssistantCreationForm from "../../components/AssistantsCreationForm";
import OpenAIMessage from "../../components/OpenAIMessage";
import { useTenant } from "../../providers/tenant";
import { useSeller } from "../../providers/users";

const AssistantCreation = () => {
  const { tenantSettings } = useTenant();
  const { seller, verifyRolePermission, roleAdmin } = useSeller();

  const api_key = tenantSettings && tenantSettings.openai_api_key;
  return (
    <>
      {seller &&
      verifyRolePermission(roleAdmin) === true &&
      (!api_key || api_key === null) ? (
        <OpenAIMessage />
      ) : (
        <AssistantCreationForm />
      )}
    </>
  );
};

export default AssistantCreation;
