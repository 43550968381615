import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  FormHelperText,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { AiOutlineLink } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { useProduct } from "../../providers/products";
import { useSeller } from "../../providers/users";
import Loading from "../Loading";
import { Container } from "./styles";
import { useTranslation } from "react-i18next";
import ModalConfirmDeletion from "../ModalConfirmDeletion";
import { IProduct } from "../../providers/products/products.interface";
import { IoCreateOutline } from "react-icons/io5";
import PageHeader from "../pageHeader";

const ProductUpdateForm = () => {
  const { apiLoading } = useSeller();
  const { allProducts, editProduct, deleteProduct } = useProduct();
  const { product_id, path } = useParams();
  const [product, setProduct] = useState<IProduct | null>(null);
  const navigate = useNavigate();
  const [language, setLanguage] = useState("english");
  const [file, setFile] = useState<File | null>(null);
  const { t } = useTranslation();
  const [modalConfirmDeletion, setModalConfirmDeletion] = useState(false);
  useEffect(() => {
    if (allProducts) {
      const productFromId = allProducts.find(
        (e: IProduct) => e.id === product_id
      );
      if (productFromId) {
        setProduct(productFromId);
        setLanguage(productFromId.language);
      } else {
        navigate("/" + path + "/products");
      }
    }
  }, [allProducts, navigate, path, product_id]);

  const handleChange = (event: any) => {
    setLanguage(event.target.value);
  };
  function removeEmptyFields(data: any) {
    Object.keys(data).forEach((key) => {
      if (!product) {
        navigate(`/${path}/products`);
      }
      //@ts-ignore
      if (data[key] === "" || data[key] == null || data[key] === product[key]) {
        delete data[key];
      }
    });
  }
  type FormValues = yup.InferType<typeof formSchema>;
  const onSubmit = async (data: FormValues) => {
    setFile(null);
    removeEmptyFields(data);

    const size = Object.keys(data).length;
    if (size === 1 && data.fileInput.length === 0) {
      navigate(`/${path}/products`);
      return;
    }

    await editProduct(product_id, data);
  };

  const handleFileUpload = (e: any) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    setFile(file);
  };
  const formSchema = yup
    .object()
    .shape({
      name: yup.string().max(128, t("name_max")),
      description: yup.string().max(128, t("description_max")),
      fileInput: yup
        .mixed()
        .notRequired()
        .nullable()
        .test("fileSize", t("file_size"), (value) => {
          return (
            value.length === 0 || (value && value[0].size <= 1000 * 1024 * 1024)
          );
        })

        .test(
          "type",
          t("file_accept", { file_types: ".jpeg, .jpg, .gif, .png" }),
          (value) => {
            return (
              value.length === 0 ||
              value[0].type === "image/jpg" ||
              value[0].type === "image/jpeg" ||
              value[0].type === "image/gif" ||
              value[0].type === "image/png"
            );
          }
        )
        .notRequired()
        .nullable(),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  return (
    <>
      <Container>
        <PageHeader
          title={t("update_product")}
          description={`${t("fill_update")} ${t("product")}`}
          icon={<IoCreateOutline />}
        />

        {product && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              error={errors.name === undefined ? false : true}
              helperText={errors.name?.message?.toString()}
              id="standard-name"
              label={t("name")}
              variant="standard"
              defaultValue={product?.name}
              {...register("name")}
            />

            <TextField
              id="standard-description"
              error={errors.description === undefined ? false : true}
              helperText={errors.description?.message?.toString()}
              label={t("description")}
              variant="standard"
              defaultValue={product?.description}
              {...register("description")}
            />
            <Button
              component="label"
              variant="outlined"
              startIcon={<AiOutlineLink />}
            >
              Upload Image
              <input
                {...register("fileInput")}
                className="inputNone"
                type="file"
                accept=".png, .jpg, .jpeg"
                onChange={handleFileUpload}
              />
            </Button>
            <p className="errosForm">
              {errors.fileInput && errors.fileInput.message?.toString()}
            </p>
            <Box>{file?.name}</Box>
            <FormControl>
              <InputLabel id="demo-simple-select-label">
                {t("language")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={language}
                label={t("language")}
                {...register("language")}
                onChange={handleChange}
              >
                <MenuItem value={"english"}>{t("english")}</MenuItem>
                <MenuItem value={"portuguese"}>{t("portuguese")}</MenuItem>
              </Select>
            </FormControl>
            <FormHelperText> {t("no_fill")}</FormHelperText>
            {apiLoading ? (
              <Loading />
            ) : (
              <div className="buttonsDiv">
                <Button type="submit" variant="contained">
                  {t("confirm")}
                </Button>
                <Button
                  sx={{ ml: 1 }}
                  onClick={() => navigate("/" + path + "/products")}
                  variant="outlined"
                >
                  {t("cancel")}
                </Button>
                <Button
                  sx={{ ml: 1 }}
                  onClick={() => setModalConfirmDeletion(true)}
                  variant="outlined"
                  color="error"
                >
                  {t("delete")}
                </Button>
              </div>
            )}
          </form>
        )}
      </Container>
      {modalConfirmDeletion && (
        <ModalConfirmDeletion
          setModalConfirmDeletion={setModalConfirmDeletion}
          deleteId={product_id!}
          deleteFunction={deleteProduct}
        />
      )}
    </>
  );
};

export default ProductUpdateForm;
