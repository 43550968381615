import { createContext, useContext, useState } from "react";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import api from "../../services";
import { useSeller } from "../users";
import { IProviderProps } from "../tenant/tenant.interfaces";
import {
  ICreateSalesTeam,
  ISalesTeam,
  IUpdateSalesTeam,
} from "./salesTeams.interface";

export const SalesTeamsContext = createContext<any | null>(null);
export const SalesTeamProvider = ({ children }: IProviderProps) => {
  const navigate = useNavigate();
  const { token, setApiLoading, setPageMounting } = useSeller();
  const location = useLocation();
  const path = location.pathname.split("/")[1];
  const [allSalesTeams, setAllSalesTeams] = useState<ISalesTeam | null>(null);

  const getAllSalesTeams = async () => {
    setPageMounting(true);
    await api
      .get("/salesteams", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setAllSalesTeams(res.data);
        setPageMounting(false);
      })
      .catch((err) => {
        setPageMounting(false);
        console.log(err.response.data.message);
      });
  };

  const createSalesTeam = async (data: ICreateSalesTeam) => {
    setApiLoading(true);
    await api
      .post(`/salesteams`, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(async (res) => {
        await getAllSalesTeams();
        setApiLoading(false);
        navigate(`${path}/salesteams`);
      })
      .catch((err) => {
        setApiLoading(false);
        toast.error(err.response.data.message);
      });
  };

  const editSalesTeam = async (
    sales_team_id: string,
    data: IUpdateSalesTeam
  ) => {
    setApiLoading(true);
    await api
      .patch(`/salesteams/${sales_team_id}`, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(async (res) => {
        await getAllSalesTeams();
        setApiLoading(false);
        navigate(`${path}/salesteams`);
      })
      .catch((err) => {
        setApiLoading(false);
        toast.error(err.response.data.message);
      });
  };
  const deleteSalesTeam = async (sales_team_id: string) => {
    setApiLoading(true);
    await api
      .delete(`/salesteams/${sales_team_id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(async (res) => {
        await getAllSalesTeams();
        setApiLoading(false);
        navigate(`${path}/salesteams`);
      })
      .catch((err) => {
        setApiLoading(false);
        toast.error(err.response.data.message);
      });
  };
  const removeUserFromSalesTeam = async (user_id: string) => {
    setApiLoading(true);
    const removed = await api
      .patch(
        `/salesteams/remove/${user_id}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(async (res) => {
        return true;
      })
      .catch((err) => {
        setApiLoading(false);
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
        return false;
      });
    if (removed) {
      setApiLoading(false);
      setTimeout(async () => {
        await getAllSalesTeams();
      }, 500);
    }
  };

  const addUserToSalesTeam = async (
    sales_team_id: string,
    user_id: string,
    setModalAddUserToSalesTeam: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    setApiLoading(true);
    const added = await api
      .patch(
        `/salesteams/member`,
        {
          user_id,
          team_id: sales_team_id,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(async (res) => {
        setApiLoading(false);
        setModalAddUserToSalesTeam(false);

        return true;
      })
      .catch((err) => {
        setApiLoading(false);
        toast.error(err.response.data.message);
        return false;
      });
    if (added) {
      setTimeout(async () => {
        await getAllSalesTeams();
      }, 100);
    }
    setModalAddUserToSalesTeam(false);
  };
  return (
    <SalesTeamsContext.Provider
      value={{
        getAllSalesTeams,
        allSalesTeams,
        createSalesTeam,
        editSalesTeam,
        deleteSalesTeam,
        removeUserFromSalesTeam,
        addUserToSalesTeam,
      }}
    >
      {children}
    </SalesTeamsContext.Provider>
  );
};

export const useSalesTeam = () => useContext(SalesTeamsContext);
