import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IoCreateOutline } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { useSalesCopilot } from "../../providers/salesCopilot";
import { useSeller } from "../../providers/users";
import Loading from "../Loading";
import PageHeader from "../pageHeader";
import { Container } from "./styles";
import { AiOutlineLink } from "react-icons/ai";

const AssistantCreationForm = () => {
  const { apiLoading } = useSeller();
  const { createAssistant } = useSalesCopilot();
  const navigate = useNavigate();
  const { path } = useParams();
  const [model, setModel] = useState("");
  const { t } = useTranslation();
  const [file, setFile] = useState<File[] | null>(null);

  const handleChange = (event: any) => {
    setModel(event.target.value);
  };

  type FormValues = yup.InferType<typeof formSchema>;
  const onSubmit = async (data: FormValues) => {
    setFile(null);
    await createAssistant(data);
  };
  const handleFileUpload = (e: any) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files;

    setFile(Object.values(file));
  };
  const formSchema = yup
    .object()
    .shape({
      name: yup.string().required(t("name_required")).max(128, t("name_max")),
      description: yup
        .string()
        .required(t("description_required"))
        .max(128, t("description_max")),
      instructions: yup.string().required(t("instructions_required")),

      model: yup
        .string()
        .required("model é necessário para criar assistants.")
        .max(128, "model deve ter menos de 128 caractéres. "),
      fileInput: yup
        .mixed()
        .required(t("file_required"))
        .test("fileSize", t("file_size"), (value) => {
          return value && value[0].size <= 1000 * 1024 * 1024;
        })
        .test(
          "type",
          t("file_accept", { file_types: ".pdf, .html, .txt" }),
          (value) => {
            return (
              value &&
              (value[0].type === "application/pdf" ||
                value[0].type === "text/html" ||
                value[0].type === "text/txt")
            );
          }
        ),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  return (
    <Container>
      <PageHeader
        title={t("new_assistant")}
        description={`${t("fill_create")} ${t("assistant")}`}
        icon={<IoCreateOutline />}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          error={errors.name === undefined ? false : true}
          helperText={errors.name?.message?.toString()}
          id="standard-name"
          label={t("name")}
          variant="standard"
          {...register("name")}
        />
        <TextField
          id="standard-description"
          error={errors.description === undefined ? false : true}
          helperText={errors.description?.message?.toString()}
          label={t("description")}
          variant="standard"
          {...register("description")}
        />
        <TextField
          id="standard-instructions"
          error={errors.instructions === undefined ? false : true}
          helperText={errors.instructions?.message?.toString()}
          label={t("instructions")}
          variant="standard"
          {...register("instructions")}
        />
        <FormControl>
          <InputLabel sx={{ mt: 1 }} id="select-model">
            {t("model")}
          </InputLabel>
          <Select
            sx={{ mt: 1 }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={model}
            label={t("model")}
            {...register("model")}
            onChange={handleChange}
          >
            <MenuItem value={"gpt-4-1106-preview"}>gpt-4-1106-preview</MenuItem>
            <MenuItem value={"gpt-3.5-turbo-1106"}>gpt-3.5-turbo-1106</MenuItem>
          </Select>
        </FormControl>
        <Button
          component="label"
          variant="outlined"
          startIcon={<AiOutlineLink />}
        >
          {t("files_upload")}
          <input
            {...register("fileInput")}
            className="inputNone"
            type="file"
            accept=".pdf, .txt, .html"
            required
            multiple
            onChange={handleFileUpload}
          />
        </Button>
        <p className="errosForm">
          {errors.fileInput && errors.fileInput.message?.toString()}
        </p>
        <FormHelperText>{t("files_message")}</FormHelperText>
        {file && file.map((e) => <Box key={e.name}>{e?.name}</Box>)}
        {apiLoading ? (
          <Loading />
        ) : (
          <div>
            <Button type="submit" variant="contained">
              {t("create")}
            </Button>
            <Button
              sx={{ ml: 1 }}
              onClick={() => navigate(`/${path}/assistants`)}
              variant="outlined"
            >
              {t("cancel")}
            </Button>
          </div>
        )}
      </form>
    </Container>
  );
};

export default AssistantCreationForm;
