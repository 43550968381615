import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useSale } from "../../providers/sales/index";
import { useSeller } from "../../providers/users";
import Loading from "../Loading";
import { Container } from "./styles";
import { GridRowId } from "@mui/x-data-grid";

interface IProps {
  setModalUpdateSaleStatus: React.Dispatch<React.SetStateAction<boolean>>;
  arraySalesId: GridRowId[];
}

const ModalUpdateSaleStatus = ({
  setModalUpdateSaleStatus,
  arraySalesId,
}: IProps) => {
  const { updateArraySalesComissionsStatus } = useSale();
  const [status, setStatus] = useState("");
  const { apiLoading } = useSeller();
  const { t } = useTranslation();
  const formSchema = yup
    .object()
    .shape({
      status: yup.string().required(t("status_required")),
    })
    .required();

  const handleStatus = (event: any) => {
    setStatus(event.target.value);
  };
  const { register, handleSubmit } = useForm({
    resolver: yupResolver(formSchema),
  });
  type FormValues = yup.InferType<typeof formSchema>;

  const onSubmit = async (data: FormValues) => {
    await updateArraySalesComissionsStatus(
      arraySalesId,
      data.status,
      setModalUpdateSaleStatus
    );
  };
  return (
    <Container>
      <div className="modalContainer">
        <span>
          <button
            className="exitModal"
            onClick={() => {
              setModalUpdateSaleStatus(false);
            }}
          >
            X
          </button>
        </span>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl>
            <InputLabel sx={{ mt: 1 }} id="demo-simple-select-label">
              {t("status")}
            </InputLabel>
            <Select
              sx={{ mt: 1 }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={status}
              label={t("status")}
              {...register("status")}
              onChange={handleStatus}
            >
              <MenuItem value="paid">{t("paid")}</MenuItem>
              <MenuItem value="approved_guarantee">
                {t("approved_guarantee")}
              </MenuItem>
              <MenuItem value="approved_for_payment">
                {t("approved_for_payment")}
              </MenuItem>
              <MenuItem value="finance_processing">
                {t("finance_processing")}
              </MenuItem>
              <MenuItem value="analysis">{t("analysis")}</MenuItem>
              <MenuItem value="cancelled_refunded">
                {t("cancelled_refunded")}
              </MenuItem>
              <MenuItem value="cancelled_chargeback">
                {t("cancelled_chargeback")}
              </MenuItem>
              <MenuItem value="rejected">{t("rejected")}</MenuItem>
              <MenuItem value="not_applicable">{t("not_applicable")}</MenuItem>
            </Select>
          </FormControl>
          {apiLoading ? (
            <Loading />
          ) : (
            <Button sx={{ mt: 2 }} variant="contained" type="submit">
              {t("update")}
            </Button>
          )}
        </form>
      </div>
    </Container>
  );
};

export default ModalUpdateSaleStatus;
