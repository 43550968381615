import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ICandidatureFromSeller } from "../../providers/candidatures/candidatures.interface";
import { useSeller } from "../../providers/users";
import { Container } from "./styles";

interface IProps {
  candidature: ICandidatureFromSeller;
  test?: boolean;
  terms?: boolean;
}

const ApprovedPhases = ({
  candidature,
  test = false,
  terms = false,
}: IProps) => {
  const navigate = useNavigate();
  const { seller } = useSeller();
  const { path } = useParams();
  const { t } = useTranslation();
  return (
    <Container>
      <h3>
        {t("welcome")} {seller.first_name} {seller.last_name}!
      </h3>
      <p>
        {t("recruitment_process")}{" "}
        {candidature && candidature.createdAt.split("T")[0]}
      </p>
      <p>
        Status:{" "}
        <span className="statusCandidature">
          {(candidature &&
            candidature.recruitment_phase === "approved_1_phase") ||
          "approved_1_phase_test"
            ? t("approved_1_phase")
            : t("approved_2_phase")}
        </span>
      </p>
      <p>{t("congratulations_phase")}</p>
      <p> {t("check_email")}</p>
      {test ? (
        <Button
          variant="contained"
          onClick={() =>
            navigate(`/${path}/candidatures/${candidature.id}/salestest`)
          }
        >
          {t("send_sales_test")}
        </Button>
      ) : terms ? (
        <Button
          variant="contained"
          onClick={() => navigate(`/${path}/termsconditions`)}
        >
          {t("read_terms")}
        </Button>
      ) : (
        <></>
      )}
    </Container>
  );
};

export default ApprovedPhases;
