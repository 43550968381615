const config = {
  columnNames: [
    "name",
    "seller",
    "email",
    "product",
    "currency",
    "commission",
    "product price",
    "percentage",
    "status",
    "date",
    "end_guarantee",
    "subscription_id",
    "order_id",
    "transaction_id",
    "charge_id",
  ],
  keys: [
    "name",
    "seller",
    "email",
    "product",
    "currency",
    "commission",
    "value",
    "percentage",
    "status",
    "date_sale",
    "end_guarantee",
    "subscription_id",
    "order_id",
    "transaction_id",
    "charge_id",
  ],
  fileName: `spp-sales${(Math.random() + 1).toString(36).substring(7)}.xlsx`,
  sheetName: "Sales Info",
};

export { config };
