import { CandidatureProvider } from "./candidatures";
import { PaymentMethodProvider } from "./paymentMethods";
import { PlaylistTrainingProvider } from "./playlistsTrainings";
import { ProductCommissionProvider } from "./productCommissions";
import { ProductProvider } from "./products";
import { RecruitmentProvider } from "./recruitments";
import { RoleProvider } from "./roles";
import { SaleProvider } from "./sales/index";
import { SalesTeamProvider } from "./salesTeams";
import { SellerProvider } from "./users";
import { TenantProvider } from "./tenant";
import { InvoiceProvider } from "./invoices";
import { SalesCopilotProvider } from "./salesCopilot";

interface IProvidersProps {
  children: React.ReactNode;
}

const Providers = ({ children }: IProvidersProps) => {
  return (
    <SellerProvider>
      <TenantProvider>
        <RoleProvider>
          <ProductProvider>
            <ProductCommissionProvider>
              <PaymentMethodProvider>
                <SalesTeamProvider>
                  <RecruitmentProvider>
                    <CandidatureProvider>
                      <InvoiceProvider>
                        <PlaylistTrainingProvider>
                          <SalesCopilotProvider>
                            <SaleProvider>{children}</SaleProvider>
                          </SalesCopilotProvider>
                        </PlaylistTrainingProvider>
                      </InvoiceProvider>
                    </CandidatureProvider>
                  </RecruitmentProvider>
                </SalesTeamProvider>
              </PaymentMethodProvider>
            </ProductCommissionProvider>
          </ProductProvider>
        </RoleProvider>
      </TenantProvider>
    </SellerProvider>
  );
};

export default Providers;
