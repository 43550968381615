import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Container } from "./styles";
import { ISalesTeam } from "../../providers/salesTeams/salesTeams.interface";

interface IProps {
  salesTeam: ISalesTeam;
}
const SalesTeamItem = ({ salesTeam }: IProps) => {
  const navigate = useNavigate();
  const { path } = useParams();
  const { t } = useTranslation();

  const containerOnclick = (e: any) => {
    navigate(`/${path}/salesTeams/${salesTeam.id}/members`);
  };
  return (
    <Container
      onClick={(e) => {
        containerOnclick(e);
      }}
    >
      <div className="salesTeamContainer">
        <div className="salesTeamInfo">
          <div className="salesTeamItemHeader">
            <p className="salesTeamName">
              {t("team_name")}: {salesTeam.name + " |"}
            </p>
            <p className="salesTeamDescription">
              {t("description")}: {salesTeam.description}
            </p>
          </div>
        </div>
      </div>
      <div className="salesTeamLink"></div>
    </Container>
  );
};

export default SalesTeamItem;
