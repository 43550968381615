import styled from "styled-components";

// export const Container = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   .react-player {
//     margin-top: 10px;
//     max-width: 90vw;
//   }
//   @media (min-width: 680px) {
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     /* display: flex; */
//     /* align-items: center; */
//     /* justify-content: center; */
//     /* width: 400; */
//     /* height: 400; */
//     /* background-color: white; */
//     transform: translate(-50%, -50%);
//   }
// `;

export const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 600px;
  .react-player {
    width: 100%;
    height: 100%;
    max-width: 100%;
  }
  @media (max-width: 768px) {
    width: 90%;
    max-width: 90vw;
  }

  @media (max-width: 576px) {
    width: 100%;
    max-width: 90vw;
  }
`;
