import styled from "styled-components";

export const Container = styled.div`
  margin-top: 25px;

  .salesContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px 0;
    background-color: ${({ theme }) => theme.colors.secondary};
    padding: 15px;
    border-radius: 10px;
  }

  .sellerName {
    margin-left: 30px;
    font-weight: bold;
  }

  .datePickerContainer {
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    /* justify-content: flex-end; */
  }
  .react-daterange-picker__wrapper {
    border-radius: 5px;
    padding: 5px;
  }
  .dateRangeLabel {
    display: flex;
    gap: 5px;
    flex-direction: column;
  }
  h3 {
    text-align: center;
    margin-top: 30px;
    font-size: 20px;
  }

  .filterAndUpdateContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  #select-label {
    max-width: 267px;
    min-width: 267px;
    margin-top: 5px;
  }

  .salesCostumerName {
    text-transform: capitalize;
  }

  @media (min-width: 800px) {
    .datePickerContainer {
      align-items: flex-end;
    }
    margin: 0px auto;
    max-width: 65vw;
  }
  @media (min-width: 1100px) {
    max-width: 80vw;
  }
`;
