import { InvoiceContainer } from "../../components/InvoicesContainer";
import Loading from "../../components/Loading";
import { useInvoice } from "../../providers/invoices";
import { useSeller } from "../../providers/users";

const InvoicesPage = () => {
  const { seller, apiLoading } = useSeller();

  const { allInvoices } = useInvoice();

  return (
    <>
      {apiLoading ? (
        <Loading />
      ) : (
        allInvoices && (
          <InvoiceContainer invoices={allInvoices} isAdminView={false} />
        )
      )}
    </>
  );
};

export default InvoicesPage;
