import Chat from "../../components/AIChat";
import ThreadsList from "../../components/ThreadsList";
import "./styles.css";
const ThreadsPage = () => {
  return (
    <>
      <Chat />
    </>
  );
};
export default ThreadsPage;
