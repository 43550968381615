import { useTranslation } from "react-i18next";
import { useSalesCopilot } from "../../providers/salesCopilot";
import { IAssistants } from "../../providers/salesCopilot/salesCopilot.interface";
import { useSeller } from "../../providers/users";
import PageHeader from "../pageHeader";
import { Container } from "./styles";
import AssistantItem from "../AssistantItem";
import { FaRobot } from "react-icons/fa";
const AssistantsList = () => {
  const { roleAdmin } = useSeller();
  const { allAssistants } = useSalesCopilot();
  const { t } = useTranslation();

  return (
    <Container>
      <PageHeader
        title={t("assistants")}
        description={t("assistants")}
        buttonContent={t("create")}
        buttonPath="assistants/create"
        verifyRole={true}
        roleToVerify={roleAdmin}
        icon={<FaRobot />}
      />

      {allAssistants && allAssistants.length > 0 ? (
        <div className="assistantsList">
          {allAssistants &&
            allAssistants.map((e: IAssistants) => (
              <AssistantItem assistant={e} key={e.id} />
            ))}
        </div>
      ) : (
        <div className="assistantsList">
          <h4>{t("no_assistant_found")}</h4>
        </div>
      )}
    </Container>
  );
};

export default AssistantsList;
