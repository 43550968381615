import styled from "styled-components";

export const Container = styled.div`
  margin-top: 5px;

  .listFiles {
    color: ${({ theme }) => theme.colors.primary};
  }
  .listFiles:hover,
  .saleChange:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;
