import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ICandidature } from "../../providers/candidatures/candidatures.interface";
import { useRecruitment } from "../../providers/recruitments";
import { IRecruitment } from "../../providers/recruitments/recruitments.interface";
import { useSeller } from "../../providers/users";
import CandidatureItem from "../CandidatureItem";
import Loading from "../Loading";
import { ActiveBall } from "../RecruitmentItem/styles";
import PageHeader from "../pageHeader";
import { Container } from "./styles";

const CandidaturesList = () => {
  const [candidatures, setCandidatures] = useState<ICandidature[]>([]);
  const { roleAdmin, apiLoading } = useSeller();
  const [recruitment, setRecruitment] = useState<IRecruitment | null>(null);
  const { allRecruitments } = useRecruitment();
  const { recruitment_id, path } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (allRecruitments) {
      const recruitmentFromId = allRecruitments.find(
        (e: IRecruitment) => e.id === recruitment_id
      );
      if (recruitmentFromId) {
        setCandidatures(recruitmentFromId.candidatures);
        setRecruitment(recruitmentFromId);
      } else {
        navigate("/" + path + "/recruitments");
      }
    }
  }, [allRecruitments, navigate, path, recruitment_id]);

  return (
    <Container>
      {recruitment && (
        <>
          {" "}
          <PageHeader
            title={recruitment?.name!}
            description={recruitment?.description!}
            buttonContent={t("update")}
            buttonPath={`recruitments/${recruitment?.id}/update`}
            verifyRole={true}
            roleToVerify={roleAdmin}
            icon={
              <ActiveBall
                className="candidaturePageBall"
                status={recruitment?.status === "active" ? 1 : 0}
              />
            }
          />
          <Button
            onClick={() => navigate("/" + path + "/recruitments")}
            variant="outlined"
          >
            {t("back")}
          </Button>
          {apiLoading ? (
            <Loading />
          ) : candidatures && candidatures.length === 0 ? (
            <div className="candidaturesNone">
              <h4>{t("no-candidature-found")}</h4>
            </div>
          ) : (
            <div className="candidaturesList">
              {candidatures &&
                candidatures.map((e) => (
                  <CandidatureItem key={e.id} candidature={e} />
                ))}
            </div>
          )}
        </>
      )}
    </Container>
  );
};

export default CandidaturesList;
