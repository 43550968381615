import SalesTeamsList from "../../components/SalesTeamsList";

const SalesTeamsPage = () => {
  return (
    <>
      <SalesTeamsList />
    </>
  );
};

export default SalesTeamsPage;
