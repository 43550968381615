import { toast } from "react-hot-toast";
import { AiOutlinePaperClip } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import { ActiveBall, Container } from "./styles";
import { useTranslation } from "react-i18next";
import CopyToClipboard from "react-copy-to-clipboard";
import { IRecruitment } from "../../providers/recruitments/recruitments.interface";

interface IProps {
  recruitment: IRecruitment;
}

const RecruitmentItem = ({ recruitment }: IProps) => {
  const navigate = useNavigate();
  const { path } = useParams();
  const { t } = useTranslation();
  const containerOnclick = (e: any) => {
    if (
      e.target.className === "copyLinkSvg" ||
      e.target.tagName === "path" ||
      e.target.tagName === "svg" ||
      e.target.className === "sc-dxEnNS hKgbHT"
    ) {
      return;
    }

    navigate(`/${path}/recruitments/${recruitment.id}`);
  };
  return (
    <Container
      onClick={(e) => {
        containerOnclick(e);
      }}
      status={recruitment.status === "active" ? 1 : 0}
    >
      <div className="recruitmentContainer">
        {/* <div className="activeBall"></div> */}
        <ActiveBall status={recruitment.status === "active" ? 1 : 0} />
        <div className="recruitmentInfo">
          <div className="recruitmentItemHeader">
            <p className="recruitmentName"> {recruitment.name + " |"}</p>
            <p className="recruitmentStatus">
              {t("status")}:{" "}
              {recruitment.status === "active" ? t("active") : t("inactive")}
            </p>
          </div>

          <p className="recruitmentCreatedBy">
            {t("started_on")}:{" "}
            {recruitment.createdAt &&
              t("date_format", {
                month: recruitment.createdAt.split("T")[0].split("-")[1],
                day: recruitment.createdAt.split("T")[0].split("-")[2],
                year: recruitment.createdAt.split("T")[0].split("-")[0],
              })}{" "}
            {/* {t("by")}: {recruitment.created_by} */}
          </p>
        </div>
      </div>
      <div className="recruitmentLink">
        <CopyToClipboard
          onCopy={() => toast.success(t("copied"))}
          text={`${process.env.REACT_APP_FRONTEND_URL}/${path}/recruitments/${recruitment.id}/candidate`}
        >
          <button className="copyLinkSvg">
            {" "}
            <AiOutlinePaperClip className="copyLinkSvg" />
          </button>
        </CopyToClipboard>
      </div>
    </Container>
  );
};

export default RecruitmentItem;
