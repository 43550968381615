import RecruitmentUpdateForm from "../../components/RecruitmentUpdateForm";

const RecruitmentUpdate = () => {
  return (
    <>
      <RecruitmentUpdateForm />
    </>
  );
};

export default RecruitmentUpdate;
