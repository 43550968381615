import SettingsContainer from "../../components/SettingsContainer";

const SettingsPage = () => {
  return (
    <>
      <SettingsContainer />
    </>
  );
};

export default SettingsPage;
