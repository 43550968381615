import { useTranslation } from "react-i18next";
import { IFile } from "../../providers/invoices/invoices.interface";
import { ISale } from "../../providers/sales/sales.interface";
import { Container } from "./styles";
interface IProps {
  setModalListSalesFiles: React.Dispatch<React.SetStateAction<boolean>>;
  sale: ISale;
}
const ModalListSalesFiles = ({ setModalListSalesFiles, sale }: IProps) => {
  const { t } = useTranslation();

  return (
    <Container>
      <div className="modalContainer">
        <span>
          <p>{t("file_list")}</p>
          <button
            className="exitModalPassword"
            onClick={() => {
              setModalListSalesFiles(false);
            }}
          >
            X
          </button>
        </span>
        <div className="modalBody">
          {sale.attachments.map((e: IFile, index) => (
            <div className="fileDiv" key={index}>
              <p>
                {t("file")} {index + 1}:
              </p>
              <a href={e.url} target="_blank" rel="noreferrer">
                {t("click_here")}
              </a>
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
};

export default ModalListSalesFiles;
