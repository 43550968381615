import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { useSeller } from "../../providers/users";
import Loading from "../Loading";
import { Container } from "./styles";
import { useInvoice } from "../../providers/invoices";
import PageHeader from "../pageHeader";
import { IoCreateOutline } from "react-icons/io5";

const InvoicesCreationForm = () => {
  const { apiLoading } = useSeller();
  const navigate = useNavigate();
  const { createInvoice } = useInvoice();
  const [month, setMonth] = useState(1);
  const { path } = useParams();
  const { t } = useTranslation();

  const handleChange = (event: any) => {
    setMonth(event.target.value);
  };
  type FormValues = yup.InferType<typeof formSchema>;
  const onSubmit = async (data: FormValues) => {
    const created = await createInvoice(data);
    if (created) {
      navigate(`/${path}/invoices`);
    }
  };
  const formSchema = yup
    .object()
    .shape({
      month: yup
        .number()
        .required(t("month_required"))
        .min(1, t("month_min_typeerror"))
        .max(12, t("month_max_typeerror")),
      year: yup.number().required(t("year_required")),
      note: yup.string().max(256, t("note_length")),
      status: yup
        .string()
        .max(128, "status length must have less than 128 characters. ")
        .test(
          "status Test",
          "status must be not_applicable, in_progress or concluded",
          function (value: any) {
            return (
              value === "not_applicable" ||
              value === "in_progress" ||
              value === "concluded" ||
              value === undefined
            );
          }
        ),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  return (
    <Container>
      <PageHeader
        title={t("new_invoice")}
        description={`${t("fill_create")} ${t("invoice")}`}
        icon={<IoCreateOutline />}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          error={errors.year === undefined ? false : true}
          helperText={errors.year?.message?.toString()}
          id="standard-year"
          label={t("year")}
          variant="standard"
          {...register("year")}
        />

        <FormControl>
          <InputLabel id="demo-simple-select-label">{t("month")}</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={month}
            label={t("month")}
            {...register("month")}
            onChange={handleChange}
          >
            <MenuItem value={1}>{t("january")}</MenuItem>
            <MenuItem value={2}>{t("february")}</MenuItem>
            <MenuItem value={3}>{t("march")}</MenuItem>
            <MenuItem value={4}>{t("april")}</MenuItem>
            <MenuItem value={5}>{t("may")}</MenuItem>
            <MenuItem value={6}>{t("june")}</MenuItem>
            <MenuItem value={7}>{t("july")}</MenuItem>
            <MenuItem value={8}>{t("august")}</MenuItem>
            <MenuItem value={9}>{t("september")}</MenuItem>
            <MenuItem value={10}>{t("october")}</MenuItem>
            <MenuItem value={11}>{t("november")}</MenuItem>
            <MenuItem value={12}>{t("december")}</MenuItem>
          </Select>
        </FormControl>
        {apiLoading ? (
          <Loading />
        ) : (
          <div>
            <Button type="submit" variant="contained">
              {t("create")}
            </Button>
            <Button
              sx={{ ml: 1 }}
              onClick={() => navigate("/" + path + "/invoices")}
              variant="outlined"
            >
              {t("cancel")}
            </Button>
          </div>
        )}
      </form>
    </Container>
  );
};

export default InvoicesCreationForm;
