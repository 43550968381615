import { createContext, useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import api from "../../services";
import { useSeller } from "../users";
import { useTenant } from "../tenant";
import { IProviderProps } from "../tenant/tenant.interfaces";
import {
  IProductComission,
  IUpdateArrayProductComission,
} from "./productCommissions.interface";
import { toast } from "react-toastify";
import { useProduct } from "../products";

export const ProductsCommissionsContext = createContext<any | null>(null);

export const ProductCommissionProvider = ({ children }: IProviderProps) => {
  const [allProductCommissions, setAllProductCommissions] = useState<
    IProductComission[]
  >([]);
  const { allProducts, getAllProducts } = useProduct();
  const navigate = useNavigate();
  const { token, setApiLoading } = useSeller();
  const location = useLocation();
  const { getTenantUsers, getAllLogs } = useTenant();
  const path = location.pathname.split("/")[1];
  const getAllProductCommissions = (id: string) => {
    setApiLoading(true);
    api
      .get("/productcommissions/" + id, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setApiLoading(false);
        if (!allProducts) {
          getAllProducts();
        }
        if (allProducts) {
          for (let i = 0; i < res.data.length; i++) {
            res.data[i].product = allProducts.find(
              (e) => e.id === res.data[i].productId
            );
          }
        }

        setAllProductCommissions(res.data);
      })
      .catch((err) => {
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
        setApiLoading(false);
      });
  };

  const editProductCommissions = (
    data: IUpdateArrayProductComission,
    goTo: string,
    getUsers = false
  ) => {
    setApiLoading(true);
    api
      .patch(`/productcommissions`, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setApiLoading(false);
        setAllProductCommissions([]);
        getAllLogs();
        if (getUsers) {
          getTenantUsers();
        }
        navigate(`/${path}/${goTo}`);
      })
      .catch((err) => {
        setApiLoading(false);
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
      });
  };
  return (
    <ProductsCommissionsContext.Provider
      value={{
        allProductCommissions,
        getAllProductCommissions,
        editProductCommissions,
      }}
    >
      {children}
    </ProductsCommissionsContext.Provider>
  );
};

export const useProductCommissions = () =>
  useContext(ProductsCommissionsContext);
