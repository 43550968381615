import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;

  .modalContainer {
    width: 90%;
    max-width: 400px;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.colors.secondary};
  }
  .modalContainer > span {
    display: flex;
    justify-content: flex-end;
    padding: 15px;
    height: 12px;
  }
  .modalContainer > form {
    display: flex;
    justify-content: center;
    padding: 15px;
    flex-direction: column;
  }
  .modalContainer > span > button {
    background-color: ${({ theme }) => theme.colors.secondary};
    color: ${({ theme }) => theme.colors.gray};
    border: none;
    border-radius: 5px;
  }
  .noUserFound {
    text-align: center;
    font-size: 1.2rem;
  }
`;
