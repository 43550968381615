import SalesTeamMembersList from "../../components/SalesTeamMembersList";

const SalesTeamMembersPage = () => {
  return (
    <>
      <SalesTeamMembersList />
    </>
  );
};

export default SalesTeamMembersPage;
