import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AiOutlineLink } from "react-icons/ai";
import { IoCreateOutline } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { IPaymentMethod } from "../../providers/paymentMethods/paymentMethods.interface";
import { useProduct } from "../../providers/products";
import { IProduct } from "../../providers/products/products.interface";
import { useSeller } from "../../providers/users";
import { useTenant } from "../../providers/tenant";
import Loading from "../Loading";
import PageHeader from "../pageHeader";
import { Container } from "./styles";
import { useSale } from "../../providers/sales";
import { ISale } from "../../providers/sales/sales.interface";
import { ISaleCommission } from "../../providers/sales/sales.interface";

const SalesUpdateForm = () => {
  const { apiLoading } = useSeller();
  const { updateSale } = useSale();
  const navigate = useNavigate();
  const { path, sales_id } = useParams();

  const { t } = useTranslation();

  const [sales, setSales] = useState<ISale | null>(null);
  const { allSales } = useSale();
  const { allUsers } = useTenant();

  useEffect(() => {
    if (allSales) {
      const salesFromId = allSales.find((e: ISale) => e.id === sales_id);
      if (salesFromId) {
        console.log(salesFromId);
        setSales(salesFromId);
      } else {
        navigate("/" + path + "/allsales");
      }
    }
  }, [allSales, navigate, path, sales_id]);

  const formSchema = yup.object().shape({
    customer_first_name: yup
      .string()
      .required(t("first_name_required"))
      .max(128, t("first_name_max")),
    customer_last_name: yup
      .string()
      .required(t("last_name_required"))
      .max(128, t("last_name_max")),
    customer_email: yup
      .string()
      .required(t("email_required"))
      .email(t("email_invalid")),
    order_id: yup
      .string()
      .required(t("order_id_required"))
      .max(128, t("order_id_max")),
    subscription_id: yup
      .string()
      .required(t("subscription_id_required"))
      .max(128, t("subscription_id_max")),
    transaction_id: yup
      .string()
      .required(t("transaction_id_required"))
      .max(128, t("transaction_id_max")),
    charge_id: yup
      .string()
      .required(t("charge_id_required"))
      .max(128, t("charge_id_max")),
    payment_processor: yup
      .string()
      .required(t("payment_processor_required"))
      .max(128, t("payment_processor_max")),
    note: yup.string().max(128, t("note_length")),
    saleCommission: yup.object().shape({
      value_to_receive: yup
        .string()
        .required(t("value_required"))
        .matches(/^\d+(\.\d{1,2})?$/, t("value_typeerror")),
      full_price: yup
        .string()
        .required(t("value_required"))
        .matches(/^\d+(\.\d{1,2})?$/, t("value_typeerror")),
      percentage: yup
        .number()
        .typeError(t("value_typeerror"))
        .required(t("value_required")),
    }),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(formSchema),
  });

  const removeEmptyFields = (data: any) => {
    Object.keys(data).forEach((key) => {
      //@ts-ignore
      if (data[key] === "" || data[key] == null || data[key] === sales[key]) {
        delete data[key];
      }
    });
  };

  const onSubmit = async (data: any) => {
    removeEmptyFields(data);
    if (data.saleCommission.full_price) {
      data.order_amount = data.saleCommission.full_price.toString();
      data.saleCommission.full_price =
        data.saleCommission.full_price.toString();
    }
    await updateSale(sales_id, data, true);
    navigate("/" + path + "/allsales");
  };

  return (
    <Container>
      <PageHeader
        title={t("update_sales")}
        description={`${t("fill_create")} ${t("sales_analysis")}`}
        icon={<IoCreateOutline />}
      />

      {sales && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            defaultValue={sales.customer_email || ""}
            error={!!errors.customer_email}
            helperText={errors.customer_email?.message?.toString()}
            id="standard-customer_email"
            label={t("customer_email")}
            variant="standard"
            {...register("customer_email")}
          />
          <TextField
            defaultValue={sales.customer_first_name || ""}
            id="standard-customer_first_name"
            error={!!errors.customer_first_name}
            helperText={errors.customer_first_name?.message?.toString()}
            label={t("customer_first_name")}
            variant="standard"
            {...register("customer_first_name")}
          />
          <TextField
            defaultValue={sales.customer_last_name || ""}
            id="standard-customer_last_name"
            error={!!errors.customer_last_name}
            helperText={errors.customer_last_name?.message?.toString()}
            label={t("customer_last_name")}
            variant="standard"
            {...register("customer_last_name")}
          />
          <TextField
            defaultValue={sales.note || ""}
            id="standard-note"
            error={!!errors.note}
            helperText={errors.note?.message?.toString()}
            label={t("note")}
            placeholder={t("not_required")}
            variant="standard"
            {...register("note")}
          />
          <TextField
            defaultValue={sales.order_id || ""}
            id="standard-order_id"
            error={!!errors.order_id}
            helperText={errors.order_id?.message?.toString()}
            label={t("order_id")}
            variant="standard"
            {...register("order_id")}
          />
          <TextField
            defaultValue={sales.subscription_id || ""}
            id="standard-subscription_id"
            error={!!errors.subscription_id}
            helperText={errors.subscription_id?.message?.toString()}
            label={t("subscription_id")}
            variant="standard"
            {...register("subscription_id")}
          />
          <TextField
            defaultValue={sales.transaction_id || ""}
            id="standard-transaction_id"
            error={!!errors.transaction_id}
            helperText={errors.transaction_id?.message?.toString()}
            label={t("transaction_id")}
            variant="standard"
            {...register("transaction_id")}
          />
          <TextField
            defaultValue={sales.charge_id || ""}
            id="standard-charge_id"
            error={!!errors.charge_id}
            helperText={errors.charge_id?.message?.toString()}
            label={t("charge_id")}
            variant="standard"
            {...register("charge_id")}
          />
          <TextField
            defaultValue={sales.payment_processor || ""}
            id="standard-payment_processor"
            error={!!errors.payment_processor}
            helperText={errors.payment_processor?.message?.toString()}
            label={t("payment_processor")}
            variant="standard"
            {...register("payment_processor")}
          />
          <h3>{t("update_sales_commission")}</h3>
          <TextField
            defaultValue={sales.saleCommission?.value_to_receive || ""}
            id="standard-value_to_receive"
            error={!!errors.saleCommission?.value_to_receive}
            helperText={errors.saleCommission?.value_to_receive?.message?.toString()}
            label={t("value_to_receive")}
            variant="standard"
            {...register("saleCommission.value_to_receive")}
          />
          <TextField
            defaultValue={sales.saleCommission?.full_price || ""}
            id="standard-full_price"
            error={!!errors.saleCommission?.full_price}
            helperText={errors.saleCommission?.full_price?.message?.toString()}
            label={t("value")}
            variant="standard"
            {...register("saleCommission.full_price")}
          />
          <TextField
            defaultValue={sales.saleCommission?.percentage || 0}
            id="standard-percentage"
            error={!!errors.saleCommission?.percentage}
            helperText={errors.saleCommission?.percentage?.message?.toString()}
            label={t("percentage")}
            variant="standard"
            {...register("saleCommission.percentage")}
          />
          <FormControl>
            <InputLabel sx={{ mt: 1 }} id="demo-simple-select-label">
              {t("status")}
            </InputLabel>
            <Select
              sx={{ mt: 1 }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              defaultValue={sales.saleCommission?.status || ""}
              label={t("status")}
              {...register("saleCommission.status")}
            >
              <MenuItem value="paid">{t("paid")}</MenuItem>
              <MenuItem value="approved_guarantee">
                {t("approved_guarantee")}
              </MenuItem>
              <MenuItem value="approved_for_payment">
                {t("approved_for_payment")}
              </MenuItem>
              <MenuItem value="finance_processing">
                {t("finance_processing")}
              </MenuItem>
              <MenuItem value="analysis">{t("analysis")}</MenuItem>
              <MenuItem value="cancelled_refunded">
                {t("cancelled_refunded")}
              </MenuItem>
              <MenuItem value="cancelled_chargeback">
                {t("cancelled_chargeback")}
              </MenuItem>
              <MenuItem value="rejected">{t("rejected")}</MenuItem>
              <MenuItem value="not_applicable">{t("not_applicable")}</MenuItem>
            </Select>
          </FormControl>

          {allUsers && (
            <FormControl>
              <InputLabel id="select-user">{t("seller")}</InputLabel>
              <Select
                labelId="select-user"
                id="select-user"
                defaultValue={sales.userId || ""}
                required
                label={t("seller")}
                {...register("userId")}
              >
                {allUsers.map((e: any) => (
                  <MenuItem key={e.id} value={e.id}>
                    {`${e.first_name} ${e.last_name}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {apiLoading ? (
            <Loading />
          ) : (
            <div>
              <Button type="submit" variant="contained">
                {t("update")}
              </Button>
              <Button
                sx={{ ml: 1 }}
                onClick={() => navigate("/" + path + "/allsales")}
                variant="outlined"
              >
                {t("cancel")}
              </Button>
            </div>
          )}
        </form>
      )}
    </Container>
  );
};

export default SalesUpdateForm;
