import { yupResolver } from "@hookform/resolvers/yup";
import { Button, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useSeller } from "../../providers/users";
import Loading from "../Loading";
import { Container } from "./styles";
import { useNavigate } from "react-router-dom";

interface IProps {
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setEmail: React.Dispatch<React.SetStateAction<string | null>>;
}
const SendPasswordRecoveryForm = ({ setPage, setEmail }: IProps) => {
  const { sendPasswordToken, apiLoading } = useSeller();
  const navigate = useNavigate();
  const { t } = useTranslation();

  type FormValues = yup.InferType<typeof formSchema>;
  const onSubmit = async (data: FormValues) => {
    setEmail(data.email);
    await sendPasswordToken(data, setPage);
  };

  const formSchema = yup
    .object()
    .shape({
      email: yup
        .string()
        .required(t("email_required"))
        .email(t("email_invalid")),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  return (
    <Container>
      <div className=" passwordRecoveryHeader">
        <h2>{t("password_recovery")}</h2>
      </div>
      <p>{t("password_recovery_message")}</p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          error={errors.email === undefined ? false : true}
          helperText={errors.email?.message?.toString()}
          id="standard-email"
          label={t("email")}
          autoComplete="email"
          variant="standard"
          {...register("email")}
        />

        {apiLoading ? (
          <Loading />
        ) : (
          <div>
            <Button type="submit" variant="contained">
              {t("confirm")}
            </Button>
            <Button
              onClick={() => navigate(-1)}
              variant="outlined"
              sx={{ ml: 1 }}
            >
              {t("back")}
            </Button>
          </div>
        )}
      </form>
    </Container>
  );
};

export default SendPasswordRecoveryForm;
