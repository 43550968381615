import styled from "styled-components";

export const Container = styled.div`
  form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: stretch;
    margin-top: 10px;
  }
  .divButton {
    align-self: flex-end;
  }
`;
